import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, Select } from 'antd';
import React from 'react';
import logo from '../../assets/logo.jpg';
import logoGakkenCompany from '../../assets/GAKKEN_LOGO.png';
import { Link } from 'react-router-dom';
import { COMMON_MESSAGES } from '../../commons/commonMessages/commonMessages';
import './css/signIn.css';

SignInSA.propTypes = {};

function SignInSA(props) {
  const { teams, onSignIn, errormessage } = props;
  const [formlogin__signIn] = Form.useForm();
  const onFinish = (values) => {
    const userName = values.username.trim();
    const password = values.password.trim();
    if (onFinish) {
      onSignIn({ userName, password, edit_date: new Date().toUTCString(),utc_local:new Date().getUTCHours() + 1 });
    }
  };

  return (
    <div className='login__signIn'>
      <div className='login__signIn__box'>
        <div className='common__flex login__signIn__top '>
          <div className='login__signIn__top__logo'>
            <img src={logo} alt='logo' />
          </div>
        </div>
        <div className='common__flex login__signIn__form'>
          <Form name='login__signIn__form' form={formlogin__signIn} className='login__signIn__form--form' onFinish={onFinish}>
            <Form.Item
              className='login__signIn__form--item'
              name='team_id'
              rules={[
                {
                  required: true,
                  message: COMMON_MESSAGES.AUTH.USERNAMEEMTY,
                },
              ]}
            >
              <Select 
                prefix={<UserOutlined className='site-form-item-icon' />} 
                placeholder='チームID'
                fieldNames={{value: "team_id", label: "team_name"}}
                options={teams}
                style={{height: '100px !important', background: 'red'}}
              />
            </Form.Item>
            <Form.Item
              className='login__signIn__form--item'
              name='username'
              rules={[
                {
                  type: 'email',
                  pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: COMMON_MESSAGES.AUTH.USERNAMEERROR,
                },
                {
                  required: true,
                  message: COMMON_MESSAGES.AUTH.USERNAMEEMTY,
                },
              ]}
            >
              <Input maxLength={254} prefix={<UserOutlined className='site-form-item-icon' />} placeholder='ID(メールアドレス)' />
            </Form.Item>
            <Form.Item
              className='login__signIn__form--item'
              name='password'
              rules={[
                {
                  required: true,
                  message: COMMON_MESSAGES.AUTH.PASSWORDEMTY,
                },
              ]}
            >
              <Input.Password autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='パスワード' />
            </Form.Item>
            
            <Form.Item
              className='login__signIn__form--item'
              name='errormessage'
              rules={null}
            >
              <div style={{whiteSpace: "pre-line", color: "red"}}>{errormessage}</div>
            </Form.Item>

            <div className='common__flex login__signIn__form__submit'>
            <Form.Item>
              <Button type='primary' htmlType='submit' className='login__signIn-form-button'>
                ログイン
              </Button>
            </Form.Item>
           </div>
          </Form>
        </div>
        <div className='common__flex login__signIn__bottom'>
          <div className='login__signIn__bottom__logo'>
            <Image src={logoGakkenCompany} alt={logo} preview={false} />
          </div>
          <div className='login__signIn__bottom__name'>
            <p>Gakken Logistics Co.,Ltd. © 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInSA;

import React, { useEffect, useState } from 'react';
import { DatePicker, TimePicker, message, Button, Space, Row, Col } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';

const TimeSelectionDuedate = (props) => {
  const { indexValue, handleSelectTime, value, clearTime, timeStartTask } = props;
  const [selectedDate, setSelectedDate] = useState(value || null);
  const [selectedTime, setSelectedTime] = useState(value || null);
  // Lấy thời gian hiện tại
  const currentTime = moment();

  // Thời gian tối đa: 10 năm kể từ thời điểm hiện tại
  const maxDate = moment().add(10, 'years');

  // Kiểm tra xem ngày và giờ có hợp lệ không
  const isValidTime = (date, time) => {
    if (date && time) {
      // Nếu ngày không hợp lệ, trả về false
      if (!date || !date?.isValid()) return false;

      // Nếu có thời gian, kiểm tra tính hợp lệ của time
      if (!time && !time?.isValid()) return false;

      // Nếu không có thời gian (time là null), mặc định thời gian là 00:00
      const selectedDateTime = date.set({
        hour: time ? time.hour() : 0, // Nếu có time thì lấy giờ, nếu không có thì mặc định là 0 giờ
        minute: time ? time.minute() : 0, // Nếu có time thì lấy phút, nếu không có thì mặc định là 0 phút
        second: 0,
      });

      // Kiểm tra không được chọn ngày giờ quá khứ
      if (selectedDateTime.isBefore(currentTime, 'minute')) {
        toast.warning('現在日時以降を選択してください。', {
          toastId: 'remindTime',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        // message.error('現在日時以降を選択してください。');
        return false;
      }

      // Kiểm tra không được chọn thời gian vượt quá thời gian bắt đầu task
      if (selectedDateTime.isAfter(timeStartTask, 'minutes')) {
        toast.warning('開始時よりまえの時間を選択してください。', {
          toastId: 'remindTime',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return false;
        // message.error('開始時よりまえの時間を選択してください。');
        // return false;
      }
    }

    return true;
  };

  // Handler khi người dùng chọn ngày
  const onDateChange = (date) => {
    const newSelectedTime =
      date && selectedTime
        ? date.set({
            hour: selectedTime ? selectedTime.hour() : 0, // Nếu có time thì lấy giờ, nếu không có thì mặc định là 0 giờ
            minute: selectedTime ? selectedTime.minute() : 0, // Nếu có time thì lấy phút, nếu không có thì mặc định là 0 phút
            second: 0,
          })
        : null;
    // Nếu giá trị mới thực sự thay đổi, gọi handleSelectTime
    if (!isValidTime(date, selectedTime)) {
      setSelectedDate(null);
      return;
    }
    setSelectedDate(date);
    handleSelectTime({ indexValue, time: newSelectedTime });
  };

  // Handler khi người dùng chọn giờ
  const onTimeChange = (time) => {
    const newSelectedTime =
      selectedDate && time
        ? selectedDate.set({
            hour: time ? time.hour() : 0, // Nếu có time thì lấy giờ, nếu không có thì mặc định là 0 giờ
            minute: time ? time.minute() : 0, // Nếu có time thì lấy phút, nếu không có thì mặc định là 0 phút
            second: 0,
          })
        : null;
   
    // Kiểm tra tính hợp lệ của thời gian ngay sau khi người dùng chọn
    if (!isValidTime(selectedDate, time)) {
      setSelectedTime(null); // Reset giờ nếu không hợp lệ
      return;
    }
    setSelectedTime(time);
    handleSelectTime({ indexValue, time: newSelectedTime });
  };

  useEffect(() => {
    
    if(value){
      setSelectedDate(moment(value))
      setSelectedTime(moment(value))
    }else{
      setSelectedDate(null)
      setSelectedTime(null)
    }
  }, [value]);
  useEffect(() => {
    // Nếu clearTime là true, reset giá trị
    if (clearTime) {
      setSelectedDate(null);
      setSelectedTime(null);
      return; // Không cần kiểm tra các điều kiện khác
    }
  }, [clearTime]);
// console.log({selectedDate,selectedTime});

  return (
    <Space direction='vertical'>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <DatePicker value={selectedDate} onChange={onDateChange} disabledDate={(current) => current && current.isBefore(currentTime, 'day')} format='YYYY-MM-DD' />
        </Col>
        <Col span={12}>
          <TimePicker
            value={selectedTime}
            onChange={onTimeChange} // Cập nhật khi thay đổi giá trị thời gian
            onSelect={onTimeChange} // Cập nhật khi người dùng chọn giờ từ menu
            format='HH:mm'
          />
        </Col>
      </Row>
    </Space>
  );
};

export default TimeSelectionDuedate;

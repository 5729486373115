import { Col, Dropdown, Layout, Modal, Row, Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaLock, FaSignOutAlt, FaUser } from 'react-icons/fa';
import I3linkSider from '../components/I3linkSider';
import { v4 as uuidv4 } from 'uuid';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './css/style.css';
import processSlice from '../components/Process/reduxProcess/processSlice';
import { useDispatch } from 'react-redux';

DefaultLayout.propTypes = {};

function DefaultLayout({ children }) {
  const [team, setTeam] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [navActive, setNavActive] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const items = [
    {
      key: '1',
      label: (
        <span style={{ display: 'block' }}>
          <div
            onClick={() => {
              handleConfirm({
                id: 0,
                to: '/profile/resetpassword',
              });
            }}
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}
          >
            <FaLock /> <span> パスワード変更</span>
          </div>
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span style={{ display: 'block' }}>
          <div
            onClick={() => {
              handleConfirm({
                id: 1,
                to: '/login',
              });
            }}
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}
          >
            <FaSignOutAlt /> <span> ログアウト</span>
          </div>
        </span>
      ),
    },
  ];

  const handleConfirm = (item) => {
    
    if (location.pathname.includes('process/new') || location.pathname.includes('process/update') || location.pathname.includes('process/admin')) {
      const pathname = location.pathname;
      const searchQuery = location.search;
      navigate({
        pathname: pathname,
        search: searchQuery,
      });
      setNavActive({ ...item });
      setShowModal(true);
      return;
    }
    if (item.to === '/login') {
      localStorage.setItem('flag_history_location', '0');
      localStorage.removeItem('history_location');
      
      const pathname = location.pathname;
      navigate({
        pathname:pathname,
        search:location.search
      });
      if (isSuperAdmin == 1) {
        setNavActive({ to: '/loginsa' });
      } else {
        setNavActive({ to: '/login' });
      }
      setShowModal(true);
      return;
    }
    navigate(`${item.to}`);

    return;
  };

  const handleOk = () => {
    if (location.pathname.includes('process/new') || location.pathname.includes('process/update')) {
      localStorage.removeItem('process');
      const action = processSlice.actions.newProcess({});
      dispatch(action);
    }
    setShowModal(false);
    sessionStorage.removeItem('history_location')
    navigate(`${navActive.to}`);
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleRenderMess = () => {
    if (location.pathname.includes('process/update') || location.pathname.includes('/new')) {
      return (
        <>
          <h2 style={{ marginBottom: 0 }}>現在設定中の内容は破棄されます。</h2>
          <h2> 設定を終了してよろしいですか？</h2>
        </>
      );
    }
    if (location.pathname.includes('process/admin')) {
      return (
        <>
          <h2 style={{ marginBottom: 0 }}> 現在設定中の内容は破棄されます。</h2>
          <h2> 設定を終了してよろしいですか？</h2>
        </>
      );
    }
    return (
      <>
        <h2> ログアウトしてよろしいですか？</h2>
      </>
    );
  };

  useEffect(() => {
    const Team = localStorage.getItem('listTeam') ? JSON.parse(localStorage.getItem('listTeam')) : { team_id: '', team_name: '' };
    setTeam(Team);
  }, []);
  return (
    <Layout>
      <div style={{ background: '#fff', position: 'sticky', top: 0, zIndex: 300 }}>
        <div
          className='header'
          style={{
            background: 'rgba(188, 232, 98, 0.3)',
            height: 'max-content',
            padding: '5px 10px',
          }}
        >
          <Row>
            <Col span={21}>
              <h2 className='common_text-clamp common_text-clamp--1' style={{ margin: 0 }}>
                チーム ID: {team.team_id}　チーム名: {team.team_name}
              </h2>
            </Col>
            <Col span={3}>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
              <Dropdown
                menu={{
                  items,
                }}
                placement='bottomRight'
                
              >  
                 <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',columnGap:5}}>
                 <FaUser style={{ cursor: 'pointer' }} size={20} />
                    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden',maxWidth:150 }}>
                      <span className='' style={{ cursor: 'pointer' }}>
                        <Tooltip color={'#00a199'} placement='leftBottom' title={localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user_first_name + ' ' + JSON.parse(localStorage.getItem('userInfo')).user_last_name : ''}>
                          {localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user_first_name + ' ' + JSON.parse(localStorage.getItem('userInfo')).user_last_name : ''}
                        </Tooltip>
                      </span>
                    </div>
                 </div>
                 
              </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Layout>
        <I3linkSider />
        <Layout className='site-layout site__layout__i3links'>{children}</Layout>
      </Layout>
      <Modal
        className=''
        maskClosable={false}
        closable={true}
        title=''
        open={showModal}
        onCancel={() => {
          handleCancel();
        }}
        forceRender
        footer={[
          <div key={uuidv4() + 1} className='modle__user__btn flex__box flex__jus__c'>
            <Button
              // disabled={spinning}
              className='i3link__btn bg__red'
              onClick={() => {
                handleCancel();
              }}
            >
              キャンセル
            </Button>
            <Button
              // disabled={spinning}
              className='i3link__btn bg__blue'
              onClick={() => {
                handleOk();
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        {handleRenderMess()}
      </Modal>
    </Layout>
  );
}

export default DefaultLayout;

import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { processApi } from '../../../../api/processApi';
import { teamState } from '../../../../redux/selector';
import ProcessEditAdmin from './ProcessEditAdmin';
import { toast } from 'react-toastify';

ProcessEditAdminContainer.propTypes = {};

function ProcessEditAdminContainer(props) {
  const [spinning, setSpinning] = useState(false);
  const navigate = useNavigate();
  const teamInfo = useSelector(teamState);
  const location = useLocation();
  const [listUser, setListUser] = useState([]);
  const [pagination, setPagination] = useState({
    totals: 1,
    limit: 10,
    page: 1,
  });

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      team_id: teamInfo.listTeam[0]?.team_id,
      page: Number.parseInt(pagination.page) || 1,
      limit: Number.parseInt(pagination.limit) || 10,
    };
  }, [location.search, teamInfo]);

  useEffect(() => {
    (async () => {
      try {
        setSpinning(true);
        const result = await processApi.getAdminById(queryParams);
        if (result && result.data.length > 0) {
          const { data } = result;
          setListUser([...data]);
          return;
        } else {
            toast.warning('既に削除されています。', {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: false,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });
          navigate({
            pathname: `/process`,
          });
          return;
        }
      } catch (error) {
        setSpinning(false);
        console.log(error);
      }finally{
        setSpinning(false);
      }
    })();
  }, [location.pathname, queryParams, teamInfo]);

  return <ProcessEditAdmin listUser={listUser} />;
}

export default ProcessEditAdminContainer;

import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import logoGakkenCompany from '../../assets/GAKKEN_LOGO.png';
import logo from '../../assets/logo.jpg';
import { COMMON_MESSAGES } from '../../commons/commonMessages/commonMessages';
import './css/style.css';
import { resetMilliseconds } from '../../commons/commonFunctions/CommonFunction';

ForgotPassword.propTypes = {};

function ForgotPassword(props) {
  const { onForgotPassword, spinning } = props;
  const onFinish = (values) => {
    const userName = values.username.trim();
    if (onForgotPassword) {
      onForgotPassword({ userName,dateChangePassword:resetMilliseconds(new Date()) });
    }
  };

  return (
    <div className='login'>
      <Spin spinning={spinning} tip='Loading...'>
        <div className='login__box'>
          <div className='flex__box flex__jus__c flex__al__c login__title'>仮パスワードの発行</div>
          <div className='login__top'>
            <div className='login__top__logo' style={{marginBottom:80}}>
              <img src={logo} alt='logo' />
            </div>
            <div className='login__top__form'>
              <Form name='normal_login' onFinish={onFinish}>
                {/* <Form.Item name='username'><Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Username' /></Form.Item> */}
                <Form.Item
                  name='username'
                  rules={[
                    {
                      type: 'email',
                      message: COMMON_MESSAGES.AUTH.USERNAMEERROR,
                    },
                    {
                      required: true,
                      message: COMMON_MESSAGES.AUTH.USERNAMEEMTY,
                    },
                  ]}
                >
                  <Input maxLength={254} prefix={<UserOutlined className='site-form-item-icon' />} placeholder='ID(メールアドレス)' />
                </Form.Item>

                <Form.Item>
                  <Button disabled={spinning} type='primary' htmlType='submit' className='login-form-button i3link__btn'>
                    送信
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className='login__top__forgot--password'  style={{marginBottom:60}}>
              <p>
                <Link to='/login'>ログイン画面へ戻る</Link>
              </p>
            </div>
          </div>
          <div className='login__middle'></div>
          <div className='login__bottom'>
            <div className='login__bottom__logo' >
              <img src={logoGakkenCompany} alt='logo' />
            </div>
            <div className='login__bottom__name'>
              <p>Gakken Logistics Co.,Ltd. © 2024</p>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ForgotPassword;

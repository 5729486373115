import React from 'react';
import PropTypes from 'prop-types';
import ProcessNew from './ProcessNew';

ProcessNewContainer.propTypes = {
    
};

function ProcessNewContainer(props) {
    return (
       <ProcessNew />
    );
}

export default ProcessNewContainer;
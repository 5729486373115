import axiosClient from './axiosClient';

const teamsApi = {
  getAll(params) {
    const url = '/team';
    return axiosClient.get(url, { params });
  },
  getAllPublic(params) {
    const url = 'api/team';
    return axiosClient.get(url ,{ params });
  },
  getById(params) {
    const url = `/team/${params}`;
    return axiosClient.get(url, { params });
  },
  
  getAllFilter(params, data) {
    const url = '/user/filter';
    return axiosClient.post(url, { params, data });
  },
  getUserByEmail(email) {
    const url = `/userbyemail/${email}`;
    return axiosClient.get(url);
  },
  get(id) {
    const url = `/user/${id}`;
    return axiosClient.get(url);
  },
  add(data) {
    const url = `/user`;
    return axiosClient.post(url, data);
  },
  update(data) {
    const url = `/user/${data.id}`;
    return axiosClient.patch(url, data);
  },
  remove(id) {
    const url = `/user/${id}`;
    return axiosClient.delete(url);
  },
};

export default teamsApi;

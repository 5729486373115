
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProcessListContainer from './components/ProcessList';
import ProcessNewContainer from './components/ProcessNew';
import './css/style.css';
import ProcessEditAdminContainer from './components/ProcessEditAdmin';
import TaskAdminRun from '../Reference/components/TaskAdminRun';
import TaskAdminDone from '../Reference/components/TaskAdminDone';
import TaskReference from '../Reference/components/TaskReference';
import ContainerScheduleTimer from './components/ProcessScheduleTimer';

Process.propTypes = {};

function Process(props) {
  return (
    <Routes>
        <Route exact path='/*' element={<ProcessListContainer />} />
        <Route exact path='/list' element={<ProcessListContainer />} />
        <Route exact path='/new' element={<ProcessNewContainer />} />
        <Route exact path='/update' element={<ProcessNewContainer />} />
        <Route exact path='/scheduler' element={<ContainerScheduleTimer />} />
        <Route exact path='/admin' element={<ProcessEditAdminContainer />} />
        <Route path='/run' element={<TaskAdminRun />} />
        <Route path='/done' element={<TaskAdminDone />} />
        <Route path='/task' element={<TaskReference />} />

        {/* <Route exact path='/*' element={<Navigate to="/process/list" />} /> */}
    </Routes>
   
  );
}

export default Process;

import { Button, Col, Input, Layout, Row, Spin, DatePicker, Dropdown, Space, Modal, Tooltip, Table, Checkbox } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaEraser, FaFileDownload, FaFilter, FaPen, FaProjectDiagram } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { processApi } from '../../../../api/processApi';
import { COLUMNS_TABLES } from '../../../../commons/commonColumnTables/commonColumnTables';
import ModalConfirm from '../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import TableI3links from '../../../../commons/commonComponents/tableI3links/TableI3links';
import { COMMON_MESSAGES } from '../../../../commons/commonMessages/commonMessages';
import { teamState } from '../../../../redux/selector';
import { DownloadCSV, getWeekOfMonth } from '../../../../commons/commonFunctions/CommonFunction';
import { FormatDate, FormatDateString } from '../../../../commons/commonFunctions/FormatDate';
import './css/style.css';
import useWindowWidth from '../../../../hooks/useWindowWidth/useWindowWidth';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
const { Header, Content } = Layout;
const { RangePicker } = DatePicker;
ProcessList.propTypes = {};
const defaultOption = [
  {
    value: '0',
    label: '未設定',
  },
  {
    value: '1',
    label: '日',
  },
  {
    value: '8',
    label: '週',
  },
  {
    value: '9',
    label: '月',
  },
];
const options = [
  {
    value: '0',
    label: '日',
  },
  {
    value: '1',
    label: '月',
  },
  {
    value: '2',
    label: '火',
  },
  {
    value: '3',
    label: '水',
  },
  {
    value: '4',
    label: '木',
  },
  {
    value: '5',
    label: '金',
  },
  {
    value: '6',
    label: '土',
  },
];
const optionWeeks = [
  {
    value: '1',
    label: '第1',
    days: [],
  },
  {
    value: '2',
    label: '第2',
    days: [],
  },
  {
    value: '3',
    label: '第3',
    days: [],
  },
  {
    value: '4',
    label: '第4',
    days: [],
  },
  {
    value: '5',
    label: '第5',
    days: [],
  },
  {
    value: '6',
    label: '最終',
    days: [],
  },
];
const optionDays = new Array(32).fill(null).map((item, index) => {
  return {
    value: index === 31 ? `99` : `${index + 1}`,
    label: `${index === 31 ? '最終' : index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}`,
  };
});

const lstDataHeader = ['プロセス名', 'プロセス管理者', '次回実行予定日時', '前回実行日時', '更新日時', ]
const lstDataValue = ['process_name', 'user_name', 'trigger_next_execution_start_time', 'pre_column1', 'edit_date', ]

const filterData = (listProcess, sortFilter) => {
  let dataFilterProcess = [...listProcess];
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  if (sortFilter.filterProcessIsAdmin) {
    dataFilterProcess = dataFilterProcess.filter((item) => item?.user_id_json && JSON.parse(item?.user_id_json).includes(+user.user_id));
  }
  if (sortFilter.filterProcessName?.trim()) {
    dataFilterProcess = dataFilterProcess.filter((item) => item.process_name.includes(sortFilter.filterProcessName?.trim()));
  }
  if (sortFilter.filterUserName?.trim()) {
    dataFilterProcess = dataFilterProcess.filter((item) => item.user_name.includes(sortFilter.filterUserName?.trim()));
  }
  if (sortFilter.filterNextStartTime[0] && sortFilter.filterNextStartTime[1]) {
    dataFilterProcess = dataFilterProcess.filter((item) => item.trigger_next_execution_start_time && new Date(`${item.trigger_next_execution_start_time}`) >= new Date(sortFilter.filterNextStartTime[0]) && new Date(`${item.trigger_next_execution_start_time}`) <= new Date(sortFilter.filterNextStartTime[1]));
  }
  if (sortFilter.filterLastStartTime[0] && sortFilter.filterLastStartTime[1]) {
    dataFilterProcess = dataFilterProcess.filter((item) => item.pre_column1 && new Date(`${item.pre_column1}`) >= new Date(sortFilter.filterLastStartTime[0]) && new Date(`${item.pre_column1}`) <= new Date(sortFilter.filterLastStartTime[1]));
  }
  if (sortFilter.filterEditDate[0] && sortFilter.filterEditDate[1]) {
    dataFilterProcess = dataFilterProcess.filter((item) => item.edit_date && new Date(`${item.edit_date}`) >= new Date(sortFilter.filterEditDate[0]) && new Date(`${item.edit_date}`) <= new Date(sortFilter.filterEditDate[1]));
  }
  return dataFilterProcess;
};

function ProcessList(props) {
  const { listProcess, spinning } = props;
  const [dataProcess, setDataProcess] = useState(listProcess);
  const [spinningProcessList, setSpinningProcessList] = useState(false);
  const [processInfo, setProcessInfo] = useState({});
  const [processName, setProcessName] = useState('');
  const [showModalSchedulerDay, setShowModalSchedulerDay] = useState(false);
  const [showModalSchedulerWeekMonth, setShowModalSchedulerWeekMonth] = useState(false);
  const [showModalSchedulerWeekDay, setShowModalSchedulerWeekDay] = useState(false);
  const [showModalSchedulerMonthDay, setShowModalSchedulerMonthDay] = useState(false);
  const [showModalExecution, setShowModalExecution] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [changeProcessNm, setChangeProcessNm] = useState(false);
  const teamInfo = useSelector(teamState);
  const location = useLocation();
  const navigation = useNavigate();
  const [searchName, setSearchName] = useState('');
  const [pagination, setPagination] = useState(() => {
    const PaginationLocal =
      localStorage.getItem('paginationProcess') && location.pathname.includes('process')
        ? JSON.parse(localStorage.getItem('paginationProcess'))
        : {
            totals: 1,
            limit: 10,
            page: 1,
            pageSize: 10,
          };
    return PaginationLocal;
  });
  const windowWidth = useWindowWidth();

  const handleOnchangeSearch = (value) => {
    setSearchName(value);
  };
  const handleSearch = () => {
    const params = queryString.parse(location.search);
    const filter = {
      ...params,
      // team_id: teamInfo.listTeam[0]?.team_id,
      page: 1,
      limit: 10,
      like: searchName || undefined,
    };
    navigation({
      pathname: `${location.pathname}`,
      search: queryString.stringify(filter),
    });
  };

  ///////// handleclick process
  const SaveUserIdClick = (processInfo) => {
    if (processInfo) {
      setProcessInfo(processInfo);
    }
  };

  const handleDoubleClick = async () => {
    const filter = {
      // team_id: teamInfo.listTeam[0]?.team_id,
      process_id: processInfo.process_id,
    };
    navigation({
      pathname: `/process/update`,
      search: queryString.stringify(filter),
    });
    const result = await processApi.getById(filter);
    if (result.data && !toast.isActive({ toastId: 'toastId_err' })) {
    } else {
      toast.error(COMMON_MESSAGES.PROCESS.EDITNODATA, {
        toastId: 'toastId_err',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
  };

  const handleExecute = async () => {
    try {
      setSpinningProcessList(true);
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (user === null || user.user_id === null) {
        navigation('/login');
        return;
      }
      if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        return;
      }
      const filter = {
        team_id: teamInfo.listTeam[0]?.team_id,
        process_id: processInfo.process_id,
      };
      const result = await processApi.getById(filter);

      if (result.data && !toast.isActive({ toastId: 'toast_warning' })) {
        const params = {
          mode: 1,
          process_id: result.data.process_id,
          process_name: processName,
          trigger_id: '',
          trigger_user_id: user.user_id,
          start_user_id: user.user_id,
          edit_date: new Date(),
        };
        //Issue #22
        const execute = await processApi.execute(params);
        //Issue #22
        if (execute?.success && !toast.isActive({ toastId: 'toast_success' })) {
          toast.success(execute.success, {
            toastId: 'toast_success',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
        if (execute?.error && !toast.isActive({ toastId: 'toast_warning' })) {
          toast.warning(execute.error, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
      } else {
        toast.warning(COMMON_MESSAGES.PROCESS.EXECNODATA, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        navigation('/process');
        return;
      }
    } catch (error) {
    } finally {
      // setProcessInfo({})
      setSpinningProcessList(false);
      setShowModalConfirm(false);
      navigation({
        pathname: `/process`,
      });
    }
  };

  const handleEdit = async () => {
    if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const filter = {
      process_id: processInfo.process_id,
    };
    navigation({
      pathname: `/process/update`,
      search: queryString.stringify(filter),
    });
  };
  const handleCopy = async () => {
    if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const filter = {
      process_id: processInfo.process_id,
    };
    navigation({
      pathname: `/process/new`,
      search: queryString.stringify(filter),
    });
  };

  const handleEditAdmin = () => {
    if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
        position: 'top-right',
        toastId: 'toast_warning',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const filter = {
      process_id: +processInfo.process_id,
      // team_id:teamInfo.listTeam[0]?.team_id,
    };
    navigation({
      pathname: `/process/admin`,
      search: queryString.stringify(filter),
    });
  };

  ////Phan trang/////
  const handlePaginationNexted = (values) => {
    if (values) {
      let newPagination = { ...pagination, ...values };
      localStorage.setItem('paginationProcess', JSON.stringify(newPagination));
      setPagination(newPagination);
    }
  };

  const handleConfirmDelete = async () => {
    if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const filter = {
      team_id: teamInfo.listTeam[0]?.team_id,
      process_id: processInfo.process_id,
    };

    navigation({
      pathname: `/process/delete`,
      search: queryString.stringify(filter),
    });
  };
  const handleDeleteProcess = async () => {
    try {
      const process_id = processInfo.process_id;
      const team_id = teamInfo.listTeam[0]?.team_id;
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (user === null || user.user_id === null) {
        navigation('/login');
        return;
      }
      const result = await processApi.delete({ process_id, team_id });
      if (result?.success) {
        toast.success(result.success, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        setProcessInfo({});
      }
      if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(result.error, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
      setProcessInfo({});
      navigation('/process');
    } catch (error) {
      console.log(error.message);
    }
  };

  const setWeekMonth = (process) => {
    if (process.schedules.listWeekOfMonth) {
      const newList = [...process.schedules.listWeekOfMonth];
      const indexWeek = getWeekOfMonth(process.trigger_start_time || new Date());
      const indexDay = new Date(process.trigger_start_time || new Date()).getDay();
      return [
        ...newList.map((week, index) => {
          let newWeek = { ...week, isWeekStart: false };
          if (indexWeek === index + 1) {
            newWeek = { ...newWeek, days: [...newWeek.days.filter((item) => +item != +indexDay), `${indexDay}`], isWeekStart: true };
          }
          return newWeek;
        }),
      ];
    }
    return [
      {
        value: '1',
        label: '第1',
        days: [],
      },
      {
        value: '2',
        label: '第2',
        days: [],
      },
      {
        value: '3',
        label: '第3',
        days: [],
      },
      {
        value: '4',
        label: '第4',
        days: [],
      },
      {
        value: '5',
        label: '第5',
        days: [],
      },
      {
        value: '6',
        label: '最終',
        days: [],
      },
    ];
  }

  const [listDay, setListDay] = useState([]);
  const [listDayOfMonth, setListDayOfMonth] = useState([]);
  const [listWeek, setListWeek] = useState([]);
  const [optionMonth02, setOptionMonth02] = useState([]);
  const [nextExecutionDate, setNextExecutionDate] = useState("");
  const [listExecuteDay, setListExecuteDay] = useState([]);

  const handleDisplaySchedule = async (mode, team_id, process_id,trigger_type) => {
    const result = await processApi.getById({
      team_id: team_id,
      process_id: process_id,
      trigger_type:trigger_type
    });
    if(result && result.data){
      const process = result?.data;
      //console.log(process)
      if(mode == 1){
        setNextExecutionDate(FormatDate(process.trigger_next_execution_start_time))
        setListDayOfMonth(process.schedules.listDayOfMonth.length > 0 ? [...process.schedules.listDayOfMonth] : [`${new Date(process.trigger_start_time || new Date()).getDate()}`]);
        setListDay(process.schedules.listDayOfWeek.length > 0 ? [...process.schedules.listDayOfWeek] : [`${new Date(process.trigger_start_time || new Date()).getDay()}`]);
        setListWeek(process.schedules.listWeekOfMonth.length > 0 ? [...process.schedules.listWeekOfMonth] : [`${getWeekOfMonth(process.trigger_start_time || new Date())}`]);
        if(process.trigger_type == 8){
          setShowModalSchedulerWeekDay(true);
        }
        else if(process.trigger_type == 6){
          setOptionMonth02(setWeekMonth(process));
          setShowModalSchedulerWeekMonth(true);
        }
        else if(process.trigger_type == 9){
          setShowModalSchedulerMonthDay(true);
        }
        else{
          setShowModalSchedulerDay(true);
        }
        setListExecuteDay([])
      }
      else if(mode == 2){
        setNextExecutionDate("")
        
        const execute_day = [...process.execute_day].map((item, index) => {
          let get_trigger_last_execution_start_time = '';
          if((new Date(item.trigger_last_execution_start_time) - new Date(item.entry_date) > 0 ) || item.pre_column1 !== null){
            get_trigger_last_execution_start_time = new Date(item.trigger_last_execution_start_time) - new Date(item.pre_column1 !== 'null' ? item.pre_column1 : null) > 0 ? 
            new Date(item.trigger_last_execution_start_time) - new Date(item.entry_date) > 0 ? FormatDate(item.trigger_last_execution_start_time) : ''
            :
            new Date(item.pre_column1) - new Date(item.entry_date) > 0 ? FormatDate(item.pre_column1) : ''
          }
          return {
            no:index+1,
            trigger_next_execution_start_time: item.trigger_next_execution_start_time ? FormatDate(new Date(item.trigger_next_execution_start_time)) : '',
          };
        });
        setListExecuteDay(execute_day)
        setShowModalExecution(true);
      }
    }
    else{
      setNextExecutionDate("")
      setListExecuteDay([])
    }
  }

  ////////////////////////////////////////////////////////////////////////
  const [sortOrder, setSortOrder] = useState(null);
  const [sortFilter, setSortFilter] = useState({
    filterProcessIsAdmin: false,
    filterProcessName: '',
    filterUserName: '',
    filterNextStartTime: [null, null],
    filterLastStartTime: [null, null],
    filterEditDate: [null, null],
  });
  const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys[0];
      return { ...newListSort };
    });
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys;
      return { ...newListSort };
    });
  };
  const handleReset = (clearFilters, filterName, confirm) => {
    if (filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality' || filterName === 'filterEndTimeReality') {
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = [];
        return { ...newListSort };
      });
    } else {
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = '';
        return { ...newListSort };
      });
    }
    confirm();
    clearFilters();
  };

  ///// lọc theo text
  const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={placeholder}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
            icon={<FaFilter />}
            size='small'
            style={{
              width: 90,
            }}
          ></Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              return clearFilters && handleReset(clearFilters, filterName, confirm);
            }}
            size='small'
            type='primary'
            style={{
              width: 90,
            }}
            icon={<FaEraser />}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });
  ///// lọc theo ngày
  const getColumnSearchDateProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, padding: 10 }}>
          <div>
            <RangePicker
              type=''
              defaultValue={sortFilter.filterNextStartTime}
              value={selectedKeys.length ? selectedKeys.map((date) => (date ? moment(date) : null)) : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? [moment(dates[0]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(), moment(dates[1]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()] : []);
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              onClick={() => {
                handleSearchDate(selectedKeys, confirm, dataIndex, filterName);
              }}
              size='small'
              type='primary'
              style={{ width: 90, marginRight: 8 }}
              icon={<FaFilter />}
            />
            <Button
              onClick={() => {
                setSelectedKeys([]);
                return clearFilters && handleReset(clearFilters, filterName, confirm);
              }}
              size='small'
              type='primary'
              style={{ width: 90 }}
              icon={<FaEraser />}
            />
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });
  const [orderDirection, setOrderDirection] = useState();
  const changeOrder = (direction) => () => {
    setOrderDirection(direction);
  };

  const handleSort = (key,dataProcess,sortOrder) => {
    const newSortOrder = sortOrder === 'ascend' ? 'descend' : 'ascend';
      setSortOrder(newSortOrder);
    const sortedData = [...dataProcess].sort((a, b) => {
      if (newSortOrder === 'ascend') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setDataProcess(sortedData);
  };
  const newWidth = (windowWidth - 160) * 0.25;
  const Widthtable = windowWidth - 685 - newWidth - 160;

  const [Columns, setColumns] = useState(() => {
    return [
      { title: '', align: 'center', dataIndex: 'no', key: '1', ellipsis: true, width: 50 },
      {
        title: 'プロセス名',
        align: 'left',
        dataIndex: 'process_name',
        key: '2',
        ellipsis: true,
        width: Widthtable * 0.5,
        sorter: {
          compare: (a, b) => a.process_name?.localeCompare(b.process_name),
          multiple: 1,
        },
        ...getColumnSearchProps('process_name', 'プロセス名', 'filterProcessName'),
      },
      {
        title: 'プロセス管理者',
        align: 'left',
        dataIndex: 'user_name',
        key: '3',
        ellipsis: true,
        width: Widthtable * 0.5,
        sorter: {
          compare: (a, b) => a.user_name?.localeCompare(b.user_name),
          multiple: 1,
        },
        ...getColumnSearchProps('user_name', 'プロセス管理者', 'filterUserName'),
      },
      {
        title: '次回実行予定日時',
        align: 'left',
        dataIndex: '',
        key: '4',
        ellipsis: true,
        width: 150,
        sorter: {
          compare: (a, b) => new Date(a.trigger_next_execution_start_time) - new Date(b.trigger_next_execution_start_time),
          multiple: 1,
        },
        ...getColumnSearchDateProps('trigger_next_execution_start_time', '', 'filterNextStartTime'),
        render: (item) => {
          return <span>{item.trigger_next_execution_start_time ? item.trigger_next_execution_start_time : ''}</span>;
        },
      },
      {
        title: '前回実行日時',
        align: 'left',
        dataIndex: 'pre_column1',
        key: '5',
        ellipsis: true,
        width: 130,
        sorter: {
          compare: (a, b) => new Date(a.pre_column1) - new Date(b.pre_column1),
          multiple: 1,
        },
        ...getColumnSearchDateProps('pre_column1', '', 'filterLastStartTime'),
      },
      {
        title: '更新日時',
        align: 'left',
        dataIndex: 'edit_date',
        key: '6',
        ellipsis: true,
        width: 130,
        sorter: {
          compare: (a, b) => new Date(a.edit_date) - new Date(b.edit_date),
          multiple: 1,
        },
        ...getColumnSearchDateProps('edit_date', '', 'filterEditDate'),
      },
      {
        title: '繰り返し',
        align: 'center',
        dataIndex: '',
        key: '7',
        ellipsis: true,
        width: 120,
        sorter: {
          compare: (a, b) => new Date(a.is_repeat) - new Date(b.is_repeat),
          multiple: 1,
        },
        ...getColumnSearchDateProps('is_repeat', '', 'filterEditDate'),
        render: (item) => {
          //return <span>{item.is_repeat ? '設定済' : ''}</span>;
          return <span onClick={() => {handleDisplaySchedule(1, item.team_id, item.process_id)}}>{item.is_repeat ? '設定済' : ''}</span>;
        },
      },
      {
        title: '予約',
        align: 'center',
        dataIndex: '',
        key: '8',
        ellipsis: true,
        width: 80,
        sorter: {
          compare: (a, b) => new Date(a.is_schedule) - new Date(b.is_schedule),
          multiple: 1,
        },
        ...getColumnSearchDateProps('is_schedule', '', 'filterEditDate'),
        render: (item) => {
          return <span onClick={() => {handleDisplaySchedule(2, item.team_id, item.process_id,0)}}>{item.is_schedule ? '設定済' : ''}</span>;
        },
      },
      { title: '', align: 'left', dataIndex: '', key: '30', ellipsis: true },
    ];
  });

  const handleFilterAdmin = (value) => {
    setSortFilter(() => {
      return { ...sortFilter, filterProcessIsAdmin: value };
    });
  };

  useEffect(() => {
    let dataFilterProcess = filterData(listProcess, sortFilter);
    const checkProcessInfo = dataFilterProcess.findIndex((item) => +item.process_id === +processInfo.process_id);
    if (checkProcessInfo === -1) {
      setProcessInfo({});
    }
    setDataProcess(() => {
      return dataFilterProcess.map((item, index) => {
        return { ...item, no: index + 1 };
      });
    });
    setPagination((prevPagination) => {
      return { ...prevPagination };
    });
  }, [sortFilter]);
  ////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location.pathname.includes('start')) {
      return;
    }
    if (!location.pathname.includes('delete')) {
      // setProcessInfo({});
    }
    if (listProcess.length > 0) {
      setDataProcess(() => {
        let dataFilterProcess = filterData(listProcess, sortFilter).map((item, index) => {
          return { ...item, no: index + 1 };
        });
        return dataFilterProcess;
      });
    }
  }, [location, listProcess]);

  const items = [
    {
      key: '1',
      label: (
        <span
          onClick={() => {
            if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
              toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
                toastId: 'toast_warning',
                position: 'top-right',
                autoClose: 3000,
                closeOnClick: false,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
              setShowModalConfirm(false);
              return;
            }
            setShowModalConfirm(true);
            setProcessName(processInfo.process_name);
            navigation({
              pathname: `/process/start`,
            });
          }}
        >
          今すぐ実行
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span
          onClick={() => {
            if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
              toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
                toastId: 'toast_warning',
                position: 'top-right',
                autoClose: 3000,
                closeOnClick: false,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
              return;
            }
            const filter = {
              process_id: processInfo.process_id,
            };
            navigation({
              pathname: `/process/scheduler`,
              search: queryString.stringify(filter),
            });
          }}
        >
          予約して実行
        </span>
      ),
    },
  ];

  return (
    <div
    // style={{animation:'animation_toptobottom 0.5s'}}
    >
      <Spin spinning={spinningProcessList} size='large' className='process_content_spining'>
        <Header className='header__common' style={{ marginBottom: 20 }}>
          <Row gutter={[16, 16]} style={{ height: '99%' }}>
            <Col
              span={24}
              lg={{
                span: 18,
              }}
              md={{
                span: 18,
              }}
            >
              <div className='flex__box flex__jus__sb'>
                <div className='header__title'>
                  <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                    <FaProjectDiagram size={20} color='#000' />
                    <h1 className='header__content__title'>プロセス定義</h1>
                  </div>
                </div>
                <div className='flex__box flex__jus__sb flex__gap__cl--20' style={{ flex: 1, justifyContent: 'flex-end' }}>
                  <div className='hearder__search' style={{ width: '50%' }}>
                    {/* <Input
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleSearch();
                        }
                      }}
                      onChange={(value) => {
                        const text = value.target.value;
                        handleOnchangeSearch(text);
                      }}
                      placeholder='キーワードを入力'
                      suffix={<FaSearch onClick={handleSearch} style={{ cursor: 'pointer' }} />}
                    /> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              span={24}
              lg={{
                span: 6,
              }}
              md={{
                span: 6,
              }}
            >
              <div className='hearder__btn__new' style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button className='i3link__btn'>
                  <Link to='/process/new'> 新規作成</Link>
                </Button>
              </div>
            </Col>
          </Row>
        </Header>
        <Content>
          <Row gutter={[16, 16]} style={{ height: '99%', marginRight: 0 }}>
            <Col
              span={24}
              lg={{
                span: 18,
              }}
              md={{
                span: 18,
              }}
            >
              <div className='common__content__left' style={{ width: '100%', padding: 10 }}>
                <TableI3links idProcess={processInfo.process_id || null} handleFilterAdmin={handleFilterAdmin} checkboxFilterLabel='自分が管理者' className='common__table title_table_small' scroll={{ x: '100%' }} spinning={spinning} Pagination={pagination} handlePaginationNexted={handlePaginationNexted} handleDoubleClick={handleDoubleClick} handleClick={SaveUserIdClick} type='radio' pageSizeOptions={['10', '25', '50', '100']} dataSource={dataProcess ? dataProcess : listProcess} total={dataProcess ? dataProcess.length : listProcess.length} position='topRight' columns={Columns} />
              </div>
            </Col>
            <Col
              span={24}
              lg={{
                span: 6,
              }}
              md={{
                span: 6,
              }}
            >
              <div className='common__content__righ' style={{ background: '#fff', maxHeight: '1080px', height: 'calc(100vh - 130px)', overflow:'auto', padding: '10px 16px', position: 'sticky', top: windowWidth < 800 ? 'initial' : 120 }}>
                <Row style={{ height: '100%' }}>
                  <Col span={24} style={{ height: '35%',minHeight:180 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: '100%' }}>
                      <div className='common__content__right--top flex__box flex__jus__c mt_right--10'>
                        <Dropdown
                          menu={{
                            items,
                          }}
                          placement='bottomLeft'
                        >
                          <Button disabled={spinningProcessList} className='i3link__btn bg__green'>
                            <span>プロセス実行</span>
                          </Button>
                        </Dropdown>
                        {/* <Button
                          disabled={spinningProcessList}
                          className='i3link__btn bg__green'
                         
                        >
                          <span>プロセス実行</span>
                        </Button> */}
                        {processInfo.process_name && !location.pathname.includes('delete') && (
                          <Modal
                            className='modle__confirm'
                            maskClosable={false}
                            closable={false}
                            width={'80%'}
                            style={{ maxWidth: '500px' }}
                            title=''
                            open={showModalConfirm}
                            onCancel={() => {
                              setShowModalConfirm(false);
                              navigation({
                                pathname: `/process`,
                              });
                            }}
                            forceRender
                            footer={[
                              <div key={0} className='modle__user__btn flex__box flex__jus__c'>
                                <Button
                                  className='i3link__btn bg__red'
                                  onClick={() => {
                                    setShowModalConfirm(false);
                                    navigation({
                                      pathname: `/process`,
                                    });
                                  }}
                                >
                                  キャンセル
                                </Button>
                                <Button
                                  className='i3link__btn bg__blue'
                                  onClick={() => {
                                    handleExecute();
                                  }}
                                >
                                  OK
                                </Button>
                              </div>,
                            ]}
                          >
                            <Row>
                              <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                プロセス名：
                                <Input
                                  suffix={
                                    <Tooltip title={'今回の実行に合わせた一時的なプロセス名に変更することができます。'}>
                                      <Button
                                        type='text'
                                        icon={changeProcessNm ? <FaCheck /> : <FaPen />}
                                        onClick={() => {
                                          setChangeProcessNm(!changeProcessNm);
                                        }}
                                      />
                                    </Tooltip>
                                  }
                                  maxLength={40}
                                  readOnly={!changeProcessNm}
                                  //width={'100%'}
                                  bordered={false}
                                  value={processName}
                                  defaultValue={processInfo.process_name}
                                  className={changeProcessNm ? 'confirm_input_enable' : 'confirm_input_disable'}
                                  style={{ flex: 1, flexGrow: 1 }}
                                  onChange={(e) => {
                                    setProcessName(e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>実行してよろしいですか？</Col>
                            </Row>
                          </Modal>
                        )}
                      </div>
                      <div className='common__content__right--top flex__box flex__jus__c mt_right--10'>
                        <Button
                          disabled={spinningProcessList}
                          className='i3link__btn bg__blue'
                          onClick={() => {
                            handleEdit();
                          }}
                        >
                          編 集
                        </Button>
                      </div>
                      <div className='common__content__right--top flex__box flex__jus__c mt_right--10'>
                        <Button
                          disabled={spinningProcessList}
                          className='i3link__btn bg__purple'
                          onClick={() => {
                            handleEditAdmin();
                          }}
                        >
                          プロセス管理者変更
                        </Button>
                      </div>
                      <div className='common__content__right--top flex__box flex__jus__c mt_right--10'>
                        <Button
                        style={{backgroundColor:'#fcad05'}}
                          disabled={spinningProcessList}
                          className='i3link__btn '
                          onClick={() => {
                            handleCopy();
                          }}
                        >
                          プロセスをコピー
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col span={24} style={{ height: '50%',minHeight:220  }}>
                    <div className='common__content__right--middle'>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>プロセス名：</span>
                        <span className='common__content__right--name '>{processInfo.process_name}</span>
                      </p>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>プロセス管理者：</span>
                        <span className='common__content__right--name '>{processInfo.user_name}</span>
                      </p>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>自動実行間隔：</span>
                        <span className='common__content__right--name'>
                          {processInfo.trigger_interval != 0 ? processInfo.trigger_interval : ''} 
                          {defaultOption.find((item) => +item.value === (+processInfo.trigger_type === 9 || +processInfo.trigger_type === 6 ? 9 : +processInfo.trigger_type)) 
                          && defaultOption.find((item) => +item.value === (+processInfo.trigger_type === 9 || +processInfo.trigger_type === 6 ? 9 : +processInfo.trigger_type)).label}
                        </span>
                      </p>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>次回実行予定日時：</span>
                        <span className='common__content__right--name'>{processInfo.trigger_next_execution_start_time}</span>
                      </p>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>登録者：</span>
                        <span className='common__content__right--name '>{processInfo.entry_name}</span>
                      </p>
                      <p className='common__content__right--info'>
                        <span className='common__content__right--lable'>登録日時：</span>
                        <span className='common__content__right--name'>{processInfo.entry_date ? FormatDate(new Date(processInfo.entry_date)) : ''}</span>
                      </p>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>更新者：</span>
                        <span className='common__content__right--name '>{processInfo.edit_name}</span>
                      </p>
                      <p className='common__content__right--info common_text-clamp  common_text-clamp--2'>
                        <span className='common__content__right--lable'>更新日時：</span>
                        <span className='common__content__right--name'>{processInfo.edit_date ? FormatDate(new Date(processInfo.edit_date)) : ''}</span>
                      </p>
                    </div>
                  </Col>
                  <Col span={24} style={{ height: '12%' }}>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                      <div className='common__content__right--bottom flex__box flex__jus__c'>
                        <Button
                          disabled={spinningProcessList}
                          className='i3link__btn'
                          onClick={() => {
                            handleConfirmDelete();
                          }}
                        >
                          削 除
                        </Button>
                        <ModalConfirm handleSubmit={handleDeleteProcess}  textConfirm={`選択されたプロセスを削除してよろしいですか？`} textConfirmMid={`プロセス名: ${processInfo.process_name}`} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Content>
      </Spin>
      <Modal
          open={showModalSchedulerDay}
          onCancel={() => {setShowModalSchedulerDay(false)}}
          header={''}
          footer={null}
          width={'500px'}
          height={"auto"}
          closable={false}        
      >
        <div className='' style={{ width: 470 }}>
          <div style={{ fontSize: 14 }}>
            次回: {nextExecutionDate}<br/>
            実行間隔: 日<br/>
          </div>
        </div>
      </Modal>
      <Modal
          open={showModalSchedulerWeekDay}
          onCancel={() => {setShowModalSchedulerWeekDay(false)}}
          header={''}
          footer={null}
          width={'500px'}
          height={"auto"}
          closable={false}        
      >
        <div className='' style={{ width: 470 }}>
          <div style={{ fontSize: 14 }}>
            次回: {nextExecutionDate}<br/>
            実行間隔: 週<br/>
          </div>
          <Checkbox.Group
            options={options}
            value={[...listDay]}
            style={{readOnly: "true"}}
          />
        </div>
      </Modal>
      <Modal
          open={showModalSchedulerWeekMonth}
          onCancel={() => {setShowModalSchedulerWeekMonth(false)}}
          header={''}
          footer={null}
          width={'500px'}
          height={"auto"}
          closable={false}        
      >
        <div className='' style={{ width: 470 }}>
          <div style={{ fontSize: 14 }}>
            次回: {nextExecutionDate}<br/>
            実行間隔: 月<br/>
            設定パターン: 曜日<br/>
          </div>
          {optionMonth02.map((item, index) => {
            return (
              <div style={{ padding: '5px 0px' }} key={index}>
                <span style={{ margin: 0, fontWeight: 'bold' }}>{item.label}</span>
                <div style={{ width: 470 }}>
                  <Checkbox.Group
                    options={options}
                    value={[...item.days]}
                    style={{readOnly: "true"}}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
          open={showModalSchedulerMonthDay}
          onCancel={() => {setShowModalSchedulerMonthDay(false)}}
          header={''}
          footer={null}
          width={'500px'}
          height={"auto"}
          closable={false}        
      >
        <div style={{ width: 470 }}>
          <div style={{ fontSize: 14 }}>
            次回: {nextExecutionDate}<br/>
            実行間隔: 月<br/>
            設定パターン: 日付<br/>
          </div>
          <Checkbox.Group
            options={optionDays}
            value={listDayOfMonth}
            style={{readOnly: "true"}}
          />
        </div>
      </Modal>
      <Modal
          open={showModalExecution}
          onCancel={() => {setShowModalExecution(false)}}
          header={''}
          footer={null}
          width={'220px'}
          height={"auto"}
          closable={false}        
      >
          <div>
            <Table 
              header={null}
              size='small' 
              type='radio' 
              showHeader={false}
              pagination={false} 
              dataSource={listExecuteDay} 
              columns={
                [
                  { 
                    align: 'center', 
                    dataIndex: 'no', 
                    key: '1', 
                    ellipsis: false
                  },
                  { 
                    align: 'left', 
                    dataIndex: 'trigger_next_execution_start_time', 
                    key: '2', 
                    ellipsis: false
                  }
                ]
              } 
            />

          </div>
      </Modal>
    </div>
  );
}

export default ProcessList;

import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authenticationsApi from '../../api/authenticationsApi';
import { COMMON_MESSAGES } from '../../commons/commonMessages/commonMessages';
import SignIn from '../../components/Auth/SignIn';
import teamSlice from '../../components/I3linkSider/reduxTeam/teamSlice';

PageLogin.propTypes = {};

function PageLogin(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [spinning, setSpinning] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  useEffect(() => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('accessToken');
    const action = teamSlice.actions.addTeam({listTeam:[]})
    dispatch(action)
  }, []);
  const handleSignIn = async (values) => {
    if (values) {
      try {
        setSpinning(true);
        const result = await authenticationsApi.Login(values);
        const { accessToken, userInfo, temp_login_password_flg, teamInfo } = result;
        if (result.error) {
          if(result.onDisplay && !toast.isActive({toastId:'toastId_err'})){
            setErrormessage(result.error);
          }else
          {
            toast.error(result.error, {
              toastId:'toastId_err',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
          navigate('/login');
          setSpinning(false);
          return;
        }
        if (+temp_login_password_flg === 1 && !toast.isActive({toastId:'toast_warning'})) {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('teamInfo',JSON.stringify(teamInfo))
          toast.warning(COMMON_MESSAGES.AUTH.CHANGEPASSWORD, {
            toastId:'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          navigate('/resetpassword');
          return;
        }
        if (accessToken && userInfo && +temp_login_password_flg === 0) {
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('isSuperAdmin', 0);
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('teamInfo',JSON.stringify(teamInfo))
          const flag_history_location =  sessionStorage.getItem('history_location')
          if(flag_history_location){
            navigate(`${flag_history_location}`);
            sessionStorage.removeItem('history_location')   
          }else{
            navigate('/reference/processing');
          }
          return;
        
        }
        setSpinning(false);
      } catch (error) {
       
        setSpinning(false);
        navigate('/login');
      }
    }
  };
  return (
    <div className='common__signin '>
      <Spin spinning={spinning}  size='large' className='common__signin__loading'>
        <SignIn onSignIn={handleSignIn} errormessage={errormessage}/>
      </Spin>
    </div>
  );
}

export default PageLogin;

import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { toast } from 'react-toastify';
import logoGakkenCompany from '../../assets/GAKKEN_LOGO.png';
import logo from '../../assets/logo.jpg';
import './css/style.css';
import authenticationsApi from '../../api/authenticationsApi';

ResetPassword.propTypes = {};

function ResetPassword(props) {
  const { onResetPassword } = props;
  const [form_resetPassword] = Form.useForm();
  const onFinish = async(values) => {
    const oldPassword = values.oldPassword.trim();
    const newPassword = values.newPassword.trim();
    const confirmNewPassword = values.confirmNewPassword.trim();
    let flag = false
    if (!oldPassword || oldPassword.length < 10) {
      form_resetPassword.setFields([
        {
          name: 'oldPassword',
          errors: ['現在のパスワードが正しくありません。'],
        },
      ]);
      flag = true
    }
    if (!newPassword || !newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/)) {
      form_resetPassword.setFields([
        {
          name: 'newPassword',
          errors: [`新しいパスワードが正しくありません。`, `アルファベット(a～zとA～Z)、数字(0～9),特殊文字(!@#$%^&.())のみを使用してください。`],
        },
      ]);
      flag = true
    }
    if (newPassword === oldPassword) {
      form_resetPassword.setFields([
        {
          name: 'newPassword',
          errors: [`「現在のパスワード」と「新しいパスワード」が同じです。`],
        },
      ]);
      flag = true
    }
    if (newPassword !== confirmNewPassword) {
      form_resetPassword.setFields([
        {
          name: 'confirmNewPassword',
          errors: ['新しいパスワードが一致しません。'],
        },
      ]);
      flag = true
    }
    
    if (onResetPassword && !flag) {
      onResetPassword({ oldPassword, newPassword, confirmNewPassword });
    }
  };

  return (
    <div className='login'>
      <div className='login__box'>
        <div className='login__top'>
          <div className='login__top__logo'>
            <img src={logo} alt='logo' />
          </div>
          <div className='login__top__form'>
            <Form form={form_resetPassword} name='form_resetPassword' onFinish={onFinish}>
              <Form.Item
                name='oldPassword'
                rules={[
                  {
                    required: true,
                    message: '現在のパスワードを入力してくさい。',
                  },
                ]}
              >
                <Input.Password style={{ padding: 10 }} autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='現在のパスワード' />
              </Form.Item>
              <Form.Item
                name='newPassword'
                style={{marginBottom:0}}
                rules={[
                  {
                    required: true,
                    message: '新しいパスワードを入力してください。',
                  },
                ]}
              >
                <Input.Password style={{ padding: 10 }} autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='新しいパスワード' />
              </Form.Item>
              <p >英大文字、英小文字、数字、特殊記号 を混在させた10文字以上の文字列</p>
              <Form.Item
                name='confirmNewPassword'
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: '新しいパスワードが一致しません。',
                  },
                ]}
              >
                <Input.Password style={{ padding: 10 }} autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='新しいパスワード(確認）' />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='login-form-button i3link__btn'>
                  変更
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className='login__top__forgot--password'></div>
        </div>
        <div className='login__middle'></div>
        <div className='login__bottom'>
          <div className='login__bottom__logo'>
            <img src={logoGakkenCompany} alt='logo' />
          </div>
          <div className='login__bottom__name'>
            <p>Gakken Logistics Co.,Ltd. © 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import { Button, Input, Layout } from 'antd';
import React, { useEffect } from 'react';
import { FaCog, FaLock, FaSearch } from 'react-icons/fa';
import { Link, NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ProfileChangePasswordContainer from './components/ProfileChangePassword';
import './css/style.css';
const { Header, Content, Footer } = Layout;

Profile.propTypes = {};

function Profile(props) {
  const location = useLocation();
  const navigation = useNavigate();

  const handleOnchangeSearch = (value) => {};
  const handleSearch = () => {};
  useEffect(() => {}, [location.pathname]);
  return (
    <>
      <Header className='header__hrm'>
        <div className='flex__box flex__jus__sb'>
          <div className='header__title'>
            <h1>
              <FaLock color='#000' /> パスワード変更
            </h1>
          </div>
          <div className='flex__box flex__jus__sb flex__gap__cl--20'>
            <div className='hearder__search'></div>
            <div className='hearder__btn__new'></div>
          </div>
        </div>
      </Header>
      <Content>
        <Routes>
          <Route path='/resetpassword' element={<ProfileChangePasswordContainer />} />
        </Routes>
      </Content>
    </>
  );
}

export default Profile;

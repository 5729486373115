import { Button, DatePicker, TimePicker } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import React, { useState } from 'react';
import { FaCalendarAlt, FaPlus, FaRegClock, FaRegTimesCircle } from 'react-icons/fa';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import InputI3links from '../../../../commons/commonComponents/inputI3links/InputI3links';
import { isPastDate } from '../../../../commons/commonFunctions/CommonFunction';
import { toast } from 'react-toastify';
import moment from 'moment';

SortListSchedule.propTypes = {};

function SortListSchedule(props) {
  const { listScheduleTime, handleAddSchedule, handleRemoveSchedule, handleEdit } = props;
  const handleEditName = (value) => {
    const newItem = { ...value, name: value.task_name };
    handleEdit(newItem);
  };
  const SortableItem = SortableElement(({ item,index }) => {
    return (
      <div className='common__flex process__task--item'>
        <div className='common__flex process__task--itemL'>
          <div className='process__task--item--number' style={{ height: 100 }}>
            <p>{item.key + 1}</p>
          </div>
          <div className=' process__task--item--name'>
            <div className='common__flex process__schedule--time '>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', columnGap: 20 }}>
                  <DatePicker
                    style={{ fontWeight: 600, minWidth: 200, border: '1px solid #00a199', padding: `6px 11px` }}
                    className={''}
                    allowClear={false}
                    format={'yyyy/MM/DD'}
                    value={item.startTime ? moment(item.startTime) : null}
                    onChange={(value) => {
                      if (value?._d) {
                        const checkIsPastDate = isPastDate(value?._d);
                        if (checkIsPastDate) {
                          toast.warning('現在日時以降を選択してください。', {
                            toastId: 'schedule_check_day',
                            position: 'top-right',
                            autoClose: 3000,
                            closeOnClick: true,
                            theme: 'light',
                            pauseOnHover: false,
                            hideProgressBar: true,
                          });
                          const now = new Date();
                          now.setMinutes(now.getMinutes());
                          handleEdit({...item,startTime:new Date(now)})
                          return;
                        }
                        handleEdit({ ...item, startTime: value?._d });
                      }
                    }}
                    // placeholder=''
                    suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                    size='large'
                  />
                  <TimePicker
                    value={item.startTime ? moment(item.startTime) : null}
                    suffixIcon={<FaRegClock size={20} color='#000' />}
                    format={'HH:mm'}
                    showNow={false}
                    className='process__schedule--TimePicker'
                    clearIcon={false}
                    onSelect={(value) => {
                      if (value?._d) {
                        const hourCurrent = new Date(value._d).getHours();
                        const mitunuteCurrent = new Date(value._d).getMinutes();
                        let newDate = new Date(item.startTime);
                        newDate.setHours(hourCurrent);
                        newDate.setMinutes(mitunuteCurrent);
                        const checkIsPastDate = isPastDate(newDate);
                        if (checkIsPastDate) {
                          toast.warning('現在日時以降を選択してください。', {
                            toastId: 'schedule_check_day',
                            position: 'top-right',
                            autoClose: 3000,
                            closeOnClick: true,
                            theme: 'light',
                            pauseOnHover: false,
                            hideProgressBar: true,
                          });
                          return;
                        }
                        handleEdit({ ...item, startTime: newDate });
                      }
                    }}
                    onChange={(value) => {
                      if (value?._d) {
                        const hourCurrent = new Date(value._d).getHours();
                        const mitunuteCurrent = new Date(value._d).getMinutes();
                        let newDate = new Date(item.startTime);
                        newDate.setHours(hourCurrent);
                        newDate.setMinutes(mitunuteCurrent);
                        const checkIsPastDate = isPastDate(newDate);
                        if (checkIsPastDate) {
                          toast.warning('現在日時以降を選択してください。', {
                            toastId: 'schedule_check_day',
                            position: 'top-right',
                            autoClose: 3000,
                            closeOnClick: true,
                            theme: 'light',
                            pauseOnHover: false,
                            hideProgressBar: true,
                          });
                          return;
                        }
                        handleEdit({ ...item, startTime: newDate });
                      }
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <InputI3links textAlign='center' autoFocus={false} placeholder='ここにプロセス名称を入力' item={{ ...item, task_name: item.name }} handleEdit={handleEditName} maxLength={41} />
                </div>
              </div>
              <Button
                className='process__task--item--btn'
                style={{ boxShadow: 'none' }}
                onClick={() => {
                  // const id = item.id;
                  handleRemoveSchedule(item.key);
                }}
              >
                <FaRegTimesCircle size={30} />
              </Button>
            </div>
          </div>
        </div>
        <div className='process__task--itemR'>
          <Button
            className='process__task--item--btn'
            style={{ boxShadow: 'none' }}
            onClick={() => {
              handleAddSchedule();
            }}
          >
            <FaPlus size={30} />
          </Button>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <div className='common__flex process__listtask '>
      {items?.map((item, index) => {
        return <SortableItem key={`${index}`} index={index} item={item} />;
      })}
    </div>
  ));

  const onChangeOrder = (value) => {
    // console.log(value);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newData = arrayMoveImmutable(listScheduleTime, oldIndex, newIndex);
    if (onChangeOrder) {
      onChangeOrder(newData);
    }
  };

  return (
    <>
      <SortableList items={listScheduleTime} onSortEnd={onSortEnd} distance={1} axis='xy' helperClass='SortableHelper' />
    </>
  );
}

export default SortListSchedule;

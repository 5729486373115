import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { Button, Checkbox, DatePicker, InputNumber, Modal, Radio, Select, Space, Switch, TimePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FaCalendarAlt, FaCaretDown, FaRegClock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { checkDate, checkLastDayOfMonth, checkPrevDate } from '../../../../../commons/commonFunctions/CommonFunction';
import { FormatDate } from '../../../../../commons/commonFunctions/FormatDate';
import { FormatDateCustom } from '../../../../../commons/commonFunctions/FormatDate';
import { processState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';

StepCreateProcessScheduler.propTypes = {};
const defaultOption = [
  {
    value: '1',
    label: '日',
  },
  {
    value: '8',
    label: '週',
  },
  {
    value: '3',
    label: '月',
  },
];
const options = [
  {
    value: '0',
    label: '日',
  },
  {
    value: '1',
    label: '月',
  },
  {
    value: '2',
    label: '火',
  },
  {
    value: '3',
    label: '水',
  },
  {
    value: '4',
    label: '木',
  },
  {
    value: '5',
    label: '金',
  },
  {
    value: '6',
    label: '土',
  },
];


const optionDays = new Array(32).fill(null).map((item, index) => {
  return {
    value: index === 31 ? `99` : `${index + 1}`,
    label: `${index === 31 ? '最終' : index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}`,
  };
});
const mess = {
  message1: '現在日時以降を選択してください。',
  message2: '入力された日付は最大値を超えました。',
  message3: 'プロセス開始予定日時以降を選択してください。',
};



const getWeekOfMonth = (date) => {
  const newDate = new Date(date)
  // Lấy ngày, tháng, năm từ đối tượng date
  const dayOfMonth = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();

  // Lấy thứ trong tuần
  const dayOfWeek = newDate.getDay(); // 0 = Chủ Nhật, 1 = Thứ Hai, ..., 6 = Thứ Bảy

  // Tính số lần xuất hiện của thứ trong tháng
  let occurrence = 0;
  for (let day = 1; day <= dayOfMonth; day++) {
      const currentDate = new Date(year, month, day);
      if (currentDate.getDay() === dayOfWeek) {
          occurrence++;
      }
  }

  return occurrence;
};
const  getLast7DaysOfMonth =(date)=> {
  const now = new Date(date);

  // Lấy ngày đầu tiên của tháng sau
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

  // Tính ngày cuối cùng của tháng hiện tại (ngày trước ngày đầu tiên của tháng sau)
  const lastDayOfMonth = new Date(nextMonth - 1);

  // Lấy 7 ngày cuối cùng của tháng
  const last7Days = {};
  for (let i = 6; i >= 0; i--) {
    const date = new Date(lastDayOfMonth);
    date.setDate(lastDayOfMonth.getDate() - i);  // Lùi về 7 ngày
    const occurrence = getWeekOfMonth(date);  // Hàm giả định có sẵn để lấy ngày trong tuần
          // Kiểm tra và khởi tạo mảng nếu chưa có
          if (!last7Days[occurrence]) {
            last7Days[occurrence] = [];
          }
          // Thêm ngày vào mảng tương ứng
          last7Days[occurrence].push(`${date.getDay()}`);
        }

        return last7Days;
}
function getCommonElements(arr1, arr2) {
  const set2 = new Set(arr2);  // Chuyển arr2 thành Set
  return arr1.filter(item => set2.has(item));  // Kiểm tra phần tử trong Set
}

function StepCreateProcessScheduler(props) {  
  const { nextStep, prevStep } = props;
  const processInfo = useSelector(processState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(() => {
    let check = false;
    return check;
  });
  const [optionMonth, setOptionMonth] = useState(() => {
    if (+processInfo.trigger_type === 9) {
      return 1;
    }
    return 2;
  });
  const [openModal, setOpenModal] = useState(false);
  const [useSchedule, setUseSchedule] = useState(() => {
    const check = processInfo.newProcess ? 'checked' : processInfo.trigger_start_time ? 'checked' : false;
    return check;
  });

  const [triggerStartTime, setTriggerStartTime] = useState(() => {
    let triggerStartTimeNow = new Date();
    triggerStartTimeNow.setMinutes(triggerStartTimeNow.getMinutes() + 15);
    return processInfo.trigger_next_execution_start_time ? FormatDateCustom(new Date(processInfo.trigger_next_execution_start_time)) : processInfo.trigger_start_time ? FormatDateCustom(new Date(processInfo.trigger_start_time)) : FormatDateCustom(new Date(triggerStartTimeNow));
  });

  const [timeStart, setTimeStart] = useState(() => {
    let triggerStartTimeNow = new Date();
    triggerStartTimeNow.setMinutes(triggerStartTimeNow.getMinutes() + 15);
    return processInfo.trigger_start_time ? FormatDate(new Date(processInfo.trigger_start_time)) : FormatDate(triggerStartTimeNow);
  });

  const [triggerType, setTriggerType] = useState(() => {
    // return processInfo.trigger_type !== null ? ((+processInfo.trigger_type !== 1 && +processInfo.trigger_type !== 8) ? '3' : processInfo.trigger_type !== null ? processInfo.trigger_type : '1') : '1';
    if (processInfo.trigger_type) {
      return +processInfo.trigger_type === 8 || +processInfo.trigger_type === 2 ? '8' : +processInfo.trigger_type === 3 || +processInfo.trigger_type === 5 || +processInfo.trigger_type === 9 || +processInfo.trigger_type === 6 ? '3' : '1';
    }
    return '1';
  });

  const [optionMonth02, setOptionMonth02] = useState(() => {
    if (processInfo.schedules.listWeekOfMonth) {
      const newList = [...processInfo.schedules.listWeekOfMonth];
      const indexWeek = getWeekOfMonth(processInfo.trigger_start_time || new Date());
      const indexDay = new Date(processInfo.trigger_start_time || new Date()).getDay();
      return [
        ...newList.map((week, index) => {
          let newWeek = { ...week, isWeekStart: false };
          if (indexWeek === index + 1) {
            newWeek = { ...newWeek, isWeekStart: true };
          }
          // if(index === 5){
          //   newWeek = {...newWeek,days: [...newWeek.days.filter((item) => +item != +indexDay)]}
          // }
          return newWeek;
        }),
      ];
    }
    return [
      {
        value: '1',
        label: '第1',
        days: [],
      },
      {
        value: '2',
        label: '第2',
        days: [],
      },
      {
        value: '3',
        label: '第3',
        days: [],
      },
      {
        value: '4',
        label: '第4',
        days: [],
      },
      {
        value: '5',
        label: '第5',
        days: [],
      },
      {
        value: '6',
        label: '最終',
        days: [],
      },
    ];
  });

  const [triggerInterval, setTriggerInterval] = useState(() => {
    return +processInfo.trigger_interval || 1;
  });

  const [checkBoxLastdate, setCheckBoxLastdate] = useState(() => {
    const check = checkLastDayOfMonth(processInfo.trigger_start_time);
    return check && +processInfo.trigger_type === 5;
  });

  const [listDayOfMonth, setListDayOfMonth] = useState(() => {
    return processInfo?.schedules?.listDayOfMonth?.length > 0 ? [...processInfo.schedules.listDayOfMonth] : [`${new Date(processInfo.trigger_start_time || new Date()).getDate()}`];
  });
  const [listDay, setListDay] = useState(() => {
    return processInfo?.schedules?.listDayOfWeek?.length > 0 ? [...processInfo.schedules.listDayOfWeek] : [`${new Date(processInfo.trigger_start_time || new Date()).getDay()}`];
  });

  const [listWeek, setListWeek] = useState(() => {
    return processInfo?.schedules?.listWeekOfMonth?.length > 0 ? [...processInfo.schedules.listWeekOfMonth] : [`${getWeekOfMonth(processInfo.trigger_start_time || new Date())}`];
  });

  const renderMaxNumberDay = (numberLoop) => {
    switch (+numberLoop) {
      case 1:
        if (triggerInterval > 999) {
          setTriggerInterval(999);
        }
        return 999;
      case 2:
        if (triggerInterval > 99) {
          setTriggerInterval(99);
        }
        return 99;
      case 3:
        if (triggerInterval > 99) {
          setTriggerInterval(99);
        }
        return 99;
      case 4:
        if (triggerInterval > 10) {
          setTriggerInterval(10);
        }
        return 10;
      default:
        return null;
    }
  };
  /////handle use scheduler/////
  const onChange = (value) => {
    let triggerStartTimeNow = new Date();
    triggerStartTimeNow.setMinutes(triggerStartTimeNow.getMinutes() + 15);
    if (value) {
      setTriggerStartTime(processInfo.trigger_start_time ? FormatDateCustom(new Date(processInfo.trigger_start_time)) : FormatDateCustom(new Date(triggerStartTimeNow)));
      setTimeStart(processInfo.trigger_start_time ? FormatDateCustom(new Date(processInfo.trigger_start_time)) : FormatDateCustom(new Date(triggerStartTimeNow)));
      setTriggerType('1');
      setTriggerInterval(1);
      setUseSchedule(value);
      return;
    } else {
      setTriggerStartTime(null);
      setTimeStart(null);
      setTriggerInterval(null);
      setTriggerType(null);
      setUseSchedule(value);
      return;
    }
  };

  /////handle change date
  const handleChangeDate = (value) => { 
    ///check ngày quá khứ
    const isPrevDate = checkPrevDate(value);
    if (isPrevDate && !toast.isActive({ toastId: 'schedule_check_day' })) {
      toast.warning(mess.message1, {
        toastId: 'schedule_check_day',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });

      setTriggerStartTime(new Date());
      setListDayOfMonth((prev) => {
        const LastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth()  + 1, 0).getDate();
        let newArr = [...prev.filter((item) => item !== `${new Date(triggerStartTime).getDate()}`), `${new Date().getDate()}`];
        const check = new Date().getDate() === LastDayOfMonth && listDayOfMonth.find(item=>+item === 99) ? true : false
        if(check){
          newArr = newArr.filter(item => +item !== 99)
        }
        return newArr
      });
      setListDay((prev) => {
        return [...prev.filter((item) => item !== `${new Date(triggerStartTime).getDay()}`), `${new Date().getDay()}`];
      });
      setListWeek((prev) => {
        return [...prev.filter((item) => item !== `${getWeekOfMonth(triggerStartTime)}`), `${getWeekOfMonth(new Date())}`];
      });

      setOptionMonth02((prev) => {
        const indexWeek = getWeekOfMonth(triggerStartTime);
        const indexDay = new Date(triggerStartTime).getDay();
        const indexWeekNew = getWeekOfMonth(new Date());
        const indexDayNew = new Date().getDay();

        return [
          ...prev.map((week, index) => {
            let newWeek = { ...week };
            if (indexWeek === index + 1) {
              newWeek = { ...newWeek, days: newWeek.days.filter((day) => +day !== indexDay), isWeekStart: false };
            }
            if (indexWeekNew === index + 1) {
              newWeek = { ...newWeek, days: [...newWeek.days, `${indexDayNew}`], isWeekStart: true };
            }
            if (index === 5) {
              newWeek = { ...newWeek, days: [...newWeek.days.filter(item=>+item != +indexDayNew)] };
            }
            return newWeek;
          }),
        ];
      });
      setTimeStart(new Date());
      return;
    }
    ///check ngày chọn có vượt quá 10 năm so với ngày hiện tại
    const dateDiff = checkDate(new Date(), new Date(value));
    if (dateDiff > 10) {
      toast.warning(mess.message1, {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });
      setTriggerStartTime(new Date());
      setTimeStart(new Date());
      setListDayOfMonth((prev) => {
        const LastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth()  + 1, 0).getDate();
        const check = new Date().getDate() === LastDayOfMonth && listDayOfMonth.find(item=>+item === 99) ? true : false
        let newArr = [...prev.filter((item) => item !== `${new Date(triggerStartTime).getDate()}`), `${new Date().getDate()}`];
        if(check){
          newArr = newArr.filter(item => +item !== 99)
        }
        return newArr
      });
      setListDay((prev) => {
        return [...prev.filter((item) => item !== `${new Date(triggerStartTime).getDay()}`), `${new Date().getDay()}`];
      });
      setListWeek((prev) => {
        return [...prev.filter((item) => item !== `${getWeekOfMonth(triggerStartTime)}`), `${getWeekOfMonth(new Date())}`];
      });

      setOptionMonth02((prev) => {
        const indexWeek = getWeekOfMonth(triggerStartTime);
        const indexDay = new Date(triggerStartTime).getDay();
        const indexWeekNew = getWeekOfMonth(new Date());
        const indexDayNew = new Date().getDay();
        return [
          ...prev.map((week, index) => {
            let newWeek = { ...week };
            if (indexWeek === index + 1) {
              newWeek = { ...newWeek, days: newWeek.days.filter((day) => +day !== indexDay), isWeekStart: false };
            }
            if (indexWeekNew === index + 1) {
              newWeek = { ...newWeek, days: [...newWeek.days, `${indexDayNew}`], isWeekStart: true };
            }
            if (index === 5) {
              newWeek = { ...newWeek, days: [...newWeek.days.filter(item=>+item != +indexDayNew)] };
            }
            return newWeek;
          }),
        ];
      });
      return;
    }

    let newValue = new Date(value);
    const newTimeStart = new Date(timeStart);
    const h = newTimeStart.getHours();
    const m = newTimeStart.getMinutes();
    newValue.setHours(h);
    newValue.setMinutes(m);
    setTriggerStartTime(newValue);
    setTimeStart(newValue);
    setListDayOfMonth((prev) => {
      const LastDayOfMonth = new Date(newValue.getFullYear(), newValue.getMonth()  + 1, 0).getDate();
      const check = newValue.getDate() === LastDayOfMonth && listDayOfMonth.find(item=>+item === 99) ? true : false
      let newArr = [...prev.filter((item) => item !== `${new Date(triggerStartTime).getDate()}`), `${newValue.getDate()}`];
      if(check){
        newArr = newArr.filter(item => +item !== 99)
      }
      return newArr
    });
    setListDay((prev) => {  
      return [...prev.filter((item) => item !== `${new Date(triggerStartTime).getDay()}`), `${newValue.getDay()}`];
    });
    setListWeek((prev) => {
      return [...prev.filter((item) => item !== `${getWeekOfMonth(triggerStartTime)}`), `${getWeekOfMonth(newValue)}`];
    });
    setOptionMonth02((prev) => {
      const indexWeek = getWeekOfMonth(triggerStartTime);              
      const indexDay = new Date(triggerStartTime).getDay();
      const indexWeekNew = getWeekOfMonth(newValue);
      const indexDayNew = new Date(newValue).getDay();
      return [
        ...prev.map((week, index) => {
          let newWeek = { ...week };
          if (indexWeek === index + 1) {
            newWeek = { ...newWeek, days: newWeek.days.filter((day) => +day !== indexDay), isWeekStart: false };
          }
          if (indexWeekNew === index + 1) {
            newWeek = { ...newWeek, days: [...new Set([...newWeek.days, `${indexDayNew}`])], isWeekStart: true };
          }
          if (index === 5) {
            newWeek = { ...newWeek, days: [...newWeek.days.filter(item=> +item != +indexDayNew)] };
            }
          return newWeek;
        }),
      ];
    });

    ///check ngày cuối tháng
    /*const check = checkLastDayOfMonth(value);

    if (check) {
      setCheckBoxLastdate(true);
      setTriggerType((prevType) => {
        if (+prevStep === 3) {
          return 5;
        }
        return prevType;
      });
    } else {
      setCheckBoxLastdate(false);
    }*/
  };

  /////handle change Time
  const handleChangeTime = (value) => {
    const date = new Date(triggerStartTime).getDate();
    const month = new Date(triggerStartTime).getMonth();
    const year = new Date(triggerStartTime).getFullYear();
    const hours = new Date(value).getHours();
    const minutes = new Date(value).getMinutes();
    const newtriggerStartDate = new Date(year, month, date, hours, minutes);
    const isPrevDate = checkPrevDate(newtriggerStartDate);

    if (isPrevDate && !toast.isActive({ toastId: 'schedule_check_day' })) {
      toast.warning(mess.message1, {
        toastId: 'schedule_check_day',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });
      setTriggerStartTime(new Date());
      setTimeStart(new Date());
      return;
    }
    setTriggerStartTime(newtriggerStartDate);
    setTimeStart(newtriggerStartDate);
  };

  /////handle change type
  const handleChangeType = (value) => {
    setTriggerType(value);
    if (+value === 3) {
      setOptionMonth(1);
    }
  };

  /////handle change number Interval
  const handleChangeNumberInterval = (value) => {
    setTriggerInterval(value);
  };

  ////////// sử lí trước khi quay về bước trước đó theo
  const handlePrevStep = () => {
    if (prevStep && step === 0) {
      const action = processSlice.actions.addTimeProcess({ 
        schedules: { listDayOfWeek: [...listDay.sort()], listWeekOfMonth: [...optionMonth02], listDayOfMonth: [...listDayOfMonth.sort()] }, 
        triggerStartTime: useSchedule ? triggerStartTime.toString() : null, 
        triggerInterval, 
        triggerType: useSchedule ? (+triggerType !== 8 && +triggerType !== 1 ? (+optionMonth === 1 ? '9' : '6') : triggerType) : null });
      dispatch(action);
      prevStep();
      return;
    }
  };

  ////////// sử lí trước khi chuyển sang bước tiếp theo
  const handleNextStep = () => {
    const check = checkDisableBtn();
    if (check) {
      return;
    }
    if (!check) {
      if (checkPrevDate(triggerStartTime) && useSchedule && !toast.isActive({ toastId: 'schedule_check_day' })) {
        toast.warning(mess.message1, {
          toastId: 'schedule_check_day',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      } else {
        const action = processSlice.actions.addTimeProcess({ schedules: { listDayOfWeek: [...listDay.sort()], listWeekOfMonth: [...optionMonth02], listDayOfMonth: [...listDayOfMonth.sort()] }, triggerStartTime: useSchedule ? triggerStartTime.toString() : null, triggerInterval, triggerType: useSchedule ? (+triggerType !== 8 && +triggerType !== 1 ? (+optionMonth === 1 ? '9' : '6') : triggerType) : null });
        dispatch(action);
        nextStep();
        return;
      }
    }
  };

  /////////check điều kiện của nút next
  const checkDisableBtn = () => {
    if ((triggerStartTime === null || triggerInterval === null || triggerType === null) && useSchedule) {
      return true;
    }
    if (!useSchedule) {
      return false;
    }

    return false;
  };

  ////////////huy create process
  const handleCancelCreateProcess = () => {
    const searchParam = queryString.parse(location.search);
    const action = processSlice.actions.newProcess({});
    dispatch(action);
    if(searchParam.process_id){
      navigate({
        pathname: `/process`,
      });
    }else{
      navigate({
        pathname: `/template`,
      });
    }
    return;
    // localStorage.removeItem('process');
    // const action = processSlice.actions.newProcess({});
    // dispatch(action);
    // setOpenModal(false);
    // navigate('/process');
    // return;
  };

  const handleCancelConfirm = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    if(step === 0){
      setShowModal(()=>{
        let check = false;
          const searchParam = queryString.parse(location.search);
          if (searchParam.template_id && processInfo.pre_column7) {
            check = true;
          }
          return check;
      })
    }
  }, [processInfo]);
  
  return (
    <div className='process__content__bottom--box'>
      <div className='process__new--content process__new--content--custom' style={{ padding: 25 }}>
        <div className='common__process__listtaskbox process__schedule'>
          <div className='process__new--intro process__new--save'>
            <div className='common__flex'>
              <h2 className='color__title__black'>プロセスを定期的に自動実行する</h2>
              <Switch style={{ marginLeft: 10 }} checked={useSchedule} onChange={onChange} />
            </div>

            {useSchedule && <p className='color__text__black '>自動実行を行う条件を設定してください。</p>}

            <>
              <div className='common__flex process__schedule--box'>
                {useSchedule && (
                  <>
                    <div style={{ width: 470 }}>
                      <p className='color__text__black mb--10'>開始日時</p>
                      <div className='common__flex process__schedule--time  mb--10'>
                        <DatePicker
                          style={{ fontWeight: 600, minWidth: 200, border: '1px solid #00a199', padding: `6px 11px` }}
                          disabled={!useSchedule}
                          className={''}
                          allowClear={false}
                          // format={'yyyy 年 MM 月 DD'}
                          format={'yyyy/MM/DD'}
                          value={triggerStartTime !== null ? moment(triggerStartTime) : ''}
                          onChange={(value) => {
                            if (value?._d) {
                              handleChangeDate(value._d);
                              return;
                            }
                          }}
                          placeholder=''
                          suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                          size='large'
                        />
                        <TimePicker
                          suffixIcon={<FaRegClock size={20} color='#000' />}
                          format={'HH:mm'}
                          showNow={false}
                          className='process__schedule--TimePicker'
                          clearIcon={false}
                          value={moment(timeStart)}
                          onSelect={(value) => {
                            if (value?._d) {
                              handleChangeTime(value?._d);
                            }
                          }}
                          onChange={(value) => {
                            if (value?._d) {
                              handleChangeTime(value?._d);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: 470 }}>
                      <p className='color__text__black mb--10'>自動実行間隔</p>
                      <div className='common__flex process__schedule--time  mb--10'>
                        <Select
                          disabled={!useSchedule}
                          className={!useSchedule ? 'common__disable' : ''}
                          size='large'
                          suffixIcon={<FaCaretDown size={20} color='#000' />}
                          placeholder={'選択してください'}
                          style={{ minWidth: 200, border: '1px solid #00a199' }}
                          value={triggerType}
                          options={defaultOption}
                          onChange={(value) => {
                            handleChangeType(value);
                          }}
                        />
                        <InputNumber
                          disabled={!useSchedule}
                          className={!useSchedule ? 'common__disable' : ''}
                          min={1}
                          max={renderMaxNumberDay(triggerType)}
                          size='large'
                          type='number'
                          style={{ minWidth: 200, textAlign: 'center', fontWeight: 600, border: '1px solid #00a199' }}
                          value={triggerInterval}
                          onChange={(value) => {
                            if (value) {
                              handleChangeNumberInterval(value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {triggerType === '8' && (
                      <div className='' style={{ width: 470, marginTop: 20, animation: 'animation_bottomtotop 0.5s' }}>
                        <Checkbox.Group
                          options={options}
                          value={[...listDay]}
                          onChange={(value) => {
                            const newValue = [...new Set(value)].filter((item) => item !== `${new Date(triggerStartTime).getDay()}`);
                            if (value.findIndex((item) => item === `${new Date(triggerStartTime).getDay()}`) === -1) {
                              toast.warning('変更する場合は開始日時を修正してください。', {
                                position: 'top-right',
                                autoClose: 3000,
                                closeOnClick: true,
                                theme: 'light',
                                pauseOnHover: false,
                                hideProgressBar: true,
                              });
                              return;
                            }
                            setListDay(() => {
                              return [...newValue, `${new Date(triggerStartTime).getDay()}`];
                            });
                          }}
                        />
                      </div>
                    )}
                    {triggerType !== '8' && triggerType !== '1' && (
                      <>
                        <div style={{ width: 470, marginBottom: 20, animation: 'animation_bottomtotop 0.5s' }}>
                          <p className='color__text__black mb--10'>設定パターン</p>
                          <Radio.Group
                            onChange={(e) => {
                              setOptionMonth(+e.target.value);
                            }}
                            value={optionMonth}
                          >
                            <Space direction='horizontal'>
                              <Radio value={1}>日付</Radio>
                              <Radio value={2}>曜日</Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                        {optionMonth === 1 ? (
                          <div style={{ width: 470, animation: 'animation_bottomtotop 0.5s' }}>
                            <Checkbox.Group
                              options={optionDays}
                              value={listDayOfMonth}
                              onChange={(value) => {
                                /////////////////////////////////
                                const LastDayOfMonth = new Date(triggerStartTime.getFullYear(), triggerStartTime.getMonth()  + 1, 0).getDate();
                                const check = new Date(triggerStartTime).getDate() === LastDayOfMonth && value.find(item=>+item === 99) ? true : false
                                const newValue = [...new Set(value)].filter((item) => item !== `${new Date(triggerStartTime).getDate()}`);
                                if ((value.findIndex((item) => item === `${new Date(triggerStartTime).getDate()}`) === -1) && new Date(triggerStartTime).getDate() !== LastDayOfMonth) {
                                  toast.warning('変更する場合は開始日時を修正してください。', {
                                    position: 'top-right',
                                    autoClose: 3000,
                                    closeOnClick: true,
                                    theme: 'light',
                                    pauseOnHover: false,
                                    hideProgressBar: true,
                                  });
                                  return;
                                }
                                const check1 = value.filter(item=> +item === +LastDayOfMonth || +item === 99).length > 0 ? true : false 
                                // if (new Date(triggerStartTime).getDate() === LastDayOfMonth && !check1) {
                                //   toast.warning('変更する場合は開始日時を修正してください。', {
                                //     position: 'top-right',
                                //     autoClose: 3000,
                                //     closeOnClick: true,
                                //     theme: 'light',
                                //     pauseOnHover: false,
                                //     hideProgressBar: true,
                                //   });
                                //   return;
                                // }
                                // console.log(value.find(item=> +item !== 99));
                                
                                if (new Date(triggerStartTime).getDate() === LastDayOfMonth && !value.find(item=> +item === 99) && !value.find(item=>+item === +LastDayOfMonth)) {
                                  if(listDayOfMonth.find(item=>+item === 99)){
                                    setListDayOfMonth(() => {
                                      const newArr = [...new Set(value)].filter(item=>+item != 99 && +item != LastDayOfMonth )
                                      return [...newArr, `${LastDayOfMonth}`];
                                    });
                                    return
                                  }
                                  if(listDayOfMonth.find(item=>+item === +LastDayOfMonth)){
                                    setListDayOfMonth(() => {
                                      const newArr = value.filter(item=>+item != 99 && +item != LastDayOfMonth )
                                      return [...newArr, `99`];
                                    });
                                    return
                                  }
                                }
                                if( value.find(item=> +item === 99) && value.find(item=>+item === +LastDayOfMonth)){
                                  if(listDayOfMonth.find(item=>+item === 99)){
                                    setListDayOfMonth(() => {
                                      const newArr = [...new Set(value)].filter(item=>+item != 99 && +item != LastDayOfMonth )
                                      console.log([...newArr, LastDayOfMonth]);
                                      
                                      return [...newArr, `${LastDayOfMonth}`];
                                    });
                                    return
                                  }
                                  if(listDayOfMonth.find(item=>+item === +LastDayOfMonth)){
                                    setListDayOfMonth(() => {
                                      const newArr = value.filter(item=>+item != 99 && +item != LastDayOfMonth )
                                      return [...newArr, `99`];
                                    });
                                    return
                                  }
                                  
                                }

                                setListDayOfMonth(() => {
                                  return [...value];
                                });

                                /////////////////////////////////
                              }}
                            />
                            <div style={{ marginTop: 10, fontSize: 14 }}>
                              <p style={{ marginBottom: 0,fontSize: 14  }}>※月末にプロセスを実行する場合は最終にチェックを入れて下さい。</p>
                              <p style={{ marginBottom: 0,fontSize: 14  }}>　存在しない日付になる場合は実行されません。</p>
                            </div>
                          </div>
                        ) : (
                          <div style={{ animation: 'animation_bottomtotop 0.5s' }}>
                            <div style={{ width: 470, paddingBottom: 10 }}>
                              {optionMonth02.map((item, index) => {
                                return (
                                  <div style={{ padding: '5px 0px' }} key={index}>
                                    <span style={{ margin: 0, fontWeight: 'bold' }}>{item.label}</span>
                                    <div style={{ width: 470 }}>
                                      <Checkbox.Group
                                        options={options}
                                        value={[...item.days]}
                                        onChange={(value) => {
                                          const getLast7DaysOfMonth1 = getLast7DaysOfMonth(triggerStartTime)
                                          console.log(getLast7DaysOfMonth1);
                                          console.log(Object.keys(getLast7DaysOfMonth1).includes(`${getWeekOfMonth(triggerStartTime)}`));
                                          // console.log(!optionMonth02[5].days.find((item) => item === `${new Date(triggerStartTime).getDay()}`));
                                          
                                          
                                          if (value.findIndex((item) => item === `${new Date(triggerStartTime).getDay()}`) === -1 
                                            && getWeekOfMonth(triggerStartTime) === index + 1
                                            && !Object.keys(getLast7DaysOfMonth1).includes(`${getWeekOfMonth(triggerStartTime)}`)  
                                          ) {
                                            toast.warning('変更する場合は開始日時を修正してください。', {
                                              position: 'top-right',
                                              autoClose: 3000,
                                              closeOnClick: true,
                                              theme: 'light',
                                              pauseOnHover: false,
                                              hideProgressBar: true,
                                            });
                                            return;
                                          }
                                          if(
                                            Object.keys(getLast7DaysOfMonth1).includes(`${getWeekOfMonth(triggerStartTime)}`)
                                            && getWeekOfMonth(triggerStartTime) === index + 1
                                            && !value.find((item) => item === `${new Date(triggerStartTime).getDay()}`)
                                            && !optionMonth02[5].days.find((item) => item === `${new Date(triggerStartTime).getDay()}`)
                                            ){
                                              setOptionMonth02((prevValue) => {
                                                return [
                                                  ...prevValue.map((item, index1) => {                                              
                                                    if (index === index1) {
                                                      return { ...item, days: value };
                                                    }
                                                    if(index1 === 5){
                                                      return { ...item, days: [...item.days,`${new Date(triggerStartTime).getDay()}`] };
                                                    }                                         
                                                    return item;
                                                  }),
                                                ];
                                              });
                                              return

                                            }
                                         
                                         
                                          
                                          if(getLast7DaysOfMonth1[index+1]){
                                            const getCommonElements2 = getCommonElements(getLast7DaysOfMonth1[index+1],value)
                                            const newListDays = optionMonth02[5].days.filter(item=>{
                                              return !getCommonElements2.includes(item)
                                            })

                                            setOptionMonth02((prevValue) => {
                                              return [
                                                ...prevValue.map((item, index1) => {                                              
                                                  if (index === index1) {
                                                    return { ...item, days: value };
                                                  }
                                                  if(index1 === 5){
                                                    return { ...item, days: newListDays };
                                                  }                                         
                                                  return item;
                                                }),
                                              ];
                                            });
                                            return
                                          }
                                          if(index === 5){                  
                                            const newListDays3 = optionMonth02[3].days.filter(item=>{
                                              return !getCommonElements(getLast7DaysOfMonth1[4],value).includes(item)
                                            })
                                            const newListDays4 = optionMonth02[4].days.filter(item=>{
                                              return !getCommonElements(getLast7DaysOfMonth1[5],value).includes(item)
                                            })
                                            const numberWeekStart = getWeekOfMonth(triggerStartTime)
                                            const isWeekStart = optionMonth02[numberWeekStart-1].days.find(item=>item === `${new Date(triggerStartTime).getDay()}` )
                                            const isWeekend = value.find(item=>item === `${new Date(triggerStartTime).getDay()}` )
                                            if(!isWeekStart && !isWeekend){
                                              setOptionMonth02((prevValue) => {
                                                return [
                                                  ...prevValue.map((item, index1) => {                                              
                                                    if (index1 === numberWeekStart-1) {
                                                      return { ...item, days:[...item.days,`${new Date(triggerStartTime).getDay()}`]  };
                                                    }
                                                    if(index1 === 5){
                                                      return { ...item, days: value };
                                                    }                                         
                                                    return item;
                                                  }),
                                                ];
                                              });
                                              return
                                            
                                            }  
                                            setOptionMonth02((prevValue) => {
                                              return [
                                                ...prevValue.map((item, index1) => {                                              
                                                  if (index1 === 3) {
                                                    return { ...item, days: newListDays3 };
                                                  }
                                                  if (index1 === 4 ) {
                                                    return { ...item, days: newListDays4 };
                                                  }
                                                  if(index1 === 5){
                                                    return { ...item, days: value };
                                                  }                                         
                                                  return item;
                                                }),
                                              ];
                                            });
                                            return
                                          }
                                          
                                           
                                            // console.log({getLast7DaysOfMonth1,index});
                                            
                                          // if(index === 5 || index === 4){
                                          //   const commonElements = [...new Set(value)].filter(item => new Set(optionMonth02[index === 5 ? 4 : 5].days).has(item));                                            
                                          //   if(commonElements.length > 0){
                                          //     toast.warning('第5 / 最終 はどちらかのみチェックすることができます。', {
                                          //       position: 'top-right',
                                          //       autoClose: 3000,
                                          //       closeOnClick: true,
                                          //       theme: 'light',
                                          //       pauseOnHover: false,
                                          //       hideProgressBar: true,
                                          //     });
                                          //     return;
                                          //   }
                                          // }
                                         
                                          setOptionMonth02((prevValue) => {
                                            return [
                                              ...prevValue.map((item, index1) => {                                              
                                                if (index === index1) {
                                                  return { ...item, days: value };
                                                }                                         
                                                return item;
                                              }),
                                            ];
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              <div style={{ marginTop: 10, fontSize: 14 }}>
                                <p style={{ marginBottom: 0,fontSize: 14  }}>※実行予定の第N回目の曜日にチェックを入れて下さい。</p>
                                <p style={{ marginBottom: 0,fontSize: 14  }}>　存在しない日付になる場合は実行されません。</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
      <div className='common__process__control'>
        <div className='common__flex common__process__control--listbtn'>
          <div className='common__flex common__process__control--btnL'>
            <Button
              className='bg__red common__process__control--btn'
              onClick={() => {
                setOpenModal(true);
              }}
            >
              キャンセル
            </Button>
            <ModalConfirm open={openModal} handleCancelConfirm={handleCancelConfirm} handleSubmit={handleCancelCreateProcess} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />
          </div>

          <div className='common__flex common__process__control--btnR'>
            <>
              <Button disabled={false ? true : false} className={false ? 'common__process__bottom--btn--disable' : 'bg__blue common__process__control--btn'} onClick={handlePrevStep}>
                前に戻る
              </Button>
              <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                次へ進む
              </Button>
            </>
          </div>
        </div>
      </div>
      <Modal
        className=''
        maskClosable={false}
        closable={false}
        title=''
        open={showModal}
        forceRender
        footer={[
          <div key={0} className='modle__user__btn flex__box flex__jus__c'>
            <Button
              className='i3link__btn bg__blue'
              onClick={() => {
                setShowModal(false);
                const action = processSlice.actions.disableNotification({nameStep:'pre_column7'});
                dispatch(action);
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        <p style={{ marginBottom: 0 }}>{processInfo.pre_column7}</p>
      </Modal>
    </div>
  );
}

export default StepCreateProcessScheduler;

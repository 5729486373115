import axiosClient from './axiosClient';
import queryString from 'query-string';

export const processApi = {
  create(data) {
    const url = `/process/new`;
    return axiosClient.post(url, {data});
  },
  getAll(params) {
    const url = '/process';
    return axiosClient.get(url, { params });
  },
  getById(params) {
    const searchParam = queryString.stringify(params)
    const url = `/process/${searchParam}`;
    return axiosClient.get(url, { params });
  },
  getByName(params) {
    const searchParam = queryString.stringify(params)
    const url = `/process/name/${searchParam}`;
    return axiosClient.get(url, { params });
  },
  getAdminById(params) {
    const searchParam = queryString.stringify(params)
    const url = `/process/admin/${searchParam}`;
    return axiosClient.get(url, {params: {...params}});
  },
  update(params,data) {
    const url = `/process`;
    return axiosClient.put(url,{data},{params: {...params}});
  },
  updateAdmin(data,params) {
    const url = `/process/admin`;

    return axiosClient.put(url,{data},{params: {...params}});
  }, 
  delete(params) {
    const url = `/process/delete`;
    return axiosClient.delete(url,{params:{...params}});
  }, 
  execute(params) {
    const url = `process/execute`;
    return axiosClient.put(url, params);
  },
  createScheduler(data) {
    const url = `/scheduler/new`;
    return axiosClient.post(url, data);
  },
  updateScheduler(params,data) {
    const url = `/scheduler`;
    return axiosClient.put(url,{data},{params: {...params}});
  },
};

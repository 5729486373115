import axiosClient from './axiosClient';
import queryString from 'query-string';
const usersApi = {
  create(data) {
    const url = `/user/new`;
    return axiosClient.post(url, data);
  },
  getAllUser(params) {
    const url = '/user';
    return axiosClient.get(url, { params:{...params} });
  },

  getAllAttributeByUser(params) {
    const newParams = queryString.stringify(params)
    const url = `/user/attribute/${newParams}`;
    return axiosClient.get(url, { params:{...params} });
  },
  getUserSearch(params) {
    const url = `/user/search`;
    return axiosClient.get(url, { params:{...params}});
  },
  getUserById(id) {
    const url = `/user/${id}`;
    return axiosClient.get(url);
  },
  getUserByEmail(email) {
    const url = `/userbyemail/${email}`;
    return axiosClient.get(url);
  },

  checkAttributesAllocation(data) {
    const url = `/user/checkAttributesAllocation`;
    return axiosClient.post(url, data);
  },
  checkUserAdmin(params) {
    const url = `/user/checkadmin`;
    return axiosClient.get(url, { params:{...params}});
  },
  checkUserAttributes(params) {
    const url = `/user/checkattribute`;
    return axiosClient.get(url, { params:{...params}});
  },
  checkProcessAllocation(params) {
    const url = `/user/checkprocess`;
    return axiosClient.get(url, { params:{...params}});
  },
  checkTaskAllocation(params) {
    const url = `/user/checktask`;
    return axiosClient.get(url, { params:{...params}});
  },
  checkWorkProcessAllocation(params) {
    const url = `/user/checkworkprocess`;
    return axiosClient.get(url, { params:{...params}});
  },
  checkWorkTaskAllocation(params) {
    const url = `/user/checkworktask`;
    return axiosClient.get(url, { params:{...params}});
  },
  // get(id) {
  //   const url = `/user/${id}`;
  //   return axiosClient.get(url);
  // },
  update(params, data) {
    const url = `/user/${params}`;
    return axiosClient.put(url, { params, data });
  },

  delete(params) {
    const url = `/user/delete`;
    return axiosClient.delete(url,{params:{...params}});
  },
};
const groupsApi = {
  create(data) {
    const url = `/group/new`;
    return axiosClient.post(url, data);
  },
  getGroupById(id, params) {
    const url = `/group/${id}`;
    return axiosClient.get(url, { params:{...params} });
  },
  getAllGroup(params) {
    const url = '/group';
    return axiosClient.get(url, { params:{...params} });
  },
  update(params, data) {
    const url = `/group/${params}`;
    return axiosClient.put(url, { params, data });
  },
  delete(params) {
    const url = `/group/delete`;
    return axiosClient.delete(url,{params:{...params}});
  },
  checkTaskAllocationRole(data) {
    const url = `/group/checkTaskAllocationRole`;
    return axiosClient.post(url, data);
  },
};

export const hrmsApi = { usersApi, groupsApi };

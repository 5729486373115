import React from 'react';
import PropTypes from 'prop-types';

ListStepComponent.propTypes = {};

function ListStepComponent(props) {
  const { listStep, processName } = props;
  return (
    <div className='common__flex process__liststep '>
      {listStep.map((item, index) => {
        return (
          <div key={index} className={item.active ? 'process__liststep--item active' : 'process__liststep--item'}>
            <p> {item.name}</p>
          </div>
        );
      })}
    </div>
  );
}

export default ListStepComponent;

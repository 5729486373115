import { Button, Col, DatePicker, Modal, Row, Select, Spin, TimePicker, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCalendarAlt, FaCaretDown, FaRegBell, FaRegClock, FaRegTimesCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { processApi } from '../../../../../api/processApi';
import { tasksApi } from '../../../../../api/tasksApi';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { calculateScheduledDate, checkDate, checkPrevDate, numberDayToDate } from '../../../../../commons/commonFunctions/CommonFunction';
import { FormatDate, FormatDateCustom, formatDateJP } from '../../../../../commons/commonFunctions/FormatDate';
import { COMMON_MESSAGES } from '../../../../../commons/commonMessages/commonMessages';
import { processState, teamState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';
import '../../../css/style.css';
import useWindowWidth from '../../../../../hooks/useWindowWidth/useWindowWidth';
import queryString from 'query-string';
import RemindTask from './RemindTask';
const { Option } = Select;

StepCreateTaskDuedate.propTypes = {};

const mess = {
  message1: 'プロセス開始予定日時以降を選択してください。',
  message2: '入力された日付は最大値を超えました。',
};

const convertDate_v2 = (timeStartTask, remind) => {
  const newDateRemind = moment(timeStartTask);

  // Lấy các giá trị từ remind hoặc đặt mặc định nếu không có
  const duration = moment.duration({
    years: 0, // Nếu có giá trị từ remind thì thay thế
    months: 0, // Nếu có giá trị từ remind thì thay thế
    days: remind?.notification_start_day_value || 0,
    hours: remind?.notification_start_hour_value || 0,
    minutes: remind?.notification_start_minutes_value || 0,
  });

  // Cộng toàn bộ duration vào thời gian bắt đầu task
  newDateRemind.add(duration);
  // Làm tròn thời gian về đầu phút
  newDateRemind.startOf('minute');
  // Trả về giá trị sau khi đã cộng
  return newDateRemind;
};

function StepCreateTaskDuedate(props) {
  const { nextStep, prevStep } = props;
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const processInfo = useSelector(processState);
  const teamInfo = useSelector(teamState);
  const [taskInfo, setTaskInfo] = useState({});
  const [spinning, setSpinning] = useState(false);
  const [openSetType, setOpenSetType] = useState(false);
  const [listTask, setListTask] = useState(() => {
    return processInfo.tasks || [];
  });
  const [flagBtn, setFlagBtn] = useState(false);
  const [taskMode, setTaskMode] = useState(false);

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalRemind, setOpenModalRemind] = useState(false);
  const [openConfirmExec, setOpenConfirmExec] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(() => {
    let check = false;
    return check;
  });

  const [selectTime, setSelectTime] = useState(true);

  const [optionDayLoop, setOptionDayLoop] = useState(1);

  const [dateValue, setDateValue] = useState(() => {
    if (processInfo.trigger_start_time) {
      return processInfo.trigger_start_time;
    }
    return null;
  });

  const [timeValue, setTimeValue] = useState(() => {
    if (processInfo.trigger_start_time) {
      return processInfo.trigger_start_time;
    }
    return null;
  });

  const handleShowModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCancelModalComfirm = () => {
    const searchParam = queryString.parse(location.search);
    const action = processSlice.actions.newProcess({});
    dispatch(action);
    if (searchParam.process_id) {
      navigate({
        pathname: `/process`,
      });
    } else {
      navigate({
        pathname: `/template`,
      });
    }
    return;
    // localStorage.removeItem('process');
    // const action = processSlice.actions.newProcess({});
    // dispatch(action);
    // setOpenModalConfirm(true);
    // navigate('/process');
    // return;
  };
  const handleCloseModalComfirm = () => {
    setOpenModalConfirm(false);
  };
  const convertDate = (value, task) => {
    if (value === 'start') {
      const dateSchedule = moment(processInfo.trigger_start_time);
      const numberYear = task.start_limit_year_diff_value;
      const numberMonth = task.start_limit_month_diff_value;
      const numberDay = task.start_limit_day_diff_value;
      const numberHours = task.start_limit_hour_diff_value;
      const numberMinutes = task.start_limit_minutes_diff_value;
      dateSchedule.add(moment.duration(numberYear, 'years'));
      dateSchedule.add(moment.duration(numberMonth, 'months'));
      dateSchedule.add(moment.duration(numberDay, 'days'));
      dateSchedule.add(moment.duration(numberHours, 'hours'));
      dateSchedule.add(moment.duration(numberMinutes, 'minutes'));
      return new Date(dateSchedule);
    }

    if (value === 'end') {
      const dateSchedule = moment(new Date(processInfo.trigger_start_time));
      const numberYear = +task.end_limit_year_diff_value;
      const numberMonth = +task.end_limit_month_diff_value;
      const numberDay = +task.end_limit_day_diff_value;
      const numberHours = +task.end_limit_hour_diff_value;
      const numberMinutes = +task.end_limit_minutes_diff_value;

      dateSchedule.add(moment.duration(numberYear, 'years'));
      dateSchedule.add(moment.duration(numberMonth, 'months'));
      dateSchedule.add(moment.duration(numberDay, 'days'));
      dateSchedule.add(moment.duration(numberHours, 'hours'));
      dateSchedule.add(moment.duration(numberMinutes, 'minutes'));

      return new Date(dateSchedule);
    }
  };

  const resetStart = () => {
    return {
      start_limit_reference_type: 2,
      start_limit_reference_task: 0,

      start_limit_year_diff_value: 0,
      start_limit_month_diff_value: 0,
      start_limit_day_diff_value: 0,
      start_limit_hour_diff_value: 0,
      start_limit_minutes_diff_value: 0,

      start_limit_year_fixed_value: null,
      start_limit_month_fixed_value: null,
      start_limit_day_fixed_value: null,
      start_limit_hour_fixed_value: null,
      start_limit_minutes_fixed_value: null,
    };
  };
  const resetEnd = () => {
    return {
      end_limit_reference_type: 0,
      end_limit_reference_task: 0,
      end_limit_decision_type: 0,

      end_limit_year_diff_value: 0,
      end_limit_month_diff_value: 0,
      end_limit_day_diff_value: 0,
      end_limit_hour_diff_value: 0,
      end_limit_minutes_diff_value: 0,

      end_limit_year_fixed_value: null,
      end_limit_month_fixed_value: null,
      end_limit_day_fixed_value: null,
      end_limit_hour_fixed_value: null,
      end_limit_minutes_fixed_value: null,
    };
  };

  const handleOk = () => {
    let newTimeStart = {
      start_limit_reference_type: 1,
      start_limit_reference_task: 0,

      start_limit_year_diff_value: 0,
      start_limit_month_diff_value: 0,
      start_limit_day_diff_value: 0,
      start_limit_hour_diff_value: 0,
      start_limit_minutes_diff_value: 0,

      start_limit_year_fixed_value: null,
      start_limit_month_fixed_value: null,
      start_limit_day_fixed_value: null,
      start_limit_hour_fixed_value: null,
      start_limit_minutes_fixed_value: null,
    };
    let newTimeEnd = {
      end_limit_reference_type: 1,
      end_limit_reference_task: 0,
      end_limit_decision_type: 0,
      end_limit_year_diff_value: 0,
      end_limit_month_diff_value: 0,
      end_limit_day_diff_value: 0,
      end_limit_hour_diff_value: 0,
      end_limit_minutes_diff_value: 0,

      end_limit_year_fixed_value: null,
      end_limit_month_fixed_value: null,
      end_limit_day_fixed_value: null,
      end_limit_hour_fixed_value: null,
      end_limit_minutes_fixed_value: null,
    };

    //////////////////////start///////////////(selecttime đại diện cho start hoặc end)
    if (selectTime) {
      if (optionDayLoop === 1) {
        // const dateDiff = moment.duration(moment(dateValue).diff(processInfo.trigger_start_time));
        // newTimeStart.start_limit_year_diff_value = dateDiff._data.years;
        // newTimeStart.start_limit_month_diff_value = dateDiff._data.months;
        // newTimeStart.start_limit_day_diff_value = dateDiff._data.days;
        // newTimeStart.start_limit_hour_diff_value = dateDiff._data.hours;
        // newTimeStart.start_limit_minutes_diff_value = dateDiff._data.minutes;

        newTimeStart.start_limit_day_fixed_value = new Date(dateValue).getDate();
        const newItem = { ...taskInfo, ...newTimeStart }
        if(newItem.remindStart){
          if(newItem.remindStart?.notification_loop_interval === null){
            delete newItem.remindStart
          }
        }
        const action = processSlice.actions.editTask(newItem);
        dispatch(action);
      }
      if (optionDayLoop === 2) {
        const dateDiff = moment.duration(moment(dateValue).diff(processInfo.trigger_start_time));
        newTimeStart.start_limit_hour_diff_value = dateDiff._data.hours;
        newTimeStart.start_limit_minutes_diff_value = dateDiff._data.minutes;
        newTimeStart.start_limit_day_fixed_value = null;
        newTimeStart.start_limit_day_diff_value = numberDayToDate(processInfo.trigger_start_time, dateValue);
        ///////////////////kiêm tra ngày đã chọn có ảnh hưởng để như ngày của task trước hay không////////
        const tasks = processInfo.tasks;
        let flag = false;
        let flag1 = false;
        const indexTask = tasks.findIndex((item) => item.id === taskInfo.id);
        //////////////kiem tra ngay băt đầu có nhỏ hơn ngày ket thuc không////
        const taskCurrent = tasks[indexTask];
        const startTime = +newTimeStart.start_limit_reference_type === 1 ? +newTimeStart.start_limit_day_diff_value * 24 * 60 + +newTimeStart.start_limit_hour_diff_value * 60 + +newTimeStart.start_limit_minutes_diff_value : 0;
        const endTime = +taskCurrent.end_limit_reference_type === 1 ? +taskCurrent.end_limit_day_diff_value * 24 * 60 + +taskCurrent.end_limit_hour_diff_value * 60 + +taskCurrent.end_limit_minutes_diff_value : 0;
        if (startTime > endTime && endTime) {
          // toast.warning(`thơi gian khong hop le ngày bắt đầu không được lơn hơn ngày kết thúc!`, {
          if (!toast.isActive({ toastId: 'toast_warning' })) {
            toast.warning(`時系列に矛盾があります。設定をご確認ください。`, {
              toastId: 'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
          return;
        }
        //////////////kiem tra ngay băt đầu có nhỏ hơn ngày ket thuc không////

        for (let index = 0; index < indexTask; index++) {
          const element = tasks[index];
          let totalTime = 0;
          if (+element.start_limit_reference_type === 1 || +element.end_limit_reference_type === 1) {
            if ((element.start_limit_day_diff_value || element.start_limit_hour_diff_value || element.start_limit_minutes_diff_value) && !element.end_limit_day_diff_value && !element.end_limit_hour_diff_value && !element.end_limit_minutes_diff_value) {
              totalTime = element.start_limit_day_diff_value * 24 * 60 + element.start_limit_hour_diff_value * 60 + element.start_limit_minutes_diff_value;
            }
            if (element.end_limit_day_diff_value || element.end_limit_hour_diff_value || element.end_limit_minutes_diff_value) {
              totalTime = +element.end_limit_day_diff_value * 24 * 60 + +element.end_limit_hour_diff_value * 60 + +element.end_limit_minutes_diff_value;
            }
            const totalTimeStart = newTimeStart.start_limit_day_diff_value * 24 * 60 + newTimeStart.start_limit_hour_diff_value * 60 + newTimeStart.start_limit_minutes_diff_value;

            if (+totalTime > +totalTimeStart) {
              flag = true;
              break;
            }
          }
        }

        if (flag) {
          // プロセス開始予定日時以降を選択してください。
          // toast.warning(`thơi gian anh huong den task trước đó`, {
          if (!toast.isActive({ toastId: 'toast_warning' })) {
            toast.warning(`時系列に矛盾があります。設定をご確認ください。`, {
              toastId: 'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
          return;
        }
        //////////////////////////////////////////////////////////
        for (let index = indexTask + 1; index < tasks.length; index++) {
          const element = tasks[index];
          let totalTime = 0;
          if (+element.start_limit_reference_type === 1 || +element.end_limit_reference_type === 1) {
            if ((element.start_limit_day_diff_value || element.start_limit_hour_diff_value || element.start_limit_minutes_diff_value) && !element.end_limit_day_diff_value && !element.end_limit_hour_diff_value && !element.end_limit_minutes_diff_value) {
              totalTime = element.start_limit_day_diff_value * 24 * 60 + element.start_limit_hour_diff_value * 60 + element.start_limit_minutes_diff_value;
            }
            if (element.end_limit_day_diff_value || element.end_limit_hour_diff_value || element.end_limit_minutes_diff_value) {
              totalTime = +element.end_limit_day_diff_value * 24 * 60 + +element.end_limit_hour_diff_value * 60 + +element.end_limit_minutes_diff_value;
            }
            const totalTimeStart = newTimeStart.start_limit_day_diff_value * 24 * 60 + newTimeStart.start_limit_hour_diff_value * 60 + newTimeStart.start_limit_minutes_diff_value;
            if (+totalTime < +totalTimeStart) {
              flag1 = true;
              break;
            }
          }
        }

        if (flag1) {
          // toast.warning(`thơi gian anh huong den task tiếp theo!`, {
          if (!toast.isActive({ toastId: 'toast_warning' })) {
            toast.warning(`時系列に矛盾があります。設定をご確認ください。`, {
              toastId: 'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
          return;
        }

        ///////////////////kiêm tra ngày đã chọn có ảnh hưởng để như ngày của task trước hay không////////
        const newItem = { ...taskInfo, ...newTimeStart }
        if(newItem.remindStart){
          if(newItem.remindStart?.notification_loop_interval === null){
            delete newItem.remindStart
          }
        }
        const action = processSlice.actions.editTask({ ...newItem});
        dispatch(action);
      }
    }

    //////////////////////end///////////////(selecttime đại diện cho start hoặc end)
    if (!selectTime) {
      if (optionDayLoop === 1) {
        newTimeEnd.end_limit_day_fixed_value = new Date(dateValue).getDate();
        const newItem = { ...taskInfo, ...newTimeEnd }
        if(newItem.remindEnd){
          if(newItem.remindEnd?.notification_loop_interval === null){
            delete newItem.remindEnd
          }
        }
        const action = processSlice.actions.editTask({ ...newItem});
        dispatch(action);
      }
      if (optionDayLoop === 2) {
        const dateDiff = moment.duration(moment(dateValue).diff(processInfo.trigger_start_time));
        newTimeEnd.end_limit_hour_diff_value = dateDiff._data.hours;
        newTimeEnd.end_limit_minutes_diff_value = dateDiff._data.minutes;
        newTimeEnd.end_limit_day_fixed_value = null;
        newTimeEnd.end_limit_day_diff_value = numberDayToDate(processInfo.trigger_start_time, dateValue);

        const tasks = processInfo.tasks;
        let flag = false;
        let flag1 = false;
        const indexTask = tasks.findIndex((item) => item.id === taskInfo.id);
        //////////////kiem tra ngay băt đầu có lớn hơn ngày ket thuc không////
        const taskCurrent = tasks[indexTask];
        const startTime = +taskCurrent.start_limit_reference_type === 1 ? +taskCurrent.start_limit_day_diff_value * 24 * 60 + +taskCurrent.start_limit_hour_diff_value * 60 + +taskCurrent.start_limit_minutes_diff_value : 0;
        const endTime = +newTimeEnd.end_limit_reference_type === 1 ? +newTimeEnd.end_limit_day_diff_value * 24 * 60 + +newTimeEnd.end_limit_hour_diff_value * 60 + +newTimeEnd.end_limit_minutes_diff_value : 0;
        if (startTime > endTime && startTime && !toast.isActive({ toastId: 'toast_warning' })) {
          // toast.warning(`thơi gian khong hop le thời gian ket thuc khong được nhỏ hơn ngay bắt đầu`, {
          toast.warning(`時系列に矛盾があります。設定をご確認ください。`, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          return;
        }

        ///////////////////kiêm tra ngày đã chọn có ảnh hưởng để như ngày của task trước hay không////////
        for (let index = 0; index < indexTask; index++) {
          const element = tasks[index];
          let totalTime = 0;
          if (+element.start_limit_reference_type === 1 || +element.end_limit_reference_type === 1) {
            if ((element.start_limit_day_diff_value || element.start_limit_hour_diff_value || element.start_limit_minutes_diff_value) && !element.end_limit_day_diff_value && !element.end_limit_hour_diff_value && !element.end_limit_minutes_diff_value) {
              totalTime = element.start_limit_day_diff_value * 24 * 60 + element.start_limit_hour_diff_value * 60 + element.start_limit_minutes_diff_value;
            }
            if (element.end_limit_day_diff_value || element.end_limit_hour_diff_value || element.end_limit_minutes_diff_value) {
              totalTime = +element.end_limit_day_diff_value * 24 * 60 + +element.end_limit_hour_diff_value * 60 + +element.end_limit_minutes_diff_value;
            }
            const totalTimeEnd = newTimeEnd.end_limit_day_diff_value * 24 * 60 + newTimeEnd.end_limit_hour_diff_value * 60 + newTimeEnd.end_limit_minutes_diff_value;
            if (+totalTime > +totalTimeEnd) {
              flag = true;
              break;
            }
          }
        }

        if (flag && !toast.isActive({ toastId: 'toast_warning' })) {
          // toast.warning(`thơi gian anh huong den task trước đó`, {
          toast.warning(`時系列に矛盾があります。設定をご確認ください。`, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          return;
        }
        //////////////////////////////////////////////////////////
        for (let index = indexTask + 1; index < tasks.length; index++) {
          const element = tasks[index];
          let totalTime = 0;
          if (+element.start_limit_reference_type === 1 || +element.end_limit_reference_type === 1) {
            if ((element.end_limit_day_diff_value || element.end_limit_hour_diff_value || element.end_limit_minutes_diff_value) && +element.end_limit_reference_type === 1) {
              totalTime = +element.end_limit_day_diff_value * 24 * 60 + +element.end_limit_hour_diff_value * 60 + +element.end_limit_minutes_diff_value;
            }
            if ((element.start_limit_day_diff_value || element.start_limit_hour_diff_value || element.start_limit_minutes_diff_value) && +element.start_limit_reference_type === 1) {
              totalTime = +element.start_limit_day_diff_value * 24 * 60 + +element.start_limit_hour_diff_value * 60 + +element.start_limit_minutes_diff_value;
            }
            const totalTimeEnd = newTimeEnd.end_limit_day_diff_value * 24 * 60 + newTimeEnd.end_limit_hour_diff_value * 60 + newTimeEnd.end_limit_minutes_diff_value;
            if (+totalTime < +totalTimeEnd) {
              flag1 = true;
              break;
            }
          }
        }

        if (flag1 && !toast.isActive({ toastId: 'toast_warning' })) {
          // toast.warning(`thơi gian anh huong den task tiếp theo!`, {
          toast.warning(`時系列に矛盾があります。設定をご確認ください。`, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          return;
        }

        ///////////////////kiêm tra ngày đã chọn có ảnh hưởng để như ngày của task trước hay không////////
        const newItem = { ...taskInfo, ...newTimeEnd }
        if(newItem.remindEnd){
          if(newItem.remindEnd?.notification_loop_interval === null){
            delete newItem.remindEnd
          }
        }
        const action = processSlice.actions.editTask({ ...newItem});
        dispatch(action);
      }
    }

    setOpenModal(false);
  };

  ///////////// không thiết đặt thời gian cho task////////////////
  const handleNoSettingTimeOfTask = (value, task) => {
    if (!value || !task) {
      return;
    }
    let newTask = { ...task };
    if (value === 'start') {
      const newValue = resetStart();
      newValue.start_limit_reference_task = +task.task_id;
      delete newTask.remindStart;
      newTask = { ...newTask, ...newValue };
    }
    if (value === 'end') {
      const newValue = resetEnd();
      delete newTask.remindEnd;
      newTask = { ...newTask, ...newValue };
    }
    const action = processSlice.actions.editTask({ ...newTask });
    dispatch(action);
  };

  /////////////thiết đặt thời gian cho task////////////////Pre-configuration
  const handlePreConfigurationTimeOfTask = (value, task) => {
    if (!value || !task) {
      return;
    }
    if (value === 'start') {
      // setOptionDayLoop(() => {
      //   return +task.start_limit_reference_type !== 1 ? 1 : +task.start_limit_day_fixed_value !== 0 ? 1 : 2;
      // });
      setOptionDayLoop(2);
      setSelectTime(true);
    }
    if (value === 'end') {
      // setOptionDayLoop(() => {
      //   return +task.end_limit_reference_type !== 1 ? 1 : +task.end_limit_day_fixed_value !== 0 ? 1 : 2;
      // });
      setOptionDayLoop(2);
      setSelectTime(false);
    }

    setDateValue(convertDate(value, task));
    setTimeValue(convertDate(value, task));
    handleShowModal();
  };

  /////////////thiết đặt thời gian cho task lúc process khởi chạy////////////////
  const handleSettingsAtStartTimeOfTask = (value, task) => {
    if (!value || !task) {
      return;
    }
    const defaultValueRemind = {
      dateStart:null,
      notification_loop_value: null,
      notification_loop_num: '1',
      notification_loop_interval: null,
      notification_start_type: value === 'start' ? 5 : 6,
      optionRemind: 1,
      notification_start_hour_value: 0,
      notification_start_minutes_value: 0,
      notification_start_day_value: 0,
    };
    let newTask = { ...task };
    if (value === 'start') {
      const newValue = resetStart();
      newValue.start_limit_reference_type = 0;
      newValue.remindStart = {...defaultValueRemind}
      newTask = { ...newTask, ...newValue };
    }
    if (value === 'end') {
      const newValue = resetEnd();
      newValue.end_limit_reference_type = 0;
      newValue.end_limit_decision_type = 1;
      newValue.remindEnd = {...defaultValueRemind}
      newTask = { ...newTask, ...newValue };
    }
    const action = processSlice.actions.editTask({ ...newTask });
    dispatch(action);
  };

  /////handle change date
  const handleChangeDate = (value) => {
    ///check ngày quá khứ
    const isPrevDate = checkPrevDate(value, new Date(processInfo.trigger_start_time) - new Date() > 0 ? new Date(processInfo.trigger_start_time) : new Date());

    if (isPrevDate && !toast.isActive({ toastId: 'schedule_check_day' })) {
      toast.warning(mess.message1, {
        toastId: 'schedule_check_day',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });
      setDateValue(new Date(processInfo.trigger_start_time) - new Date() > 0 ? new Date(processInfo.trigger_start_time) : new Date());
      setTimeValue(new Date(processInfo.trigger_start_time) - new Date() > 0 ? new Date(processInfo.trigger_start_time) : new Date());
      return;
    }
    ///check ngày chọn có vượt quá 10 năm so với ngày hiện tại
    const dateDiff = checkDate(new Date(), new Date(value));
    if (dateDiff > 10) {
      toast.warning(mess.message2, {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });
      setDateValue(new Date(processInfo.trigger_start_time));
      setTimeValue(new Date(processInfo.trigger_start_time));
      return;
    }
    let newValue = new Date(value);
    const newTimeStart = new Date(timeValue);
    const h = newTimeStart.getHours();
    const m = newTimeStart.getMinutes();
    newValue.setHours(h);
    newValue.setMinutes(m);
    setDateValue(new Date(newValue));
    setTimeValue(new Date(newValue));
  };

  /////handle change Time
  const handleChangeTime = (value) => {
    const date = new Date(dateValue).getDate();
    const month = new Date(dateValue).getMonth();
    const year = new Date(dateValue).getFullYear();
    const hours = new Date(value).getHours();
    const minutes = new Date(value).getMinutes();
    const newDate = new Date(year, month, date, hours, minutes);
    const isStartDay = checkPrevDate(new Date(processInfo.trigger_start_time)) ? new Date() : new Date(processInfo.trigger_start_time);
    const isPrevDate = checkPrevDate(newDate, isStartDay);
    // return
    if (isPrevDate) {
      if (!toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(mess.message1, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
      setDateValue(() => {
        const test = new Date(processInfo.trigger_start_time) - new Date() > 0 ? new Date(year, month, date, new Date(processInfo.trigger_start_time).getHours(), new Date(processInfo.trigger_start_time).getMinutes()) : new Date();
        return test;
      });
      setTimeValue(() => {
        const test = new Date(processInfo.trigger_start_time) - new Date() > 0 ? new Date(year, month, date, new Date(processInfo.trigger_start_time).getHours(), new Date(processInfo.trigger_start_time).getMinutes()) : new Date();
        return test;
      });
      return;
    }
    setDateValue(newDate);
    setTimeValue(newDate);
  };

  ////////// sử lí trước khi quay về bước trước đó theo
  const handlePrevStep = () => {
    if (step > 0) {
      setStep(step - 1);
      return;
    }
    if (prevStep && step === 0) {
      prevStep();
      return;
    }
  };

  ////////// sử lí trước khi chuyển sang bước tiếp theo
  const handleNextStep = async () => {
    try {
      setSpinning(true);
      if (step === 0) {
        const teamId = teamInfo.listTeam[0]?.team_id;
        if (!teamId && !toast.isActive({ toastId: 'check_team' })) {
          toast.warning(`まだ何もチームに付属していません！`, {
            toastId: 'check_team',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          return;
        }
        const userCreate = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
        if (userCreate === null || !userCreate.user_id) {
          navigate('/login');
        }

        const newProcessInfo = {
          ...processInfo,
          userCreate_id: userCreate.user_id,
          team_id: teamId,
          checkUserStart: false,
          edit_date: new Date().toUTCString(),
          utc_local: new Date().getUTCHours() + 1,
          new_date: FormatDateCustom(new Date()),
        };
        const pathname = location.pathname;

        let isSuccess = false;
        let msgSuccess = '';
        if (pathname.includes('new') && !pathname.includes('update')) {
          const result = await processApi.create(newProcessInfo);

          const action = processSlice.actions.updateProcess({ process_id: result.data.process_id });
          dispatch(action);

          if (result?.success) {
            const resultProcess = await processApi.getById({ team_id: teamId, process_id: result.data.process_id });
            if (resultProcess?.data) {
              const processInfo = resultProcess.data;
              for (let index = 0; index < newProcessInfo.tasks.length; index++) {
                const element = newProcessInfo.tasks[index];
                if (element.remindEnd) {
                  processInfo.tasks[index].remindEnd = element.remindEnd;
                }
                if (element.remindStart) {
                  processInfo.tasks[index].remindStart = element.remindStart;
                }
              }
              newProcessInfo.process_id = processInfo.process_id;
              const result1 = await tasksApi.updateTask({ ...processInfo });
              const result2 = await processApi.createScheduler(newProcessInfo);
            }
            isSuccess = true;
            msgSuccess = result.success;
          }
          if (result?.error && !toast.isActive({ toastId: 'err_duedate' })) {
            toast.warning(result.error, {
              toastId: 'err_duedate',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
        } else if (!pathname.includes('new') && pathname.includes('update')) {
          const paramUrl = { process_id: +newProcessInfo.process_id, team_id: processInfo.team_id };
          let checkUserStart = false;
          for (let index = 0; index < processInfo.tasks.length; index++) {
            const task = processInfo.tasks[index];
            for (let j = 0; j < task.listUser.length; j++) {
              const user = task.listUser[j];
              if (user.type === '起案者') {
                checkUserStart = true;
                newProcessInfo.checkUserStart = true;
              }
            }
          }

          if (checkUserStart) {
            const newProcessInfo1 = { ...processInfo };
            delete newProcessInfo1.tasks;
            newProcessInfo1.trigger_start_time = null;
            const result1 = await processApi.createScheduler(newProcessInfo1);
            const action = processSlice.actions.addTimeProcess({ triggerStartTime: null, triggerInterval: null, triggerType: null });
            dispatch(action);
          }
          const result = await processApi.update(paramUrl, newProcessInfo);
          if (result.data) {
            const action = processSlice.actions.updateProcess({ process_id: result.data.process_id });
            dispatch(action);
          }
          if (result?.success) {
            if (result.data?.process_id) {
              const process_id = result.data.process_id;
              const resultSchedule = await processApi.createScheduler({ ...newProcessInfo, process_id });
              let tasks = [];
              const listTask = [...result.data?.listTask] || [];
              for (let index = 0; index < listTask.length; index++) {
                const element = listTask[index];
                if (processInfo.tasks[index].task_name === element.task_name) {
                  const taskNew = { ...processInfo.tasks[index] };
                  const task_id = element.task_id;
                  taskNew.task_id = task_id;
                  tasks.push(taskNew);
                }
              }
              const result2 = await tasksApi.updateTask({ ...newProcessInfo, process_id, tasks });
            } else {
              const result = await processApi.getById({ team_id: teamId, process_id: processInfo.process_id });
              if (result?.data) {
                const processInfo = result.data;
                for (let index = 0; index < newProcessInfo.tasks.length; index++) {
                  const element = newProcessInfo.tasks[index];
                  if (element.remindEnd) {
                    processInfo.tasks[index].remindEnd = element.remindEnd;
                  }
                  if (element.remindStart) {
                    processInfo.tasks[index].remindStart = element.remindStart;
                  }
                }
                const result2 = await tasksApi.updateTask({ ...processInfo });
              }

              const resultSchedule = await processApi.createScheduler(newProcessInfo);
            }
            isSuccess = true;
            msgSuccess = result.success;
          }
          if (result?.error && !toast.isActive({ toastId: 'err_duedate' })) {
            toast.warning(result.error, {
              toastId: 'err_duedate',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
        }

        if (isSuccess) {
          toast.success(msgSuccess, {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          if (!processInfo.trigger_start_time) {
            setOpenConfirmExec(true);
          } else {
            navigate(`/process`);
          }
        }
      }
      setSpinning(false);
    } catch (error) {
      setSpinning(false);
      console.log(error);
      toast.warning(error.message, {
        toastId: 'err_duedate',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        theme: 'light',
        pauseOnHover: false,
        hideProgressBar: true,
      });
    }
  };

  /////////check điều kiện của nút next
  const checkDisableBtn = () => {
    return spinning;
  };

  const handleRemoveOptionTask = (item) => {
    const resetStartTask = resetStart();
    const resetEndTask = resetEnd();
    const newtask = { ...item, ...resetStartTask, ...resetEndTask };
    newtask.start_limit_reference_type = +item.start_limit_reference_type === 1 ? 2 : +item.start_limit_reference_type;
    newtask.end_limit_reference_type = +item.end_limit_reference_type === 1 ? 0 : +item.end_limit_reference_type;
    newtask.end_limit_decision_type = +item.end_limit_reference_type === 0 && +item.end_limit_decision_type === 1 ? 1 : 0;
    const action = processSlice.actions.editTask({ ...newtask });
    dispatch(action);
    return;
  };

  const handleExecute = async () => {
    try {
      setSpinning(true);
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (user === null || user.user_id === null) {
        navigate('/login');
        return;
      }
      if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        return;
      }
      const filter = {
        team_id: teamInfo.listTeam[0]?.team_id,
        process_id: processInfo.process_id,
      };
      const result = await processApi.getById(filter);

      if (result.data && !toast.isActive({ toastId: 'toast_warning' })) {
        const params = {
          mode: 1,
          process_id: result.data.process_id,
          process_name: result.data.process_name,
          trigger_id: '',
          trigger_user_id: user.user_id,
          start_user_id: user.user_id,
          edit_date: new Date(),
        };
        const execute = await processApi.execute(params);
        if (execute?.success && !toast.isActive({ toastId: 'toast_success' })) {
          toast.success(execute.success, {
            toastId: 'toast_success',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
        if (execute?.error && !toast.isActive({ toastId: 'toast_warning' })) {
          toast.warning(execute.error, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
      } else {
        toast.warning(COMMON_MESSAGES.PROCESS.EXECNODATA, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        navigate('/process');
        return;
      }
    } catch (error) {
    } finally {
      // setProcessInfo({})
      setSpinning(false);
      setOpenConfirmExec(false);
      navigate({
        pathname: `/process`,
      });
    }
  };
  const handleSetRemindTask = (value) => {
    if (value && [5, 6].includes(value.notification_start_type)) {
      const key = value.notification_start_type === 5 ? 'remindStart' : 'remindEnd';
      if (value.dateStart) {
        const remind = { ...value };

        const action = processSlice.actions.editTask({
          ...taskInfo,
          [key]: remind,
        });
        dispatch(action);
      } else {
        const newTaskInfo = { ...taskInfo };
        delete newTaskInfo[key];
        const action = processSlice.actions.editTask({
          ...newTaskInfo,
        });
        dispatch(action);
      }

      setOpenModalRemind(!openModalRemind);
    }
  };

  const getRemindData = (type, taskInfo) => {
    const remindKey = type === 'start' ? 'remindStart' : 'remindEnd';
    const remindData = taskInfo?.[remindKey];

    // Dữ liệu mặc định
    const defaultRemindData = {
      dateStart: null,
      notification_loop_value: null,
      notification_loop_num: null,
      notification_loop_interval: '5',
      optionRemind: 0,
      type_setting:openSetType,
      notification_start_type: type === 'start' ? 5 : 6,
      
    };

    // Nếu có thông tin remind (remindStart hoặc remindEnd), cập nhật dateStart bằng cách gọi convertDate_v2
    if (remindData?.notification_loop_interval) {
      const intervalMultiplier = (() => {
        switch (+remindData.notification_loop_interval) {
          case 3:
            return 1440; // 3 -> phút trong một ngày
          case 4:
            return 60; // 4 -> phút trong một giờ
          default:
            return 1; // Nếu không phải 3 hoặc 4 thì dùng mặc định
        }
      })();
      const converMinutesToNumberOfInterval = remindData.notification_loop_value / intervalMultiplier;
      return { ...remindData, dateStart: convertDate_v2(convertDate(type, taskInfo), remindData), notification_loop_value: converMinutesToNumberOfInterval < 1 ? '0' : converMinutesToNumberOfInterval,type_setting:openSetType };
    }

    return defaultRemindData;
  };

  useEffect(() => {
    if (!processInfo.trigger_start_time) {
      for (let index = 0; index < listTask.length; index++) {
        const element = listTask[index];
        handleRemoveOptionTask(element);
      }
    }
  }, []);

  useEffect(() => {
    let isMounted = true; // biến cờ
    if (isMounted) {
      setListTask(() => {
        return processInfo.tasks || [];
      });
    }
    if (step === 0) {
      setShowModal(() => {
        let check = false;
        const searchParam = queryString.parse(location.search);
        if (searchParam.template_id && processInfo.pre_column8) {
          check = true;
        }
        return check;
      });
    }
    // Cleanup function
    return () => {
      isMounted = false; // đánh dấu là unmounted
    };
  }, [processInfo]);
  const windowWidth = useWindowWidth();
  return (
    <div className='process__content__bottom--box'>
      <Spin spinning={spinning} size='large'>
        <div className='process__new--content process__new--content--custom' style={{ position: 'relative' }}>
          <div className='process__new--intro common__process__listtaskbox'>
            <div className='process__new--intro common__flex flex__fd__cl '>
              <h2 className='mb--30 color__text__black f2plus mt--30'>遅延通知の条件を設定してください。</h2>
              <div className='common__flex flex__fd__cl' style={{ justifyContent: 'left' }}>
                <div className='common__flex' style={{ justifyContent: 'left', width: '100%' }}>
                  <p className='color__text__black ' style={{ textAlign: 'left' }}>
                    設定なし：
                  </p>
                  <p className='color__text__black ' style={{ textAlign: 'left' }}>
                    遅延通知を行いません。
                  </p>
                </div>
                <div className='common__flex' style={{ justifyContent: 'left', width: '100%', alignItems: 'flex-start' }}>
                  <p className='color__text__black ' style={{ textAlign: 'left' }}>
                    事前設定：
                  </p>
                  <div>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      遅延通知を行う条件を「今」設定します。
                    </p>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      例)プロセス開始から～日後に開始/終了されていなければ遅延通知を送信します。
                    </p>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      ※事前設定は自動実行を使用しない場合は、設定できません。
                    </p>
                  </div>
                </div>
                <div className='common__flex' style={{ justifyContent: 'left', width: '100%', alignItems: 'flex-start' }}>
                  <p className='color__text__black ' style={{ textAlign: 'left' }}>
                    開始時設定：
                  </p>
                  <div className='mb--30'>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      遅延通知を行う条件を「プロセス開始された後」に管理者が設定します。
                    </p>
                    <p className='color__text__black ' style={{ textAlign: 'left', transform: 'translateX(-22px)' }}>
                      例)1.プロセス開始後に管理者にメールが送信されます。
                    </p>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      2.管理者がメールを確認して、条件を設定します。
                    </p>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      3.設定した日時を過ぎても開始/終了されていなければ遅延通知を送信されます。
                    </p>
                    <p className='color__text__black ' style={{ textAlign: 'left' }}>
                      ※開始時設定を行うまでは担当者へ指示内容の通知は送信されません。必ず、開始時設定を行ってください。
                    </p>
                  </div>
                </div>
              </div>
              <div className='common__process__listtask  process__duedate--box mb--30' style={{ width: '100%' }}>
                <div className='common__flex process__listtask'>
                  <div className='common__flex process__duedate__task--item'>
                    <div className='common__flex'></div>
                    <div className='process__duedate__task--start'>
                      <p className='color__text__black common_text-clamp common_text-clamp--2'>開始予定日時 </p>
                    </div>
                    <div className='process__duedate__task--end'>
                      <p className='color__text__black common_text-clamp common_text-clamp--2'>終了予定日時</p>
                    </div>
                  </div>
                  {listTask.length > 0 &&
                    listTask.map((item, index) => {
                      return (
                        <div className='common__flex process__duedate__task--item' key={index}>
                          <div className='common__flex process__duedate__task--name'>
                            <Tooltip color={'#00a199'} placement='topLeft' title={item.task_name} arrowPointAtCenter>
                              <p className='color__title__black f--nomal common_text-clamp common_text-clamp--2'>
                                タスク {index + 1}: {item.task_name}
                              </p>
                            </Tooltip>
                          </div>
                          <div className='common__flex process__duedate__task--start'>
                            {index === 0 ? (
                              <>
                                <div className='common__flex process__duedate--content--box bg__gray'>
                                  <div className='process__duedate--content common_text-clamp common_text-clamp--2'>
                                    <p className='f--small'>タスク {index + 1}: の開始予定日時はプロセス開始日時になるため、設定はできません</p>
                                  </div>
                                </div>

                                <div className='process__duedate--select'></div>
                              </>
                            ) : (
                              <>
                                <div className='common__flex process__duedate--content--box'>
                                  <div className='process__duedate--content' style={{ flex: '1' }}>
                                    <p className='common_text-clamp f--small common_text-clamp--1'>{+item.start_limit_reference_type === 0 ? `開始時設定 ` : +item.start_limit_reference_type === 1 ? `プロセス開始日時からの差分：${+item.start_limit_day_diff_value}日 ${+item.start_limit_hour_diff_value}時間 ${+item.start_limit_minutes_diff_value}分 後` : '設定なし'}</p>
                                    {+item.start_limit_reference_type === 1 && <p className='f--small common_text-clamp common_text-clamp--1'>{item ? `${formatDateJP(convertDate('start', item))} にタスクが開始 ` : ''}</p>}
                                  </div>
                                  {+item.start_limit_reference_type === 1 && (
                                    <div className='common__flex  f3' style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={`${index}_start`}>
                                      <svg
                                        onClick={() => {
                                          setOpenModalRemind(true);
                                          setTaskInfo({ ...item });
                                          setOpenSetType(true);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        width='25'
                                        height='25'
                                        viewBox='0 0 448 512'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path d='M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z' fill={item?.remindStart?.notification_loop_interval ? 'rgb(3, 255, 180)' : 'yellow'} stroke='black' stroke-width='15' stroke-linejoin='round' />
                                      </svg>
                                    </div>
                                  )}

                                  {item.start_limit_reference_type !== null && item.start_limit_reference_type !== undefined && +item.start_limit_reference_type !== 2 && (
                                    <div className='common__flex process__duedate--remove f3'>
                                      <FaRegTimesCircle
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          handleNoSettingTimeOfTask('start', item);
                                        }}
                                        size={windowWidth < 1025 ? 20 : 30}
                                      />
                                    </div>
                                  )}
                                </div>

                                <div className='common__flex process__duedate--select'>
                                  <Select size='large' suffixIcon={<FaCaretDown size={20} color='#000' />} placeholder={'設定'} style={{ Width: 100 }} value={+item.start_limit_reference_type !== 2 ? +item.start_limit_reference_type : null} onChange={(value) => {}}>
                                    <Option value={2} className='ant-select-item-custom'>
                                      <div
                                        className='process__duedate__item--select'
                                        style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, padding: '5px 12px' }}
                                        onClick={() => {
                                          setTaskInfo({ ...item });
                                          handleNoSettingTimeOfTask('start', item);
                                        }}
                                      >
                                        <span style={{ padding: '' }}>設定なし</span>
                                      </div>
                                    </Option>
                                    {processInfo.trigger_start_time && (
                                      <Option value={1} className='ant-select-item-custom'>
                                        <div
                                          className='process__duedate__item--select'
                                          style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, padding: '5px 12px' }}
                                          onClick={() => {
                                            setTaskInfo({ ...item });
                                            setTaskMode(true);
                                            handlePreConfigurationTimeOfTask('start', item);
                                          }}
                                        >
                                          <span style={{ padding: '' }}>事前設定</span>
                                        </div>
                                      </Option>
                                    )}
                                    <Option value={0} className='ant-select-item-custom'>
                                      <div
                                        className='process__duedate__item--select'
                                        style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, padding: '5px 12px' }}
                                        onClick={() => {
                                          setTaskInfo({ ...item });
                                          handleSettingsAtStartTimeOfTask('start', item);
                                        }}
                                      >
                                        <span style={{ padding: '' }}>開始時設定</span>
                                      </div>
                                    </Option>
                                  </Select>
                                  {/* <ModalConfirm open={openModalNoSchedule} handleCancelConfirm={handleCancelConfirmUseSchedule} handleSubmit={handleNoUseschedule} textConfirm='予定日時設定の事前設定はクリアされます。' /> */}
                                </div>
                              </>
                            )}
                          </div>
                          <div className='common__flex process__duedate__task--end'>
                            <div className='common__flex process__duedate--content--box'>
                              <div className='process__duedate--content' style={{ flex: 1 }}>
                                <p className='common_text-clamp common_text-clamp--1 f--small'>{+item.end_limit_reference_type === 0 && +item.end_limit_decision_type === 1 ? `開始時設定 ` : +item.end_limit_reference_type === 1 ? `プロセス開始日時からの差分： ${+item.end_limit_day_diff_value}日 ${+item.end_limit_hour_diff_value}時間 ${+item.end_limit_minutes_diff_value}分 後` : '設定なし'}</p>
                                {+item.end_limit_reference_type === 1 && <p className='f--small common_text-clamp common_text-clamp--1'>{item ? `${formatDateJP(convertDate('end', item))} にタスクが終了 ` : ''}</p>}
                              </div>

                              {+item.end_limit_reference_type === 1 && (
                                <div className='common__flex f3' style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={`${index}_end`}>
                                  <svg
                                    onClick={() => {
                                      setOpenModalRemind(true);
                                      setTaskInfo({ ...item });
                                      setOpenSetType(false);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    width='25'
                                    height='25'
                                    viewBox='0 0 448 512'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path d='M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z' fill={item?.remindEnd?.notification_loop_interval ? 'rgb(3, 255, 180)' : 'yellow'} stroke='black' stroke-width='15' stroke-linejoin='round' />
                                  </svg>
                                </div>
                              )}

                              {((+item.end_limit_reference_type === 0 && +item.end_limit_decision_type === 1) || +item.end_limit_reference_type === 1) && (
                                <div className='process__duedate--remove f3'>
                                  <FaRegTimesCircle
                                    style={{ cursor: 'pointer' }}
                                    size={windowWidth < 1025 ? 20 : 30}
                                    onClick={() => {
                                      handleNoSettingTimeOfTask('end', item);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className='common__flex process__duedate--select'>
                              <Select
                                size='large'
                                suffixIcon={<FaCaretDown size={20} color='#000' />}
                                placeholder={'設定'}
                                value={+item.end_limit_decision_type === 1 && +item.end_limit_reference_type === 0 ? 0 : +item.end_limit_reference_type !== 0 && +item.end_limit_reference_type !== null ? +item.end_limit_reference_type : null}
                                onChange={(value) => {
                                  // console.log({value});
                                  
                                }}
                              >
                                <Option value={2} className='ant-select-item-custom'>
                                  <div
                                    className='process__duedate__item--select'
                                    style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, padding: '5px 12px' }}
                                    onClick={() => {
                                      setTaskInfo({ ...item });
                                      handleNoSettingTimeOfTask('end', item);
                                    }}
                                  >
                                    <span style={{ padding: '' }}>設定なし</span>
                                  </div>
                                </Option>
                                {processInfo.trigger_start_time && (
                                  <Option value={1} className='ant-select-item-custom'>
                                    <div
                                      className='process__duedate__item--select'
                                      style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, padding: '5px 12px' }}
                                      onClick={() => {
                                        setTaskInfo({ ...item });
                                        setTaskMode(false);
                                        handlePreConfigurationTimeOfTask('end', item);
                                      }}
                                    >
                                      <span style={{ padding: '' }}>事前設定</span>
                                    </div>
                                  </Option>
                                )}
                                <Option value={0} className='ant-select-item-custom'>
                                  <div
                                    className='process__duedate__item--select'
                                    style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, padding: '5px 12px' }}
                                    onClick={() => {
                                      setTaskInfo({ ...item });
                                      handleSettingsAtStartTimeOfTask('end', item);
                                    }}
                                  >
                                    <span style={{ padding: '' }}>開始時設定</span>
                                  </div>
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <div className='common__process__control'>
        <div className='common__flex common__process__control--listbtn'>
          <div className='common__flex common__process__control--btnL'>
            <Button
              disabled={checkDisableBtn()}
              className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__red common__process__control--btn'}
              onClick={() => {
                setOpenModalConfirm(true);
              }}
            >
              キャンセル
            </Button>
            <ModalConfirm open={openModalConfirm} handleCancelConfirm={handleCloseModalComfirm} handleSubmit={handleCancelModalComfirm} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />
          </div>
          <div className='common__flex common__process__control--btnR'>
            <>
              <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__blue common__process__control--btn'} onClick={handlePrevStep}>
                前に戻る
              </Button>
              <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                保存して終了
              </Button>
            </>
          </div>
        </div>
      </div>
      {processInfo.trigger_start_time && (
        <>
          <Modal
            closable={false}
            className='process__modal__duedate'
            width={800}
            footer={
              <div className='common__flex process__modal__duedate--footer'>
                <Button
                  className='i3link__btn common__process__control--btn bg__red'
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  className='i3link__btn common__process__control--btn bg__blue'
                  onClick={() => {
                    handleOk();
                  }}
                >
                  設定
                </Button>
              </div>
            }
            open={openModal}
          >
            <div className='mb--30'>
              <h2 className='color__text__black f2'> {taskMode ? `開始予定日時の事前設定` : '終了予定日時の事前設定'}</h2>
              <h2 className='color__text__black f1plus'>
                自動実行で設定された次回プロセス開始予定の日時は <span className='color__title__black f--nomal'>{processInfo.trigger_start_time && FormatDate(processInfo.trigger_start_time)} </span>です。
              </h2>
              {selectTime ? <h2 className='color__text__black f1plus'>このタスクの開始予定日時を設定してください。</h2> : <h2 className='color__text__black f1plus'>このタスクの終了予定日時を設定してください。</h2>}
              {selectTime ? <h2 className='color__text__black f1plus'>※差分を自動計算して設定します。</h2> : <h2 className='color__text__black f1plus'>※差分を自動計算して設定します。</h2>}
            </div>
            <div className='common__flex process__process__modal--box'>
              <div className='common__flex process__process__modal--time mb--30 '>
                <DatePicker
                  style={{ fontWeight: 600, minWidth: 200, border: '1px solid #00a199' }}
                  allowClear={false}
                  // format={'yyyy 年 MM 月 DD'}
                  format={'yyyy/MM/DD'}
                  value={dateValue !== null ? moment(dateValue) : ''}
                  onChange={(value) => {
                    if (value?._d) {
                      handleChangeDate(value._d);
                      return;
                    }
                  }}
                  placeholder=''
                  suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                  size='large'
                />

                <TimePicker
                  format={'HH:mm'}
                  showNow={false}
                  className='process__schedule--TimePicker'
                  clearIcon={false}
                  suffixIcon={<FaRegClock size={20} color='#000' />}
                  value={moment(timeValue)}
                  onSelect={(value) => {
                    if (value?._d) {
                      handleChangeTime(value?._d);
                    }
                  }}
                  onChange={(value) => {
                    if (value?._d) {
                      handleChangeTime(value?._d);
                    }
                  }}
                />
                <div className='process__process__modal--mode'>
                  <p className='color__text__black'>プロセス開始日時からの差分</p>
                  <p className='color__text__black' style={{ textAlign: 'left' }}>{`${numberDayToDate(processInfo.trigger_start_time, dateValue)} 日`}</p>
                  <p className='color__text__black' style={{ textAlign: 'left' }}>{`${moment.duration(moment(dateValue).diff(processInfo.trigger_start_time))._data.hours} 時間 `}</p>
                  <p className='color__text__black' style={{ textAlign: 'left' }}>{`${moment.duration(moment(dateValue).diff(processInfo.trigger_start_time))._data.minutes} 分`}</p>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      <RemindTask
        value={openSetType ? getRemindData('start', taskInfo) : getRemindData('end', taskInfo)}
        timeStartTask={taskInfo ? (openSetType ? convertDate('start', taskInfo) : convertDate('end', taskInfo)) : ''}
        open={openModalRemind}
        handleCancelRemind={() => {
          setOpenModalRemind(!openModalRemind);
        }}
        handleSetRemindTask={handleSetRemindTask}
      />
      <Modal open={openConfirmExec} centered={true} closable={false} style={{ maxWidth: '550px', width: '50%', maxHeight: '200px' }} header={null} footer={null}>
        <div style={{ maxHeight: '400px' }}>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div style={{ textAlign: '', paddingBottom: '20px' }}>
                プロセスを今すぐ実行しますか？
                <br />
                ※後で実行する場合は、
                <br />
                　プロセス定義から実行してください
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col span={10} style={{ display: 'flex', flex: 'auto', justifyContent: 'flex-start' }}>
              <Button
                className='i3link__btn common__process__control--btn bg__red'
                width={'100%'}
                onClick={() => {
                  setOpenConfirmExec(false);
                  navigate(`/process`);
                }}
              >
                後で実行
              </Button>
            </Col>
            <Col span={10} style={{ display: 'flex', flex: 'auto', justifyContent: 'flex-end' }}>
              <Button
                className='i3link__btn common__process__control--btn bg__blue'
                width={'100%'}
                onClick={() => {
                  setOpenConfirmExec(true);
                  handleExecute();
                }}
              >
                今すぐ実行
              </Button>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>
      <Modal
        className=''
        maskClosable={false}
        closable={false}
        title=''
        open={showModal}
        forceRender
        footer={[
          <div key={0} className='modle__user__btn flex__box flex__jus__c'>
            <Button
              className='i3link__btn bg__blue'
              onClick={() => {
                setShowModal(false);
                const action = processSlice.actions.disableNotification({ nameStep: 'pre_column8' });
                dispatch(action);
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        <p style={{ marginBottom: 0 }}>{processInfo.pre_column8}</p>
      </Modal>
    </div>
  );
}

export default StepCreateTaskDuedate;

///local dev IVS
// export const  API_URL = 'http://localhost:3000' 

/// DEV V1
// export const  API_URL = 'http://glgbpm02.domain01.local/api'

/// DEV V2
// export const  API_URL = 'http://172.18.36.44:3002'

/// STAGING
export const  API_URL = 'https://ta.i3links.jp'

/// HONBAN
// export const  API_URL = 'https://sa.i3links.jp'

export const  team = {team_id:''}
export const  MAX_NUMBER_TASK = 50
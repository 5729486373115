import { Button, Layout,Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { processApi } from '../../../../api/processApi';

import ModalConfirm from '../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import FilterUserAdmin from './FilterUserAdmin';
import { teamState } from '../../../../redux/selector';
import { useSelector } from 'react-redux';
const { Header, Content, Footer } = Layout;
ProcessEditAdmin.propTypes = {};

function ProcessEditAdmin(props) {
  const { listUser } = props;
  const [listData, setListdata] = useState({ listUser: [] });
  const [openModal, setOpenModal] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const teamInfo = useSelector(teamState);

  const checkDisableBtn = () => {
    const check = listData.listUser.findIndex((item) => item.full_name !== '') !== -1 ? false : true;
    if (listData.listUser.length === 0 || (listData.listUser.length > 0 && check) || spinning) {
      return true;
    }
    return false;
  };

  const handleAdd = (list) => {
    let newTaskInfo = { ...listData };
    newTaskInfo.listUser = [...list.listUser];
    setListdata(newTaskInfo);
  };
  const handleRemoveUser = (id) => {
    const newTaskInfo = { ...listData };
    const newListUser = listData.listUser.filter((item) => item.user_id !== id);
    const type = newListUser.length === 0 ? null : listData.type;
    newTaskInfo.listUser = [...newListUser];
    newTaskInfo.type = type;
    setListdata(newTaskInfo);
  };

  const submitChangeAdmin = async () => {
    try {
      setSpinning(true);
      const params = queryString.parse(location.search);
      const newListUser = [...listData.listUser].filter((item) => item.full_name !== '');
      const teamId = teamInfo.listTeam[0]?.team_id;
      if (!teamId) {
        toast.warning(`まだ何もチームに付属していません！`, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }
      const newListData = { ...listData, team_id: teamId };
      newListData.listUser = newListUser;
      const data = { ...newListData, ...params };
      const result = await processApi.updateAdmin(data, params);

      if (result?.success) {
        toast.success(result.success, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        navigate('/process');
      }
      if (result?.error === 'プロセスが削除されてるため、編集できません。' && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(result.error, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        setSpinning(false);
        navigate('/process');
        return;
      }
      if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(result.error, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
      setSpinning(false);
    } catch (error) {
      setSpinning(false);
      console.log(error);
    }
  };
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };
  const handleCancel = () => {
    setOpenModal(false);
    navigate('/process');
  };

  useEffect(() => {
    // const searchParam = queryString.parse(location.search);
    // if (Object.keys(searchParam).length > 0 && searchParam.process_id) {
    //   (async () => {
    //     setSpinning(true);
    //     const teamId = teamInfo.listTeam[0]?.team_id;

    //     const result = await processApi.getById({ team_id: teamId, process_id: searchParam.process_id });
    //     setSpinning(false);
    //     if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
    //       toast.warning(result.error, {
    //         toastId: 'toast_warning',
    //         position: 'top-right',
    //         autoClose: 3000,
    //         closeOnClick: false,
    //         theme: 'light',
    //         pauseOnHover: true,
    //         hideProgressBar: true,
    //       });
    //       navigate({
    //         pathname: `/process`,
    //       });
    //       return;
    //     }else{
    //       setListdata({ listUser: listUser, type: 'ユーザ' });
    //     }
    //   })();
    // } 
    setListdata({ listUser: listUser, type: 'ユーザ' })
  }, [listUser, teamInfo]);
  return (
    <>
      <Content>
        <Spin spinning={spinning} size='large' className='process_content_spining'>
          <div className='common__flex process__content process__content--admin'>
            <div className='process__content__top'>
              <div className='common__flex process__content__header'></div>
            </div>
            <div className='process__content__bottom'>
              <div className='process__content__bottom--box'>
                <div style={{flex:1}}>
                <div className={'process__new--content process__new--content--custom process__admin--custom'}>
                  <div className='process__new--intro common__process__listtaskbox'>
                    <h2 className='color__title__black mb--30 mt--30'>プロセス管理者を設定してください</h2>
                    <div className='process__new--intro '>
                      <div className='common__process__listtask  mb--30'>{listData && <FilterUserAdmin handleAdd={handleAdd} handleRemove={handleRemoveUser} taskInfo={listData} title='プロセス管理者を設定してください。' />}</div>
                    </div>
                  </div>
                </div>
                </div>
                <div className='common__process__control'>
                  <div className='common__flex common__process__control--listbtn'>
                    <div className='common__flex common__process__control--btnL'>
                      <Button
                        disabled={spinning}
                        className={spinning ? 'common__process__control--btn common__process__bottom--btn--disable' : 'bg__red common__process__control--btn'}
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        キャンセル
                      </Button>
                      {openModal && <ModalConfirm open={openModal} handleCancelConfirm={handleCancelConfirm} handleSubmit={handleCancel} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />}
                    </div>
                    <div className='common__flex common__process__control--btnR'>
                      <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__control--btn common__process__bottom--btn--disable' : 'bg__green common__process__control--btn'} onClick={submitChangeAdmin}>
                        保存して終了
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Content>
    </>
  );
}

export default ProcessEditAdmin;

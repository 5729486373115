import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HRM from '../../components/HRM/index.js';
import Process from '../../components/Process/index.js';
import Profile from '../../components/Profile/index.js';
import Reference from '../../components/Reference/index.js';
import DefaultLayout from '../../layouts/index';
import TemplateProcessContainer from '../../components/TemplateProcess/index.js';

MainPage.propTypes = {};

function MainPage(prop) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userInfo = localStorage.getItem('userInfo');
    if (!accessToken || !userInfo) {
      navigate('/login');
    }
    if (location.pathname === '/') {
      navigate('/reference/processing');
    }
  }, []);
  return (
    <DefaultLayout>
      <Routes>
        <Route path='/template'  element={<TemplateProcessContainer />} />
        <Route path='/process/*' element={<Process />} />
        <Route path='/reference/*' element={<Reference />} />
        <Route path='/hrm/*' element={<HRM />} />
        <Route path='/profile/*' element={<Profile />} />
        <Route exact path='*' element={<Navigate to='/reference/processing' />} />
      </Routes>
    </DefaultLayout>
  );
}

export default MainPage;

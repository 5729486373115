

import axiosClient from './axiosClient';

const authenticationsApi = {
  Login(data) {
    const url = '/login';
    return axiosClient.post(url, data );
  },
  ResetPassword(data) {
    const url = '/resetpassword';
    return axiosClient.post(url, data );
  },
  ForgotPassword(data) {
    const url = '/forgotpassword';
    return axiosClient.post(url, data );
  },
  
};

export default authenticationsApi;
export const MESSAGES = {
  AUTH: {
    PASSWORDERROR: 'パスワードは不正です。(※10文字以上、英大文字、英小文字、英数字、特殊記号含め）',
    PASSWORDEMTY: 'パスワードを入力してください。',
    USERNAMEEMTY: 'ID(メールアドレス)を入力してください。',
    USERNAMEERROR: 'メールアドレスは不正です。',
    CHANGEPASSWORD: 'パスワードを再度設定してください。',
  },
  PROCESS: {
    EXECNODATA: 'プロセスが削除されてるため、実行できません。',
    EDITNODATA: 'プロセスが削除されてるため、編集できません。',
    DELETENODATA: '既にプロセスが削除されています。',
    NAMETASK: 'このタスク名は既に使用されています。',
    NOTEMTY: '入力必須です。',
    MAXTASK: '追加できるタスクの上限です。',
  },
  HRM: {
    USER: {
      select_one_row: 'データを選択してください。',
      // EDITDELETEDATA: '他のユーザーがこのユーザーを削除したため編集できません',
      EDITDELETEDATA: '既に削除されています。',
      DELETENOTEAMADMIN: 'チーム管理者が0人になるため、削除することはできません。',
      DELETENOGROUPMEMBER: '割り当て済みのグループが0人になるため、削除することはできません。',
      DELETENOPROCESSADMIN: '管理者が0人になるプロセスがあるため、削除することはできません。',
      DELETENOTASKPIC: '担当者が0人になるタスクがあるため、削除することはできません。',
      DELETENOPROCESSRUNNER: '稼働中のプロセスに割り当てられているため、削除することはできません。',
      DELETENOTASKROLEMEMBER: '担当者が0人になるタスクがあるため、削除することはできません。',
    },
    GROUP: {
      select_one_row: 'データを選択してください。',
      name_in_use: '属性名は既に使用されています。',
    },
    ATTRIBUTE: {
      DELETENOGROUPMEMBER: '割り当て済みのグループが0人になるため、削除することはできません。',
    },
  },
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authenticationsApi from '../../api/authenticationsApi';
import ForgotPassword from '../../components/Auth/ForgotPassword';

PageForgotPassword.propTypes = {};

function PageForgotPassword(props) {
  const [spinning, setSpinning] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('accessToken');
  }, []);
  const handleForgotPassword = async (values) => {
    const { userName } = values;
    if (values) {
      try {
        if (userName !== '') {
            setSpinning(true);
          const result = await authenticationsApi.ForgotPassword({ ...values });
          if (result?.success) {
            toast.success(result.success, {
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            navigate('/login');
          }
          if (result?.error && !toast.isActive({toastId:'toast_warning'})) {
            toast.warning(result.error, {
              toastId:'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
        }
        setSpinning(false);
      } catch (error) {
        setSpinning(false);
        navigate('/forgotpassword');
      }
    }
  };
  return (
    <div>
      <ForgotPassword spinning={spinning} onForgotPassword={handleForgotPassword} />
    </div>
  );
}

export default PageForgotPassword;

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input } from 'antd';
import React from 'react';
import logo from '../../assets/logo.jpg';
import logoGakkenCompany from '../../assets/GAKKEN_LOGO.png';
import { Link } from 'react-router-dom';
import { COMMON_MESSAGES } from '../../commons/commonMessages/commonMessages';
import './css/signIn.css';
import { resetMilliseconds } from '../../commons/commonFunctions/CommonFunction';

SignIn.propTypes = {};

function SignIn(props) {
  const { onSignIn, errormessage } = props;
  const [formlogin__signIn] = Form.useForm();
  const onFinish = (values) => {
    const userName = values.username.trim();
    const password = values.password.trim();
    if (onFinish) {
      onSignIn({ userName, password, edit_date: resetMilliseconds(new Date())});
    }
  };

  return (
    <div className='login__signIn'>
      <div className='login__signIn__box'>
        <div className='common__flex login__signIn__top '>
          <div className='login__signIn__top__logo'>
            <img src={logo} alt='logo' />
          </div>
        </div>
        <div className='common__flex login__signIn__form'>
          <Form name='login__signIn__form' form={formlogin__signIn} className='login__signIn__form--form' onFinish={onFinish}>
            <Form.Item
              className='login__signIn__form--item'
              name='username'
              rules={[
                {
                  type: 'email',
                  pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: COMMON_MESSAGES.AUTH.USERNAMEERROR,
                },
                {
                  required: true,
                  message: COMMON_MESSAGES.AUTH.USERNAMEEMTY,
                },
              ]}
            >
              <Input maxLength={254} prefix={<UserOutlined className='site-form-item-icon' />} placeholder='ID(メールアドレス)' />
            </Form.Item>
            <Form.Item
              className='login__signIn__form--item'
              name='password'
              rules={[
                {
                  required: true,
                  message: COMMON_MESSAGES.AUTH.PASSWORDEMTY,
                },
              ]}
            >
              <Input.Password autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='パスワード' />
            </Form.Item>
            {errormessage && (
              <Form.Item className='login__signIn__form--item' name='errormessage' rules={null}>
                <div style={{ whiteSpace: 'pre-line', color: 'red' }}>{errormessage}</div>
              </Form.Item>
            )}

            <div className='login__signIn__top__forgot--password mb--10'>
              <Link to='/forgotpassword'>パスワードを忘れた方</Link>
            </div>
            <div className='common__flex login__signIn__form__submit'>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='login__signIn-form-button'>
                  ログイン
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className='common__flex login__signIn__bottom'>
          <div className='login__signIn__bottom__logo'>
            <Image src={logoGakkenCompany} alt={logo} preview={false} />
          </div>
          <div className='login__signIn__bottom__name'>
            <p>Gakken Logistics Co.,Ltd. © 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;

import React from 'react';
import ReferenceList from './List';

ReferenceListContainer.propTypes = {};

function ReferenceListContainer(props) {
    return (
        <ReferenceList />
    );
}

export default ReferenceListContainer;
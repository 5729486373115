import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ReferenceList from './components/ReferenceList';
import TaskProcessing from './components/TaskProcessing';
import TaskPlanning from './components/TaskPlanning';
import TaskDone from './components/TaskDone';
import TaskAdminRun from './components/TaskAdminRun';
import TaskAdminDone from './components/TaskAdminDone';
import TaskReference from './components/TaskReference';
import './css/style.css';

Reference.propTypes = {};

function Reference(props) {
  return (
    <Routes>
      <Route path='/processing' element={<TaskProcessing />} />
      <Route path='/task/planning' element={<TaskPlanning />} />
      <Route path='/task/done' element={<TaskDone />} />
      <Route path='/*' element={<Navigate to="/reference/processing" />}/>
    </Routes>
  );
}

export default Reference;

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { hrmsApi } from '../../../api/hrmApi';

SelectI3links.propTypes = {};

function SelectI3links(props) {
  const { team_id, listAttribute, onChangeAttributeOfUser } = props;
  const [attributeByUser, setAttributeByUser] = useState(() => {
    return listAttribute.attributeByUser;
  });

  useEffect(() => {
    setAttributeByUser(() => {
      return listAttribute.attributeByUser;
    });
  }, [listAttribute.attributeByUser]);

  const handleOnChange = async (values) => {    
    const currentSet = new Set(values);
    const deleteAttributes = attributeByUser.filter(x => !currentSet.has(x.user_attributes_detail_id))
    for(let i = 0; i < deleteAttributes.length; i++){
      const resultDeleteCheck = await hrmsApi.usersApi.checkAttributesAllocation({
        team_id: team_id,
        user_attributes_id: deleteAttributes[i].user_attributes_id, 
        user_attributes_detail_id: deleteAttributes[i].user_attributes_detail_id
      })
      if(resultDeleteCheck?.error && !toast.isActive({toastId:'toast_warning'})){
        toast.warning(resultDeleteCheck.error, {
          toastId:'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        onChangeAttributeOfUser(attributeByUser);
        return;
      }
      if (resultDeleteCheck?.validate && !toast.isActive({toastId:'toastId_err'})) {
        toast.error(resultDeleteCheck.validate, {
          toastId:'toastId_err',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        onChangeAttributeOfUser(attributeByUser);
        return;
      }
    }
    if(values.length > 0 && onChangeAttributeOfUser){
     const newAttributeByUser = values.map((item) => {
       const user_attributes_detail_id = item;
       const user_attributes_id = listAttribute.listAllDetail[0].user_attributes_id;
       return { user_attributes_detail_id, user_attributes_id };
     });

    onChangeAttributeOfUser(newAttributeByUser);
   }
   if(values.length <= 0 && onChangeAttributeOfUser){
    onChangeAttributeOfUser([{ user_attributes_detail_id:null, user_attributes_id:listAttribute.listAllDetail[0].user_attributes_id }]);
   }
  };

  return (
    <>
      {listAttribute.listAllDetail.length > 0 && (
        <Select
          mode='multiple'
          maxTagCount= 'responsive'
          onChange={handleOnChange}
          value={attributeByUser?.map((item) => item.user_attributes_detail_id)}
          style={{ width: '100%', minWidth: 250 }}
          options={listAttribute.listAllDetail?.map((item, index) => ({
            label: item.user_attributes_detail_name,
            value: item.user_attributes_detail_id,
            key: index,
          }))}
        />
      )}
    </>
  );
}

export default SelectI3links;

import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Input } from 'antd';
import { toast } from 'react-toastify';
import { escapeRegExp } from '../../commonFunctions/CommonFunction';

InputI3links.propTypes = {};
const byteSize = (str) => new Blob([str]).size;
function InputI3links(props) {
  const { item, maxLength, handleEdit = () => {}, handleActive = () => {},placeholder='ここにタスク名を入力',autoFocus=true,textAlign='left' } = props;
  const [valueText, setValueText] = useState(item.task_name);
  return (
    <>
      <Input
        value={valueText}
        maxLength = {maxLength}
        onChange={(e) => {
          const task_name = e.target.value;
          const nameEscapeRegExp = escapeRegExp(task_name)
          if (nameEscapeRegExp.length <=40 && !toast.isActive({toastId:'length_name_task'})) {
            setValueText(nameEscapeRegExp);
          } else {
            toast.warning('40字以内で入力してください。', {
              toastId:'length_name_task',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }

        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && handleActive) {
            const id = item.id;
            handleEdit({ ...item,id, task_name:valueText });
          }
        }}
        onBlur={(e) => {
          const id = item.id;
          handleEdit({ ...item,id, task_name:valueText });
        }}
        autoFocus={autoFocus}
        placeholder={placeholder}
        style={{textAlign:textAlign}}
      />
    </>
  );
}

export default InputI3links;

import axiosClient from './axiosClient';
import queryString from 'query-string';

export const fileApi = {
  execute(data) {
    const url = '/execute';
    return axiosClient.post(url, data);
  },
  async download(params) {
    const searchParam = queryString.stringify(params)

    const urlFile = `/downloadfile/${searchParam}`;
    const resFile = await axiosClient.get(urlFile, { params:{...params} });
    //console.log(resFile)
    let fileName = (resFile?.fileName) ? (resFile?.fileName) : "マニュアル";
    
    const url = `/download/${searchParam}`;
    //return axiosClient.get(url, {params: {...params}});
    return axiosClient
      .get(url, {
        params: { ...params },
        responseType: 'blob' // important
      })
      .then(res => {
        if(res){
          // let error;
          // res.text().then(text => {
          //   const result = JSON.parse(text);
          //   if(result?.error){
          //     error = {error: result.error};
          //   }
          // })

          // if(error){
          //   return error;
          // }
          // else{
          // }
          const href = URL.createObjectURL(res); // creates a object url in memory

          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName); // name must include a valid file extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link); // removing the link element
          URL.revokeObjectURL(href); // Releasing the URL from memory, this is important for optimal performance and memory usage
        }
      });
  },
};

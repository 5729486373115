import { Col, Layout, Row, Table } from 'antd';
import React from 'react';
import { BiSearchAlt } from "react-icons/bi";
import { useLocation, useNavigate } from 'react-router-dom';
import './css/style.css';
const { Header, Content, } = Layout;
ReferenceList.propTypes = {};

function ReferenceList(props) {
  const location = useLocation();
  const navigation = useNavigate();

  const dataSource = [
    {
      key: '1',
      name: '処理中マイタスク',
      link: 'processing',
    },
    // {
    //   key: '2',
    //   name: '予定しているマイタスク',
    //   link: 'planning',
    // },
    // {
    //   key: '3',
    //   name: '処理済のマイタスク',
    //   link: 'done',
    // },
    {
      key: '4',  
      name: '自分が管理者のプロセス',
      link: 'adminrun',
    },
    {
      key: '5',
      name: '自分が管理者の完了済プロセス',
      link: 'admindone',
    },
  ];
  
  const columns = [
    {
      title: '検索条件',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
  ];


  ///////// handleclick process
  const handleClick = (choice) => {
    if (choice) {
        navigation({
          pathname: `${location.pathname + (location.pathname.endsWith('/') ? '' : '/') + choice.link}`,
        });
    }
  };

  const handleDoubleClick = (choice) => {
    if (choice) {
      navigation({
        pathname: `${location.pathname + (location.pathname.endsWith('/') ? '' : '/') + choice.link}`,
      });
    }
  };

  return (
    <>
      <Header className='header__common'>
        <div className='flex__box flex__jus__sb'>
          <div className='header__title'>
            <h1>
              <BiSearchAlt color='#000' /> 検索条件
            </h1>
          </div>
        </div>
      </Header>
      <Content>
        <Row  style={{padding:20}}>
          <Col span={24}>
            <Table
            size='small'
              type='radio' 
              dataSource={dataSource} 
              columns={columns} 
              pagination={false}
              //handleDoubleClick={handleDoubleClick} 
              //handleClick={handleClick}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event  => {handleClick(record)},
                  onDoubleClick: event  => {handleDoubleClick(record)},
                };
              }}
            />
          </Col>
         
        </Row>
      </Content>
    </>
  );
}

export default ReferenceList;

import { Button, Col, DatePicker, Form, Input, Layout, Modal, Row, Space, Spin, Table } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FaEraser, FaFilter, FaSearch, FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hrmsApi } from '../../../../api/hrmApi';
import { MESSAGES } from '../../../../commons/Messages';
import ModalConfirm from '../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import TableI3links from '../../../../commons/commonComponents/tableI3links/TableI3links';
import { FORMATTRIM, escapeRegExp } from '../../../../commons/commonFunctions/CommonFunction';
import { FormatDate } from '../../../../commons/commonFunctions/FormatDate';
import useWindowWidth from '../../../../hooks/useWindowWidth/useWindowWidth';
import { teamState } from '../../../../redux/selector';
import '../../css/style.css';
const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;
User.propTypes = {};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const filterData = (listUser,sortFilter)=>{
  let dataFilterUser = [...listUser];
  if (sortFilter.filterFirstName?.trim()) {
    dataFilterUser = dataFilterUser.filter((item) => item.user_first_name.includes(sortFilter.filterFirstName?.trim()));
  }
  if (sortFilter.filterLastName?.trim()) {
    dataFilterUser = dataFilterUser.filter((item) => item.user_last_name.includes(sortFilter.filterLastName?.trim()));
  }
  if (sortFilter.filterEmail?.trim()) {
    dataFilterUser = dataFilterUser.filter((item) => item.login_id.includes(sortFilter.filterEmail?.trim()));
  }
  if (sortFilter.filterLastLogin[0] && sortFilter.filterLastLogin[1]) {
    dataFilterUser = dataFilterUser.filter((item) => item.last_login_date && new Date(`${item.last_login_date}`) >= new Date(sortFilter.filterLastLogin[0]) && new Date(`${item.last_login_date}`) <= new Date(sortFilter.filterLastLogin[1]));
  }
  return dataFilterUser
}


function User(props) {
  const { listUser, queryParams, openModel, handlePagination = () => {}, spinningUserLoad } = props;
  const [listAttribute, setListAttribute] = useState([]);
  const [listUserFilter, setListUserFilter] = useState([]);
  const [listRemoveAttribute, setListRemoveAttribute] = useState([]);
  const [listOldAttribute, setListOldAttribute] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const teamInfo = useSelector(teamState);
  const [showModel, setShowModel] = useState(() => openModel);
  const [showDetailsList, setShowDetailsList] = useState(false);
  const [affectList, setAffectList] = useState([]);
  const [affectColumn, setAffectColumn] = useState([]);
  const [userInfo, setuserInfo] = useState({});
  const [formUser] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [paramsUrl, setParamsUrl] = useState('user');
  const [searchName, setSearchName] = useState('');
  const [pagination, setPagination] = useState(() => {
    const PaginationLocal = localStorage.getItem('paginationUser')
      ? JSON.parse(localStorage.getItem('paginationUser'))
      : {
          totals: 1,
          limit: 10,
          page: 1,
        };
    return PaginationLocal;
  });
  const columnAffectList1 = [{ title: 'プロセス名', align: 'left', dataIndex: 'process_name', key: '1', ellipsis: true }];
  const columnAffectList2 = [
    { title: 'プロセス名', align: 'left', dataIndex: 'process_name', key: '1', ellipsis: true },
    { title: 'タスク名', align: 'left', dataIndex: 'task_name', key: '2', ellipsis: true },
  ];

  ////////////////////////////////////////////////////////////////////////////////
  const windowWidth = useWindowWidth();
  const [sortFilter, setSortFilter] = useState({
    filterFirstName: '',
    filterLastName: '',
    filterEmail: '',
    filterLastLogin: [null, null],
  });
  const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys[0];
      return { ...newListSort };
    });
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys;
      return { ...newListSort };
    });
  };
  const handleReset = (clearFilters,filterName,confirm) => {
    if(filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality'|| filterName === 'filterEndTimeReality'){
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = [];
        return { ...newListSort };
      });
    }else{
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = '';
        return { ...newListSort };
      });
    }
    confirm()
    clearFilters();
  };
  ///// lọc theo text
  const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={placeholder}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
            icon={<FaFilter />}
            size='small'
            style={{
              width: 90,
            }}
          ></Button>
          <Button
            onClick={() => {
              setSelectedKeys([])
              return clearFilters && handleReset(clearFilters,filterName,confirm)
            }}
            size='small'
            type='primary'
            style={{
              width: 90,
            }}
            icon={<FaEraser />}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });
  ///// lọc theo ngày
  const getColumnSearchDateProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, padding: 10 }}>
          <div>
            <RangePicker
              type=''
              defaultValue={sortFilter.filterNextStartTime}
              value={selectedKeys.length ? selectedKeys.map((date) => (date ? moment(date) : null)) : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? [moment(dates[0]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(), moment(dates[1]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()] : []);
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              onClick={() => {
                handleSearchDate(selectedKeys, confirm, dataIndex, filterName);
              }}
              size='small'
              type='primary'
              style={{ width: 90, marginRight: 8 }}
              icon={<FaFilter />}
            />
            <Button
              onClick={() => {
                setSelectedKeys([])
              return clearFilters && handleReset(clearFilters,filterName,confirm)
              }}
              size='small'
              type='primary'
              style={{ width: 90 }}
              icon={<FaEraser />}
            />
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });

  const WidthInfo = (windowWidth - 160) * 0.25;
  const Widthtable = windowWidth - WidthInfo - 210 ;
  const [Columns, setColumns] = useState(() => {
    return [
      { title: '', align: 'center', dataIndex: 'no', key: '1', ellipsis: true, width: 50 },
      {
        title: '名字',
        align: 'left',
        dataIndex: 'user_first_name',
        key: '2',
        ellipsis: true,
        width: (Widthtable) / 4,
        sorter: {
          compare: (a, b) => a.user_first_name?.localeCompare(b.user_first_name),
          multiple: 1,
        },
        ...getColumnSearchProps('user_first_name', '名字', 'filterFirstName'),
      },
      {
        title: '名前',
        align: 'left',
        dataIndex: 'user_last_name',
        key: '3',
        ellipsis: true,
        width: (Widthtable) / 4,
        sorter: {
          compare: (a, b) => a.user_last_name?.localeCompare(b.user_last_name),
          multiple: 1,
        },
        ...getColumnSearchProps('user_last_name', '名前', 'filterLastName'),
      },
      {
        title: 'ID(メールアドレス)',
        align: 'left',
        dataIndex: 'login_id',
        key: '4',
        ellipsis: true,
        width: (Widthtable) / 4 > 120 ? (Widthtable) / 4 : 130,
        sorter: {
          compare: (a, b) => a.user_last_name?.localeCompare(b.user_last_name),
          multiple: 1,
        },
        ...getColumnSearchProps('login_id', 'ID(メールアドレス)', 'filterEmail'),
      },
      {
        title: '最終ログイン日時',
        align: 'left',
        dataIndex: 'last_login_date',
        key: '5',
        ellipsis: true,
        width: (Widthtable) / 4 > 120 ? (Widthtable) / 4 - 25 : 120,
        sorter: {
          compare: (a, b) => new Date(a.last_login_date) - new Date(b.last_login_date),
          multiple: 1,
        },
        ...getColumnSearchDateProps('last_login_date', '最終ログイン日時', 'filterLastLogin'),
      },
      { title: '', align: '', dataIndex: '', key: '', ellipsis: true },
    ];
  });

  ///sort khi có thông tin sort thay đổi
  useEffect(() => {
   const dataFilterUser = filterData(listUser,sortFilter)
    setListUserFilter(() => {
      return dataFilterUser.map((item, index) => {
        return { ...item, no: index + 1 };
      });
    });
    setPagination((prevPagination) => {
      return { ...prevPagination };
    });
  }, [sortFilter,location.search]);

  ////////////////////////////////////////////////////////////////////////////////

  const SaveUserIdClick = (user) => {
    if (userInfo === undefined || userInfo.user_id !== user.user_id) {
      setuserInfo(user);
    }
  };

  const handleDoubleClick = async () => {
    const filter = {
      id: userInfo.user_id,
    };
    if (!location.pathname.includes('user')) {
      navigate({
        pathname: `${location.pathname}/user`,
        search: queryString.stringify(filter),
      });
    } else {
      navigate({
        pathname: `${location.pathname}`,
        search: queryString.stringify(filter),
      });
    }
  };

  const handlePaginationNexted = (values) => {
    if (values) {
      handlePagination(values);
      let newPagination = { ...pagination, ...values };
      localStorage.setItem('paginationUser', JSON.stringify(newPagination));
      setPagination(newPagination);
    }
  };

  const handleEdit = async () => {
    if (!userInfo.user_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(MESSAGES.HRM.USER.select_one_row, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    } else {
      const result = await hrmsApi.usersApi.getUserById(userInfo.user_id);
      if (!result?.data && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(MESSAGES.HRM.USER.EDITDELETEDATA, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        return;
      }
    }
    const filter = {
      id: userInfo.user_id,
    };
    if (!location.pathname.includes('user') && location.pathname.includes('delete')) {
      navigate({
        pathname: `${location.pathname}/user`,
        search: queryString.stringify(filter),
      });
    } else {
      navigate({
        pathname: `${location.pathname}`,
        search: queryString.stringify(filter),
      });
    }
  };
  const handleConfirmDelete = () => {
    if (!userInfo.user_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(MESSAGES.HRM.USER.select_one_row, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const filter = {
      id: userInfo.user_id,
    };
    if (!location.pathname.includes('user')) {
      navigate({
        pathname: `${location.pathname}/user/delete`,
        search: queryString.stringify(filter),
      });
    } else {
      navigate({
        pathname: `${location.pathname}/delete`,
        search: queryString.stringify(filter),
      });
    }
  };
  const handleDeleteUser = async () => {
    try {
      const user_id = userInfo.user_id;
      const team_id = teamInfo.listTeam[0]?.team_id;
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (user === null || user.user_id === null) {
        navigate('/login');
        return;
      }
      if (user.user_id === user_id && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning('自分のアカウントは削除できません。', {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        navigate('/hrm/user');
        return;
      }

      const result = await hrmsApi.usersApi.delete({ user_id, team_id });
      if (result?.success) {
        toast.success(result.success, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        setShowModel(false);
        setuserInfo({});
      }
      if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(result.error, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
      if (result?.validate && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(AffectListMessage(result), {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 0,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }

      navigate('/hrm/user');
    } catch (error) {
      console.log(error.message);
    }
  };

  const AffectListMessage = (message) => (
    <div>
      {message.validate}
      <p>
        <Button
          type='link'
          size='small'
          onClick={() => {
            setAffectList(message.data);
            setAffectColumn(message.type == 1 ? columnAffectList1 : columnAffectList2);
            setShowDetailsList(true);
          }}
        >
          詳細情報
        </Button>
      </p>
    </div>
  );

  //// cancel
  const handleCancel = () => {
    setShowModel(false);
    // setuserInfo({});
    navigate('.');
  };

  ////onFinish
  const onFinish = async (values) => {
    try {
      setSpinning(true);
      const teamId = teamInfo.listTeam[0]?.team_id;
      if (!teamId && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(`まだ何もチームに付属していません！`, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }

      let data = { ...values, listRemoveAttribute };
      const userCreate = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (userCreate === null) {
        navigate('/login');
      }
      const newListAttributeByUser = listAttribute.reduce((arr, item) => {
        arr = [...arr, ...item.attributeByUser];
        return arr;
      }, []);
      data.team_id = teamId;
      data.user_level = true;
      data.authority_user_insert = true;
      data.authority_reference_respond_info = true;
      data.notes = data.notes ? data.notes : '';
      data = FORMATTRIM(data);
      data.edit_date = new Date().toString();
      data.newDate = new Date();

      if (!location.pathname.includes('new')) {
        const checkUser = await hrmsApi.usersApi.getUserById(userInfo.user_id);
        if (!checkUser?.data && !toast.isActive({ toastId: 'toast_warning' })) {
          toast.warning(MESSAGES.HRM.USER.EDITDELETEDATA, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: false,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });

          setSpinning(false);
          setShowModel(false);
          navigate({
            pathname: `/hrm/user`,
          });
          return;
        }
        for (let i = 0; i < listRemoveAttribute.length; i++) {
          const check = await hrmsApi.usersApi.checkTaskAllocation({
            team_id: data.team_id,
            user_attributes_id: listRemoveAttribute[i].user_attributes_id,
            user_attributes_detail_id: listRemoveAttribute[i].user_attributes_detail_id,
            user_id: +userInfo.user_id,
          });
          if (check?.error && !toast.isActive({ toastId: 'toastId_err' })) {
            toast.error(MESSAGES.HRM.USER.DELETENOTASKROLEMEMBER, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            setSpinning(false);
            return;
          }

          const resultCheckAttributesAllocation = await hrmsApi.usersApi.checkAttributesAllocation({
            team_id: teamId,
            user_attributes_id: listRemoveAttribute[i].user_attributes_id,
            user_attributes_detail_id: listRemoveAttribute[i].user_attributes_detail_id,
          });
          if (resultCheckAttributesAllocation?.error && !toast.isActive({ toastId: 'toastId_err' })) {
            toast.error(resultCheckAttributesAllocation.error, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            setSpinning(false);
            return;
          }
          if (resultCheckAttributesAllocation?.validate && !toast.isActive({ toastId: 'toastId_err' })) {
            toast.error(resultCheckAttributesAllocation.validate, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            setSpinning(false);
            return;
          }
        }
        const newAttributeByUser = newListAttributeByUser.filter((item) => {
          const check = item;
          const list = listOldAttribute.filter((item) => {
            const flag = item.user_attributes_detail_id === check.user_attributes_detail_id;
            return check.user_attributes_id === item.user_attributes_id && flag;
          });
          return list.length > 0 ? false : true;
        });
        data.user_id = +userInfo.user_id;
        data.entry_id = +userCreate.user_id;
        data.edit_id = +userCreate.user_id;
        data.newAttributeByUser = newAttributeByUser;
        data.curr_edit_id = userInfo.edit_id;
        data.curr_edit_date = userInfo.edit_date;
        const result = await hrmsApi.usersApi.update(+userInfo.user_id, data);
        if (result?.success) {
          toast.success(result.success, {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });

          if (+userCreate.user_id === +userInfo.user_id) {
            const checkUser = await hrmsApi.usersApi.getUserById(userInfo.user_id);
            localStorage.setItem('userInfo', JSON.stringify(checkUser.data));
          }
          setShowModel(false);
          setuserInfo({});
          navigate('.');
        }
        if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
          toast.warning(result.error, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
      } else {
        data.newAttributeByUser = newListAttributeByUser;
        data.entry_id = +userCreate.user_id;
        data.edit_id = +userCreate.user_id;
        data.user_level = true;
        const result = await hrmsApi.usersApi.create(data);
        if (result?.success) {
          toast.success(result.success, {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          setShowModel(false);
          navigate('.');
        }
        if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
          toast.warning(result.error, {
            toastId: 'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
      }
      setSpinning(false);
    } catch (error) {
      setSpinning(false);
      if (error.message && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(error.message, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    } finally {
      setSpinning(false);
    }
  };

  const handleOnchangeSearch = (value) => {
    setSearchName(value);
  };
  const handleSearch = () => {
    const params = queryString.parse(location.search);
    const team_id = localStorage.getItem('listTeam') ? JSON.parse(localStorage.getItem('listTeam')) : null;
    const filter = {
      ...params,
      // team_id:team_id,
      page: 1,
      limit: 10,
      like: searchName || undefined,
    };
    navigate({
      pathname: `${location.pathname}`,
      search: queryString.stringify(filter),
    });
  };

  //////goi api lay du lieu moi khi paramquery thay doi///////
  useEffect(() => {
    try {
      const teamId = teamInfo.listTeam[0]?.team_id;
      if (!teamId) {
        return;
      }
      if (location.pathname.includes('new') && !location.pathname.includes('delete')) {
        setShowModel(true);
        setListRemoveAttribute([]);
        formUser.resetFields();
        (async () => {
          const params = { ...queryParams };
          const result = await hrmsApi.usersApi.getAllAttributeByUser(params);
          if (result) {
            const listAttribute = result.data.map((item, index) => {
              item.key = index;
              return item;
            });
            setListAttribute(listAttribute);
          }
        })();
        return;
      }
      if (location.search.includes('?id') && !location.pathname.includes('new') && !location.pathname.includes('delete')) {
        setShowModel(true);
        if (userInfo.user_id) {
          formUser.setFieldsValue({
            login_id: userInfo.login_id,
            user_last_name: userInfo.user_last_name,
            user_first_name: userInfo.user_first_name,
            user_level: userInfo.team_user_level === undefined ? true : +userInfo.team_user_level > 1 ? true : false,
            authority_user_insert: true,
            authority_reference_respond_info: true,
            notes: userInfo.notes,
          });
          (async () => {
            const user_id = userInfo.user_id;
            const params = { ...queryParams, id: user_id };
            const result = await hrmsApi.usersApi.getAllAttributeByUser(params);
            let ListOldAttributeApi = [];
            if (result) {
              const listAttribute = result.data.map((item, index) => {
                ListOldAttributeApi = [...ListOldAttributeApi, ...item.attributeByUser];
                item.key = index;
                return item;
              });
              setListRemoveAttribute([]);
              setListAttribute(listAttribute);
              setListOldAttribute(ListOldAttributeApi);
            }
          })();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [queryParams, location.pathname]);
  useEffect(() => {
    if (location.pathname.includes('group')) {
      setParamsUrl('group');
    } else {
      if (location.search) {
        const id = queryString.parse(location.search);
        const newArrUser = listUser.filter((item) => +item.user_id === +id.id);
        if (newArrUser.length > 0) {
          setuserInfo(newArrUser[0]);
          formUser.setFieldsValue({
            login_id: newArrUser[0].login_id,
            user_last_name: newArrUser[0].user_last_name,
            user_first_name: newArrUser[0].user_first_name,
            user_level: newArrUser[0].team_user_level === undefined ? true : +newArrUser[0].team_user_level > 1 ? true : false,
            authority_user_insert: true,
            authority_reference_respond_info: true,
            notes: newArrUser[0].notes,
          });
        }
      }
      setParamsUrl('user');
      const dataFilterUser = filterData(listUser,sortFilter)
      setListUserFilter(() => {
        return dataFilterUser.map((item, index) => {
          return { ...item, no: index + 1 };
        });
      });
    }
  }, [location.pathname, location.search, listUser]);

  return (
    <>
      <Header className='header__hrm' style={{ marginBottom: 20 }}>
        <Row gutter={[16, 16]} style={{ height: '99%' }}>
          <Col
            span={24}
            lg={{
              span: 18,
            }}
            md={{
              span: 18,
            }}
          >
            <div className='flex__box flex__jus__sb'>
              <div className='header__control'>
                <div className='header__title'>
                  <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                    <FaUsers size={20} color='#000' />
                    <h1 className='header__content__title'>ユーザー</h1>
                  </div>
                </div>
              </div>
              <div className='flex__box flex__jus__sb flex__gap__cl--20' style={{ flex: 1, justifyContent: 'flex-end' }}>
                <div className='hearder__search' style={{ width: '50%' }}>
                  {/* <Input
                    maxLength={60}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSearch();
                      }
                    }}
                    onChange={(value) => {
                      const text = value.target.value;
                      handleOnchangeSearch(text);
                    }}
                    placeholder='キーワードを入力'
                    suffix={<FaSearch onClick={handleSearch} style={{ cursor: 'pointer' }} />}
                  /> */}
                </div>
              </div>
            </div>
          </Col>
          <Col
            span={24}
            lg={{
              span: 6,
            }}
            md={{
              span: 6,
            }}
          >
            <div className='hearder__btn__new' style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button className='i3link__btn'>
                <Link to={`${location.pathname}/new`}> 新規作成</Link>
              </Button>
            </div>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row gutter={[16, 16]} style={{ height: '99%', marginRight: 0 }}>
          <Col
            span={24}
            lg={{
              span: 18,
            }}
            md={{
              span: 18,
            }}
          >
            <div className='common__content__left' style={{ width: '100%', padding: 10 }}>
              <TableI3links className='common__table ' scroll={{ x: '100%' }} idProcess={userInfo.user_id || null} Pagination={pagination} spinning={spinningUserLoad} handlePaginationNexted={handlePaginationNexted} handleDoubleClick={handleDoubleClick} handleClick={SaveUserIdClick} type='radio' position='topRight' pageSizeOptions={['10', '25', '50', '100']} dataSource={listUserFilter} total={listUserFilter.length} columns={Columns} />
            </div>
          </Col>
          <Col
            span={24}
            lg={{
              span: 6,
            }}
            md={{
              span: 6,
            }}
          >
            <div className='common__content__righ' style={{ background: '#fff', height: 'calc(100vh - 130px)', maxHeight: '1080px', padding: 16, position: 'sticky', top: windowWidth < 800 ? 'initial' : 120 }}>
              <Row style={{ height: '100%' }}>
                <Col span={24} style={{ height: '10%' }}>
                  <div className='flex__box flex__jus__c'>
                    <Button
                      onClick={() => {
                        handleEdit();
                      }}
                      className='i3link__btn bg__blue'
                    >
                      編 集
                    </Button>
                  </div>
                </Col>
                <Col span={24} style={{ height: '80%' }}>
                  <div className='common__content__right--middle'>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>名字：</span>
                      <span className='common__content__right--name'>{userInfo.user_first_name}</span>
                    </p>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>名前：</span>
                      <span className='common__content__right--name'>{userInfo.user_last_name}</span>
                    </p>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>ID(メールアドレス)：</span>
                      <span className='common__content__right--name'>{userInfo.login_id}</span>
                    </p>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>登録者：</span>
                      <span className='common__content__right--name'>{userInfo.full_name_entry}</span>
                    </p>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>登録日時：</span>
                      <span className='common__content__right--name'>{userInfo.entry_date ? FormatDate(new Date(userInfo.entry_date)) : ''}</span>
                    </p>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>更新者：</span>
                      <span className='common__content__right--name'>{userInfo.full_name_edit}</span>
                    </p>
                    <p className='common__content__right--info'>
                      <span className='common__content__right--lable'>更新日時：</span>
                      <span className='common__content__right--name'>{userInfo.edit_date ? FormatDate(new Date(userInfo.edit_date)) : ''}</span>
                    </p>
                  </div>
                </Col>
                <Col span={24} style={{ height: '10%' }}>
                  <div className='common__content__right--bottom flex__box flex__jus__c' style={{ height: '100%', alignItems: 'flex-end' }}>
                    <Button
                      className='i3link__btn'
                      onClick={() => {
                        handleConfirmDelete();
                      }}
                    >
                      削 除
                    </Button>
                    <ModalConfirm handleSubmit={handleDeleteUser} textConfirm='選択されたユーザーを削除してよろしいですか？' textConfirmMid={`ユーザー名: ${userInfo.full_name}`} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Modal
            className='modle__user'
            width={500}
            maskClosable={false}
            closable={true}
            title='ユーザー'
            open={showModel}
            onCancel={() => {
              handleCancel();
            }}
            forceRender
            footer={[
              <div key={1} className='modle__user__btn flex__box flex__jus__c'>
                <Button
                  disabled={spinning}
                  className='i3link__btn  bg__red'
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  disabled={spinning}
                  className='i3link__btn bg__blue'
                  htmlType='submit'
                  onClick={() => {
                    // onFinish()
                    formUser.submit();
                  }}
                >
                  保存
                </Button>
              </div>,
            ]}
          >
            <Spin spinning={spinning} tip='Loading...'>
              <Form {...formItemLayout} form={formUser} name='formUser' onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                  <Col span={12} lg={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item
                      name='login_id'
                      label='ID(メールアドレス)'
                      hasFeedback
                      rules={[
                        {
                          type: 'email',
                          message: 'ID(メールアドレス)が正しくありません。',
                        },
                        {
                          required: true,
                          message: 'ID(メールアドレス)を入力してください。',
                        },
                      ]}
                    >
                      <Input maxLength={254} type='email' />
                    </Form.Item>

                    <Form.Item
                      name='user_first_name'
                      label='名字'
                      rules={[
                        {
                          required: true,
                          message: '名字を入力してください。',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        maxLength={60}
                        onChange={(e) => {
                          const value = e.target.value;
                          const nameEscapeRegExp = escapeRegExp(value);
                          formUser.setFieldsValue({
                            user_first_name: nameEscapeRegExp,
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name='user_last_name'
                      label='名前'
                      rules={[
                        {
                          required: true,
                          message: '名前を入力してください。',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        maxLength={60}
                        onChange={(e) => {
                          const value = e.target.value;
                          const nameEscapeRegExp = escapeRegExp(value);
                          formUser.setFieldsValue({
                            user_last_name: nameEscapeRegExp,
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item name='notes' label='備考'>
                      <Input.TextArea
                        rows={3}
                        maxLength={1000}
                        onChange={(e) => {
                          const value = e.target.value;
                          const nameEscapeRegExp = escapeRegExp(value);
                          formUser.setFieldsValue({
                            notes: nameEscapeRegExp,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} style={{ padding: 10 }}>
                {
                  <Table
                  size='small'
                    onChangeAttributeOfUser={onChangeAttributeOfUser}
                    columns={USER_ATTRIBUTE_COLUMNS}
                    dataSource={listAttribute}
                    locale={{
                      emptyText: <Result title='データなし' />,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {},
                      };
                    }}
                    pagination={{
                      current: pagination.page,
                      showSizeChanger: false,
                      // position: [position],
                      locale: { items_per_page: '件/ページ' },
                      defaultPageSize: 5,
                      pageSizeOptions: ['5', '10', '15', '20'],
                      total: listAttribute.length,
                      onChange: (page, pageSize) => {
                        onchangePage({ page, pageSize });
                      },
                    }}
                  />
                }
              </Col> */}
                </Row>
              </Form>
            </Spin>
          </Modal>
          <Modal
            open={showDetailsList}
            closable={true}
            onCancel={() => {
              setShowDetailsList(false);
            }}
            footer={false}
          >
            <Table className='list_detail' style={{ paddingTop: 20, disabled: true }} type='radio' size='small' dataSource={affectList} columns={affectColumn} pagination={false} />
          </Modal>
        </Row>
      </Content>
    </>
  );
}

export default User;

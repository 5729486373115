import { Button, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { scheduleApi } from '../../../../api/scheduleApi';
import ModalConfirm from '../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { teamState } from '../../../../redux/selector';
import SortListSchedule from './SortListSchedule';
import { toast } from 'react-toastify';
import { processApi } from '../../../../api/processApi';
import { FormatDateCustom } from '../../../../commons/commonFunctions/FormatDate';

ContainerScheduleTimer.propTypes = {};

function ContainerScheduleTimer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [defaultNameProcess, setDefaultNameProcess] = useState('');
  const teamInfo = useSelector(teamState);
  const [spinning, setSpinning] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listScheduleTime, setListScheduleTime] = useState(() => {
    return new Array(1).fill(null).map((item, index) => {
      return {
        key: index,
        value: index + 1,
        name: `scheduler ${index + 1}`,
        startTime: new Date(),
      };
    });
  });
  const handleAddSchedule = () => {
    const newItem = {
      key: listScheduleTime.length,
      value: listScheduleTime.length + 1,
      name: defaultNameProcess,
      startTime: null,
    };
    const newListScheduleTime = [...listScheduleTime, newItem];
    if (newListScheduleTime.length <= 20) {
      setListScheduleTime([...newListScheduleTime]);
    }
  };
  const handleRemoveSchedule = (id) => {
    const newListScheduleTime = listScheduleTime
      .filter((item) => item.key !== id)
      .map((item, index1) => {
        return { ...item, key: index1 };
      });
    if (newListScheduleTime.length > 0) {
      setListScheduleTime([...newListScheduleTime]);
    } else {
      setListScheduleTime([
        {
          key: 0,
          value: 0 + 1,
          name: defaultNameProcess,
          startTime: null,
        },
      ]);
    }
  };
  const handleEdit = (scheduler) => {
    const newListScheduleTime = listScheduleTime.map((item) => {
      if (item.key === scheduler.key) {
        let newTime = new Date(scheduler.startTime || new Date()).setSeconds(0)
        let newTime1 = new Date(newTime || new Date()).setMilliseconds(0)
        return { ...item, name: scheduler.name, startTime: new Date(newTime1) };
      }
      return { ...item };
    });
    setListScheduleTime([...newListScheduleTime]);
  };
  ////////////tắt modla huy tạo process
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };
  const handleSubmit = () => {
    navigate('/process');
  };

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      team_id: teamInfo.listTeam[0]?.team_id,
      page: 1,
      limit: 10,
      id: Number.parseInt(params.user) || undefined,
    };
  }, [location.search, teamInfo]);
  const handleSaveScheduler = async()=>{
    try {
      const userCreate = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (userCreate === null || !userCreate.user_id) {
        navigate('/login');
        return;
      }

      const newProcessInfo = {
        userCreate_id: userCreate.user_id,
        checkUserStart: false,
        edit_date: new Date().toUTCString(),
        utc_local: new Date().getUTCHours() + 1,
        new_date: FormatDateCustom(new Date()),
      };
      const pathname = location.pathname;
      const listItemNoName = listScheduleTime.filter(item=>item.name === '' && item.startTime)
      if(listItemNoName.length === 0){
        const result = await processApi.createScheduler({...queryParams,userCreate_id:newProcessInfo.userCreate_id,new_date:newProcessInfo.new_date,trigger_type:0,listScheduleTime});
        if(result.success){
          toast.success('正常に登録されました。', {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          navigate(`/process`);
        }
      }else{
        toast.error('プロセス名称は空にできません。', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      
    }
  }


  useEffect(() => {
    if (teamInfo.listTeam[0]?.team_id) {
      const searchParam = queryString.parse(location.search);
      if (Object.keys(searchParam).length > 0 && searchParam.process_id) {
        (async () => {
          setSpinning(true);
          const result = await scheduleApi.getById(queryParams);
          setSpinning(false);
          if (result?.data) {
            setDefaultNameProcess(result.data.nameProcess)
            let newListScheduler = result.data.listScheduler.map((item, index) => {
              return { ...item, key: index, value: index + 1, name: item.pre_column1 || result.data.nameProcess, startTime: item.trigger_next_execution_start_time };
            }).filter(item=>new Date(item.startTime) >= new Date());  
            newListScheduler.sort((a,b)=>new Date(a.startTime) - new Date(b.startTime))
            if(newListScheduler.length === 0){
              newListScheduler = [{
                key: 0,
                value: 1 ,
                name: result.data.nameProcess,
                startTime: null,
              }]
            }
            setListScheduleTime([...newListScheduler].map((item,index)=>({...item,key: index})));
          }
          if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
            toast.warning(result.error, {
              toastId: 'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: false,
              theme: 'light',
              pauseOnHover: true,
              hideProgressBar: true,
            });
          }
        })();
      } else {
      }
    }
  }, [queryParams]);
  return (
    <>
      <Content>
        <Spin spinning={spinning} size='large' className='process_content_spining'>
          <div className='common__flex process__content process__content--admin'>
            <div className='process__content__top'>
              <div className='common__flex process__content__header'></div>
            </div>
            <div className='process__content__bottom'>
              <div className='process__content__bottom--box'>
                <div style={{ flex: 1 }}>
                  <div className={'process__new--content process__new--content--custom process__admin--custom'}>
                    <div className='process__new--intro common__process__listtaskbox'>
                      <h2 className='color__title__black mb--30 mt--30'>プロセス実行する日時を設定してください。(最大 20)</h2>
                      <div className='process__new--intro '>
                        <div className='common__process__listtask  mb--30'>{<SortListSchedule listScheduleTime={listScheduleTime} handleEdit={handleEdit} handleAddSchedule={handleAddSchedule} handleRemoveSchedule={handleRemoveSchedule} />}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='common__process__control'>
                  <div className='common__flex common__process__control--listbtn'>
                    <div className='common__flex common__process__control--btnL'>
                      <Button
                        className='bg__red common__process__control--btn'
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        キャンセル
                      </Button>
                      <ModalConfirm
                        open={openModal}
                        handleCancelConfirm={() => {
                          handleCancelConfirm();
                        }}
                        handleSubmit={() => {
                          handleSubmit();
                        }}
                        textConfirm={`現在設定中の内容は破棄されます。`}
                        textConfirmMid={`設定を終了してよろしいですか？`}
                      />
                    </div>
                    <div className='common__flex common__process__control--btnR'>
                      <Button onClick={handleSaveScheduler} className='bg__green common__process__control--btn'>保存して終了</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Content>
    </>
  );
}

export default ContainerScheduleTimer;

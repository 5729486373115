import React, { useEffect } from 'react';
import axios from 'axios';
import authenticationsApi from '../../api/authenticationsApi';
import { useNavigate } from 'react-router-dom';
import ResetPassword from '../../components/Auth/ResetPassword';
import { toast } from 'react-toastify';

PageResetPassword.propTypes = {};

function PageResetPassword(props) {
  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if(!userInfo){
      navigate('/login')
    }
  }, []);
  const handleResetPassword = async (values) => {
    const { oldPassword, newPassword, confirmNewPassword } = values;
    if (values) {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
        if (userInfo !== null) {
          const login_id = userInfo.login_id;
          const result = await authenticationsApi.ResetPassword({ login_id, oldPassword, newPassword, confirmNewPassword });
          if (result?.success) {
            toast.success(result.success, {
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            localStorage.setItem('accessToken', result.accessToken);
            navigate('/process');
          }
          if (result?.error && !toast.isActive({toastId:'toast_warning'})) {
            toast.warning(result.error, {
              toastId:'toast_warning',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
        }
      } catch (error) {
        navigate('/login');
      }
    }
  };
  return (
    <div>
      <ResetPassword onResetPassword={handleResetPassword} />
    </div>
  );
}

export default PageResetPassword;

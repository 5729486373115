import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authenticationsApi from '../../../../api/authenticationsApi';
import { COMMON_MESSAGES } from '../../../../commons/commonMessages/commonMessages';
import '../../css/style.css';
ProfileChangePassword.propTypes = {};

function ProfileChangePassword(props) {
  const [spinning, setSpinning] = useState(false);
  const [formReset] = Form.useForm();

  const onFinish = async (values) => {
    let flag = false
    const { oldPassword, newPassword, confirmNewPassword } = values;
    if (!oldPassword || oldPassword.length < 10) {
      formReset.setFields([
        {
          name: 'oldPassword',
          errors: ['現在のパスワードが正しくありません。'],
        },
      ]);
      flag = true
    }
    if (!newPassword || !newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/)) {
      formReset.setFields([
        {
          name: 'newPassword',
          errors: [`新しいパスワードが正しくありません。`, `アルファベット(a～zとA～Z)、数字(0～9),特殊文字(!@#$%^&.())のみを使用してください。`],
        },
      ]);
      flag = true
    }
    if (newPassword === oldPassword) {
      formReset.setFields([
        {
          name: 'newPassword',
          errors: [`「現在のパスワード」と「新しいパスワード」が同じです。`],
        },
      ]);
      flag = true
    }
    if (newPassword !== confirmNewPassword) {
      formReset.setFields([
        {
          name: 'confirmNewPassword',
          errors: ['新しいパスワードが一致しません。'],
        },
      ]);
      flag = true
    }

    if (values && !flag) {
      try {
        setSpinning(true);
        const userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
        if (userInfo !== null) {
          const login_id = userInfo.login_id;
          const result = await authenticationsApi.ResetPassword({ login_id, oldPassword, newPassword, confirmNewPassword });
          if (result?.success) {
            toast.success(result.success, {
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            formReset.resetFields();
          }
          if (result?.error) {
            formReset.setFields([
              {
                name: 'oldPassword',
                errors: ['現在のパスワードが正しくありません。'],
              },
            ]);
          }
        }
        setSpinning(false);
      } catch (error) {
        setSpinning(false);
        console.log({ error: error.message });
      }
    }
  };
  return (
    <div className='common__content flex__box flex__al__c flex__jus__sb flex__gap__cl--20'>
      <div className='Profile__change__password flex__box flex__jus__c'>
        <div className='Profile__change__password--form'>
          <Spin spinning={spinning} size='large' className='common__signin__loading'>
            <Form form={formReset} name='normal_login' onFinish={onFinish}>
              <Form.Item
                name='oldPassword'
                rules={[
                  {
                    required: true,
                    message: '現在のパスワードを入力してくさい。',
                  },
                ]}
              >
                <Input.Password size='large' autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='現在のパスワード' />
              </Form.Item>
              <Form.Item
                name='newPassword'
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: '新しいパスワードを入力してください。',
                  },
                ]}
                
                
              >
                <Input.Password size='large' autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='新しいパスワード' />
              </Form.Item>
              <p>英大文字、英小文字、数字、特殊記号 を混在させた10文字以上の文字列</p>
              <Form.Item
                name='confirmNewPassword'
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: '新しいパスワードを再度入力してください。',
                  },
                ]}
              >
                <Input.Password size='large' autoComplete='true' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='新しいパスワード（確認）' />
              </Form.Item>
              <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='primary' htmlType='submit' className='i3link__btn login-form-button'>
                  変更
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default ProfileChangePassword;

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ProfileChangePassword from './ProfileChangePassword';
import { useLocation, useNavigate } from 'react-router-dom';

ProfileChangePasswordContainer.propTypes = {
    
};

function ProfileChangePasswordContainer(props) {
    const location = useLocation();
    const navigation = useNavigate()
    
    const queryParams = useMemo(()=>{
     
    },[location.search])
  
    useEffect(() => {
     
    }, [queryParams,location.pathname]);
  
    const handlePagination = (values)=>{
     
    }
  
    return (
        <ProfileChangePassword />
    );
}

export default ProfileChangePasswordContainer;
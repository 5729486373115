import { Button, Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { processState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';
import queryString from 'query-string';

StepCreateNotification.propTypes = {};

function StepCreateNotification(props) {
  const { nextStep, prevStep, flag } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const processInfo = useSelector(processState);
  const navigate = useNavigate();
  const [step, setStep] = useState(() => {
    return flag ? 0 : 0;
  });
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(() => {
    let check = false;
    return check;
  });
  const [listTask, setListTask] = useState(() => {
    return processInfo.taskList || [];
  });

  const handleNotification = (item)=>{
    const newTaskInfo = { ...item };
      const action = processSlice.actions.editTask(newTaskInfo);
      dispatch(action);
  }
  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <div className='process__new--intro common__process__listtaskbox'>
            <div className='process__new--intro '>
              <h2 className='color__title__black mt--30'>担当者による完了報告が不要な場合は、無効にしてください。</h2>
              <h2 className='mb--30 color__title__black'>無効にした場合は、担当者への指示通知を行った後に自動的に完了されます。</h2>
              <div className='common__process__listtask  mb--30'>
                <div className='common__flex process__listtask'>
                  {listTask.length > 0 &&
                    listTask.map((item) => {
                      return (
                        <div className='common__flex process__task--item' key={item.id}>
                          <div className='common__flex process__task--itemL'>
                            <div className='process__task--item--number'>
                              <p>タスク</p>
                              <p>{item.index + 1}</p>
                            </div>
                            <div className='common__flex process__task--item--name'>
                              <div className='common__flex process__task--item--input'>{item.task_name}</div>
                            </div>
                          </div>
                          <div className='process__task--itemR'>
                            <div className='process__task--itemR--btn common__flex'  >
                              <Switch checked={+item.pre_column1 === 1 ? false : true} onChange={(value) => {
                                const newIteam = {...item,pre_column1:value ? null : 1}
                                handleNotification(newIteam)
                              }} />
                            </div>
                          </div>
                        </div>
                      ); 
                    })}
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  ////////// sử lí trước khi quay về bước trước đó theo
  const handlePrevStep =  () => {
    prevStep();
    return;
  };
  ////////// sử lí trước khi chuyển sang bước tiếp theo
  const handleNextStep =  () => {
    nextStep();
    return;
  };

  /////////check điều kiện của nút next
  const checkDisableBtn = () => {
   return false
  
  };

  ////////////////
  useEffect(() => {
    setListTask(() => {
      return processInfo.tasks || [];
    });
    if(step === 0){
      setShowModal(()=>{
        let check = false;
          const searchParam = queryString.parse(location.search);
          if (searchParam.template_id && processInfo.pre_column6) {
            check = true;
          }
          return check;
      })
    }
  }, [processInfo]);

  ////////////huy create process
  const handleCancelCreateProcess = () => {
    const searchParam = queryString.parse(location.search);
    const action = processSlice.actions.newProcess({});
    dispatch(action);
    if(searchParam.process_id){
      navigate({
        pathname: `/process`,
      });
    }else{
      navigate({
        pathname: `/template`,
      });
    }
    return;
    // localStorage.removeItem('process');
    // const action = processSlice.actions.newProcess({});
    // dispatch(action);
    // setOpenModal(false);
    // navigate('/process');
    // return;
  };
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className='process__content__bottom--box'>
        <div className='process__new--content process__new--content--custom'>{renderStep(step)}</div>
        <div className='common__process__control'>
          <div className='common__flex common__process__control--listbtn'>
            <div className='common__flex common__process__control--btnL'>
              <>
                <Button
                  className={step === 0 ? `bg__red common__process__control--btn` : `bg__blue common__process__control--btn`}
                  onClick={() => {
                    if (step === 1) {
                      setStep(0);
                    }
                    if (step === 0) {
                      setOpenModal(true);
                    }
                  }}
                >
                  {step === 0 ? 'キャンセル' : ' 前に戻る'}
                </Button>
                <ModalConfirm open={openModal} handleCancelConfirm={handleCancelConfirm} handleSubmit={handleCancelCreateProcess} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />
              </>
            </div>
            <div className='common__flex common__process__control--btnR'>
              {step === 0 && (
                <>
                  <Button disabled={false ? true : false} className={false ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__blue common__process__control--btn'} onClick={handlePrevStep}>
                    前に戻る
                  </Button>
                  <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                    次へ進む
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className=''
        maskClosable={false}
        closable={false}
        title=''
        open={showModal}
        forceRender
        footer={[
          <div key={0} className='modle__user__btn flex__box flex__jus__c'>
            <Button
              className='i3link__btn bg__blue'
              onClick={() => {
                setShowModal(false);
                const action = processSlice.actions.disableNotification({nameStep:'pre_column6'});
                dispatch(action);
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        <p style={{ marginBottom: 0 }}>{processInfo.pre_column6}</p>
      </Modal>
    </>
  );
}

export default StepCreateNotification;

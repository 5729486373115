import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Image, Input, Modal, Radio, Row, Select, Spin, Table, TimePicker, Tooltip } from 'antd';
import queryString from 'query-string';
import logo from '../../assets/logo.jpg';
import './Css/style.css';
import { trn_tasksApi } from '../../api/trn_tasksApi';
import { toast, ToastContainer } from 'react-toastify';
import { formatDateJP } from '../../commons/commonFunctions/FormatDate';
import { convertToFull, numberDayToDate } from '../../commons/commonFunctions/CommonFunction';
import moment from 'moment';
import { FaBell, FaCalendarAlt, FaCaretDown, FaList, FaRegTimesCircle, FaStop } from 'react-icons/fa';
import RemindTaskDuedate from './components/RemindTaskDuedate';

TaskDueDate.propTypes = {};

const calculateTimeDifference = (startTime, endTime) => {
  const dateDiff = moment.duration(moment(endTime).diff(moment(startTime)));
  // Trực tiếp lấy giá trị từ duration thay vì _data
  const timeDiff = {
    day: numberDayToDate(startTime, endTime),
    hour: dateDiff.hours(),
    minutes: dateDiff.minutes(),
  };

  return timeDiff;
};
const generateNextDatesTimes = (value) => {
  
  const listNextRemind = [];
  if (value.notification_loop_num && value.dateStart) {
    listNextRemind.push({ no: 0, date: value.dateStart, display: formatDateJP(new Date(value.dateStart._d)) });
    for (let index = 1; index < value.notification_loop_num; index++) {
      const element = value;
      if (+element.notification_loop_interval === 3) {
        const newDate = moment(listNextRemind[listNextRemind.length - 1]).add(value.notification_loop_value, 'days');
        listNextRemind.push({ no: index, date: newDate, display: formatDateJP(new Date(newDate._i.date)) });
        continue;
      }
      if (+element.notification_loop_interval === 4) {
        const newDate = moment(listNextRemind[listNextRemind.length - 1]).add(value.notification_loop_value, 'hours');
        listNextRemind.push({ no: index, date: newDate, display: formatDateJP(new Date(newDate._i.date)) });
        continue;
      }
      if (+element.notification_loop_interval === 5) {
        const newDate = moment(listNextRemind[listNextRemind.length - 1]).add(value.notification_loop_value, 'minutes');
        listNextRemind.push({ no: index, date: newDate, display: formatDateJP(new Date(newDate._i.date)) });
        continue;
      }
    }
  }
  return listNextRemind;
};

function TaskDueDate(props) {
  const [process, setProcess] = useState(null);
  const [data, setData] = useState([]);
  const [remindData, setRemindData] = useState([]);
  const [remindDate, setRemindDate] = useState([]);
  const [remindSetting, setRemindSetting] = useState({});
  const [submitRemindSetting, setSubmitRemindSetting] = useState([]);
  const [spin, setSpin] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openDone, setOpenDone] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openNotUser, setOpenNotUser] = useState(false);
  const [openNotFound, setOpenNotFound] = useState(false);
  const [openRemindSetting, setOpenRemindSetting] = useState(false);
  const [openRemindDate, setOpenRemindDate] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [fontSize, setFontSize] = useState(0);
  const [errorTasks, setErrorTasks] = useState([]);
  const [lstLoopNum, setLstLoopNum] = useState([1]);
  const [lstRemindDate, setLstRemindDate] = useState([]);
  const location = useLocation();
  const navigation = useNavigate();
  const teamInfo = localStorage.getItem('teamInfo') ? JSON.parse(localStorage.getItem('teamInfo')) : [];

  const handleSubmit = async () => {
    try {
      setSpin(true);

      if (errorTasks && errorTasks.length > 0) {
        for (let index = errorTasks.length - 1; index > 0; index--) {
          const element = errorTasks[index];
          toast.error(`タスク${element}の設定情報が不足しています。`, {
            toastId: `toastId_err${index}`,
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });
        }
        setSpin(false);
      } else {
        const result = await trn_tasksApi.updateTaskDueDateRespond(+process.trn_manage_id, {data: data, remind: submitRemindSetting});
        if (result.success) {
          toast.success('正常に登録されました。', {
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });
          setSpin(false);
          setOpenNew(false);
          setOpenDone(true);
        } else {
          if (result.error_no === 1 && !toast.isActive({ toastId: 'toastId_err' })) {
            setSpin(false);
            toast.error(result.error, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: true,
              hideProgressBar: true,
            });
            setOpenNew(false);
            setOpenNotUser(true);
          } else {
            setSpin(false);
            toast.error(result.error, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: true,
              hideProgressBar: true,
            });
            setOpenNew(false);
            setOpenError(true);
          }
        }
      }
    } catch (error) {
      setSpin(false);
      console.log(error);
    }
  };
  const handleRemindSetting = async (value) => {
    const newRemindSetting = { ...value };
    const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    try {
      const lstRemindDate = generateNextDatesTimes(value);
      const insertData = {
        ...newRemindSetting,
        type:newRemindSetting.optionRemind,
        user_id: user.user_id,
        notification_next_time: lstRemindDate && lstRemindDate.length > 0 ? lstRemindDate[0].date : null,
        edit_date: moment().toJSON(),
      };

      const currRemindSetting = (submitRemindSetting  && submitRemindSetting.length > 0) ? [...submitRemindSetting] : [];
      const filterSetting = currRemindSetting.filter(x => 
        x.task_id == insertData.task_id
        && x.notification_type == insertData.notification_type
      );
      if(filterSetting && filterSetting.length > 0){
        // filterSetting[0] = {...insertData};
        // filterSetting[0].dateStart = insertData.dateStart;
        filterSetting[0].type = insertData.type;
        filterSetting[0].remind_time = insertData.dateStart;
        filterSetting[0].dateStart = insertData.dateStart;
        filterSetting[0].notification_start_type = insertData.notification_start_type;
        filterSetting[0].notification_start_day_value = insertData.notification_start_day_value;
        filterSetting[0].notification_start_hour_value = insertData.notification_start_hour_value;
        filterSetting[0].notification_start_minutes_value = insertData.notification_start_minutes_value;
        filterSetting[0].notification_loop_value = insertData.notification_loop_value;
        filterSetting[0].notification_loop_interval = insertData.notification_loop_interval;
        filterSetting[0].notification_loop_num = insertData.notification_loop_num;
        filterSetting[0].notification_next_time = insertData.notification_next_time;
        filterSetting[0].optionRemind = insertData.optionRemind;
      }
      else{
        currRemindSetting.push({...insertData});
      }
      setSubmitRemindSetting(currRemindSetting);
      setOpenRemindSetting(false);
      //console.log(submitRemindSetting);

    } catch (error) {
      setSpin(false);
      console.log(error);
    }
  };
  const handleRemindTypeChange = (value) => {
    const setting = { ...remindSetting };
    setting.type = value;

    setRemindSetting(setting);
  };

  const handleRemindTimeChange = (date, isDate) => {
    const setting = { ...remindSetting };
    const dateSetting = moment(setting.setting_time);
    const dateInput = moment(date).seconds(0).milliseconds(0);
    const value = dateInput?.toDate();
    if (dateInput) {
      let isPastTime = false;
      if (dateInput > dateSetting && !toast.isActive({ toastId: 'toastId_err' })) {
        isPastTime = true;
      }

      let changeValue = setting.remind_time ? new Date(setting.remind_time) : new Date(setting.setting_time);
      if (isDate) {
        changeValue.setFullYear(value.getFullYear());
        changeValue.setMonth(value.getMonth());
        changeValue.setDate(value.getDate());
      } else {
        changeValue.setHours(isPastTime ? dateSetting.getHours() : value.getHours());
        changeValue.setMinutes(isPastTime ? dateSetting.getMinutes() : value.getMinutes());
        changeValue.setSeconds(0);
      }
      setting.remind_time = changeValue.toJSON();
    }

    const timeDiff = calculateTimeDifference(setting.remind_time, setting.setting_time);

    setting.notification_start_day_value = timeDiff.day;
    setting.notification_start_hour_value = timeDiff.hour;
    setting.notification_start_minutes_value = timeDiff.minutes;

    const notification_loop_value = setting.notification_loop_value;
    const notification_loop_interval = setting.notification_loop_interval;

    let notification_loop_num_cal = 1;
    if (setting.notification_loop_value > 0) {
      const notification_start_value = 0 + (notification_loop_interval == 3 ? setting.notification_start_day_value : 0) + (notification_loop_interval == 4 ? setting.notification_start_day_value * 24 + setting.notification_start_hour_value : 0) + (notification_loop_interval == 5 ? setting.notification_start_day_value * 24 * 60 + setting.notification_start_hour_value * 60 + setting.notification_start_minutes_value : 0);
      notification_loop_num_cal = Math.trunc(notification_start_value / notification_loop_value);
      if (notification_loop_num_cal > 10) {
        notification_loop_num_cal = 10;
      }
    }
    let loopNum = [];
    for (let i = 1; i <= notification_loop_num_cal; i++) {
      loopNum.push(i);
    }
    setLstLoopNum(loopNum);

    setting.notification_loop_value = notification_loop_value;
    setting.notification_loop_interval = notification_loop_interval;
    setting.notification_loop_num = 1;
    setRemindSetting(setting);

    setLstRemindDate([
      {
        no: 1,
        date: moment(setting.remind_time).toDate(),
        display: formatDateJP(moment(setting.remind_time).toDate()),
      },
    ]);
  };

  const handleRemindLoopChange = (e, isValue) => {
    const setting = { ...remindSetting };
    const notification_loop_value = isValue ? e.target.value : setting.notification_loop_value;
    const notification_loop_interval = !isValue ? e : setting.notification_loop_interval;

    let notification_loop_num_cal = 1;
    if (setting.notification_loop_value > 0) {
      const notification_start_value = 0 + (notification_loop_interval == 3 ? setting.notification_start_day_value : 0) + (notification_loop_interval == 4 ? setting.notification_start_day_value * 24 + setting.notification_start_hour_value : 0) + (notification_loop_interval == 5 ? setting.notification_start_day_value * 24 * 60 + setting.notification_start_hour_value * 60 + setting.notification_start_minutes_value : 0);
      notification_loop_num_cal = Math.trunc(notification_start_value / notification_loop_value);
      if (notification_loop_num_cal > 10) {
        notification_loop_num_cal = 10;
      }
    }
    let loopNum = [];
    for (let i = 1; i <= notification_loop_num_cal; i++) {
      loopNum.push(i);
    }
    setLstLoopNum(loopNum);

    setting.notification_loop_value = notification_loop_value;
    setting.notification_loop_interval = notification_loop_interval;
    setting.notification_loop_num = 1;
    setRemindSetting(setting);

    setLstRemindDate([
      {
        no: 1,
        date: moment(setting.remind_time).toDate(),
        display: formatDateJP(moment(setting.remind_time).toDate()),
      },
    ]);
  };

  const handleRemindLoopNumChange = (e) => {
    const setting = { ...remindSetting };
    const interval = setting.notification_loop_interval == 3 ? 'd' : setting.notification_loop_interval == 4 ? 'h' : 'm';
    const notification_loop_num = e ? e : setting.notification_loop_num;
    let remind_time = moment(setting.remind_time);
    let remindDates = [
      {
        no: 1,
        date: remind_time.toDate(),
        display: formatDateJP(remind_time.toDate()),
      },
    ];
    for (let i = 1; i < notification_loop_num; i++) {
      const date = remind_time.add(setting.notification_loop_value, interval).toDate();
      remindDates.push({
        no: i + 1,
        date: date,
        display: formatDateJP(date),
      });
    }
    setLstRemindDate(remindDates);

    setting.notification_loop_num = notification_loop_num;
    //console.log({setting});
    setRemindSetting(setting);
  };

  /////////////////////////////////////////
  const [openSetType, setOpenSetType] = useState(false);
  const getRemindData = () => {
    // const remindKey = type === 'start' ? 'remindStart' : 'remindEnd';
    // const remindData = taskInfo?.[remindKey];
    const newRemindData = { ...remindSetting };

    // Dữ liệu mặc định
    const defaultRemindData = {
      dateStart: null,
      notification_loop_value: null,
      notification_loop_num: null,
      notification_loop_interval: '5',
      optionRemind: 0,
      notification_start_type: openSetType ? 5 : 6,
    };
    const submitRemind = submitRemindSetting.filter((x) => +x.task_id == +remindSetting.task_id && +x.notification_type == +remindSetting.notification_type);
    if (submitRemind && submitRemind.length > 0) {
      newRemindData.dateStart = submitRemind[0].dateStart;
    }
    else{
      const remind = remindData.filter((x) => +x.task_id == +remindSetting.task_id && +x.notification_type == +remindSetting.notification_type );
      if (remind && remind.length > 0) {
        const date = remindDate.filter((x) => +x.task_id == +remindSetting.task_id && +x.notification_type == +remindSetting.notification_type);
        newRemindData.dateStart = date && date.length > 0 ? date[0].notification_next_time : null;
      }
    }

    // Nếu có thông tin remind (remindStart hoặc remindEnd), cập nhật dateStart bằng cách gọi convertDate_v2
    if (newRemindData) {
      const intervalMultiplier = (() => {
        switch (+newRemindData.notification_loop_interval) {
          case 3:
            return 1440; // 3 -> phút trong một ngày
          case 4:
            return 60; // 4 -> phút trong một giờ
          default:
            return 1; // Nếu không phải 3 hoặc 4 thì dùng mặc định
        }
      })();
      const converMinutesToNumberOfInterval = +newRemindData.notification_loop_value / intervalMultiplier;
      return { 
        ...newRemindData, 
        dateStart: newRemindData.dateStart ? moment(newRemindData.dateStart): null, 
        optionRemind: +newRemindData.type, 
        notification_loop_interval: +newRemindData.notification_loop_interval ? `${newRemindData.notification_loop_interval}` : '5' ,
        notification_loop_value: converMinutesToNumberOfInterval < 1 ? '0' : converMinutesToNumberOfInterval 
      };
    }

    return defaultRemindData;
  };
  /////////////////////////////////////////

  const handleChange = (task_no, e, isStart, isDate) => {
    if (e) {
      let dataInclude = data.filter((x) => x.task_no === task_no);
      if (dataInclude && dataInclude.length > 0) {
        let dataExclude = data.filter((x) => x.task_no !== task_no);
        const dateNow = moment().seconds(0).milliseconds(0);
        const dateInput = moment(e._d).seconds(0).milliseconds(0);
        const value = dateInput?.toDate();
        if (isStart) {
          if (dateInput) {
            if (dateInput < dateNow && !toast.isActive({ toastId: 'toastId_err' })) {
              toast.warning('現在日時より前の日時が入力されています。ご確認ください。', {
                toastId: 'toastId_err',
                position: 'top-right',
                autoClose: 5000,
                closeOnClick: true,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
            }
            let changeValue = dataInclude[0].task_start_limit ? new Date(dataInclude[0].task_start_limit) : new Date();
            if (isDate) {
              changeValue.setFullYear(value.getFullYear());
              changeValue.setMonth(value.getMonth());
              changeValue.setDate(value.getDate());
            } else {
              changeValue.setHours(value.getHours());
              changeValue.setMinutes(value.getMinutes());
              changeValue.setSeconds(0);
            }
            dataInclude[0].task_start_limit = changeValue.toJSON();
            dataInclude[0].update_status = '1';
          }
        } else {
          if (dateInput) {
            if (dateInput < dateNow && !toast.isActive({ toastId: 'toastId_err' })) {
              toast.warning('現在日時より前の日時が入力されています。ご確認ください。', {
                toastId: 'toastId_err',
                position: 'top-right',
                autoClose: 5000,
                closeOnClick: true,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
            }
            let changeValue = dataInclude[0].task_end_limit ? new Date(dataInclude[0].task_end_limit) : new Date();
            if (isDate) {
              changeValue.setFullYear(value.getFullYear());
              changeValue.setMonth(value.getMonth());
              changeValue.setDate(value.getDate());
              // changeValue.setHours(value.getHours());
              // changeValue.setMinutes(value.getMinutes());
            } else {
              changeValue.setHours(value.getHours());
              changeValue.setMinutes(value.getMinutes());
              changeValue.setSeconds(0);
            }
            dataInclude[0].task_end_limit = changeValue.toJSON();
            dataInclude[0].update_status = '1';
          }
        }
        let dataMerge = dataExclude.concat(dataInclude);
        dataMerge.sort(function (a, b) {
          return a.task_no - b.task_no;
        });
        setData(dataMerge);
      }
    } else {
      let dataInclude = data.filter((x) => x.task_no === task_no);
      if (dataInclude && dataInclude.length > 0) {
        let dataExclude = data.filter((x) => x.task_no !== task_no);

        if (isStart) {
          dataInclude[0].task_start_limit = null;
          dataInclude[0].update_status = '1';
        } else {
          dataInclude[0].task_end_limit = null;
          dataInclude[0].update_status = '1';
        }
        let dataMerge = dataExclude.concat(dataInclude);
        dataMerge.sort(function (a, b) {
          return a.task_no - b.task_no;
        });
        setData(dataMerge);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      const accessToken = localStorage.getItem('accessToken');
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (!accessToken || !user) {
        navigation('/login');
        return;
      }
      var filter = queryString.parse(location.search);
      if (filter.trn_manage_id || filter.task_id || filter.team_id) {
        try {
          const param = { trn_manage_id: filter.trn_manage_id, user_id: user.user_id };
          const result = await trn_tasksApi.getTaskDueDate(param);
          if (result.data) {
            if (result.data.data.length > 0) {
              setData(result.data.data);
              setRemindData(result.remindData);
              setRemindDate(result.remindDate);
              const search_status = result.data.search_status;
              const process_name = teamInfo.length <= 0 || teamInfo[0].team_id != result.data.data[0].team_id ? '' : result.data.data[0].process_name;
              const process_start_achievement = teamInfo.length <= 0 || teamInfo[0].team_id != result.data.data[0].team_id ? '' : formatDateJP(new Date(result.data.data[0].process_start_achievement));
              if (search_status === 0) {
                setProcess({
                  trn_manage_id: result.data.data[0].trn_manage_id,
                  process_name: process_name,
                  process_start_achievement: process_start_achievement,
                });
                setOpenNew(true);
              } else if (search_status === -1) {
                setProcess({});
                setOpenNotUser(true);
              } else if (search_status === -2) {
                setProcess({});
                setOpenNotFound(true);
              }
            } else {
              setData([]);
              setProcess({});
              setOpenNotUser(true);
            }
          } else {
            setData([]);
            setProcess({});
            setOpenNotUser(true);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setData([]);
        setProcess({});
        setOpenNotUser(true);
        window.close();
      }
    }
    fetchData();
  }, [location.pathname]);

  useEffect(() => {
    if(openRemindSetting){
      async function fetchData() {
        const accessToken = localStorage.getItem('accessToken');
        const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
        if (!accessToken || !user) {
          navigation('/login');
          return;
        }
        var filter = queryString.parse(location.search);
        if (filter.trn_manage_id || filter.task_id || filter.team_id) {
          try {
            const param = { trn_manage_id: filter.trn_manage_id, user_id: user.user_id };
            const result = await trn_tasksApi.getTaskDueDate(param);
            if (result.data) {
              if (result.data.data.length > 0) {
                const dueDateData = [...result.data.data];
  
                if(dueDateData && dueDateData.length > 0){
                  for(let i = 0; i < dueDateData.length; i++){
                    const item = dueDateData[i];
                    const oldData = data.filter(x => x.task_id == item.task_id)
                    if(oldData && oldData.length > 0){
                      if(item.task_no > 1 && item.task_start_limit_mode_display == 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4)){
                        item.task_start_limit = oldData[0].task_start_limit;
                      }
                      if(item.task_end_limit_mode_display == 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4)){
                        item.task_end_limit = oldData[0].task_end_limit;
                      }
                      item.update_status = oldData[0].update_status;
                    }
                    const newSubmitSettingStart = submitRemindSetting.filter(x => x.task_id == item.task_id && +x.notification_type == 4)
                    if(newSubmitSettingStart && newSubmitSettingStart.length > 0){
                      if(newSubmitSettingStart[0].dateStart == null){
                        item.is_remind_start_admin = 0;
                        item.is_remind_start_user = 0;
                      }
                      else{
                        item.is_remind_start_admin = +newSubmitSettingStart[0].type == 1 ? 1 : 0;
                        item.is_remind_start_user = +newSubmitSettingStart[0].type == 0 ? 1 : 0;
                      }
                    }          
                    const newSubmitSettingEnd = submitRemindSetting.filter(x => x.task_id == item.task_id && +x.notification_type == 7)
                    if(newSubmitSettingEnd && newSubmitSettingEnd.length > 0){
                      if(newSubmitSettingEnd[0].dateStart == null){
                        item.is_remind_end_admin = 0;
                        item.is_remind_end_user = 0;
                      }
                      else{
                        item.is_remind_end_admin = +newSubmitSettingEnd[0].type == 1 ? 1 : 0;
                        item.is_remind_end_user = +newSubmitSettingEnd[0].type == 0 ? 1 : 0;
                      }
                    }
                  }
                }
  
                setData(dueDateData);
                setRemindData(result.remindData);
                setRemindDate(result.remindDate);
                const search_status = result.data.search_status;
                const process_name = teamInfo.length <= 0 || teamInfo[0].team_id != result.data.data[0].team_id ? '' : result.data.data[0].process_name;
                const process_start_achievement = teamInfo.length <= 0 || teamInfo[0].team_id != result.data.data[0].team_id ? '' : formatDateJP(new Date(result.data.data[0].process_start_achievement));
                if (search_status === 0) {
                  setProcess({
                    trn_manage_id: result.data.data[0].trn_manage_id,
                    process_name: process_name,
                    process_start_achievement: process_start_achievement,
                  });
                  setOpenNew(true);
                } else if (search_status === -1) {
                  setProcess({});
                  setOpenNotUser(true);
                } else if (search_status === -2) {
                  setProcess({});
                  setOpenNotFound(true);
                }
              } else {
                setData([]);
                setProcess({});
                setOpenNotUser(true);
              }
            } else {
              setData([]);
              setProcess({});
              setOpenNotUser(true);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setData([]);
          setProcess({});
          setOpenNotUser(true);
          window.close();
        }
      }
      fetchData();
    }
    else{      
      const dueDateData = [...data];  
      if(dueDateData && dueDateData.length > 0){
        for(let i = 0; i < dueDateData.length; i++){
          const item = dueDateData[i];
          const newSubmitSettingStart = submitRemindSetting.filter(x => x.task_id == item.task_id && +x.notification_type == 4)
          if(newSubmitSettingStart && newSubmitSettingStart.length > 0){
            if(newSubmitSettingStart[0].dateStart == null){
              item.is_remind_start_admin = 0;
              item.is_remind_start_user = 0;
            }
            else{
              item.is_remind_start_admin = +newSubmitSettingStart[0].type == 1 ? 1 : 0;
              item.is_remind_start_user = +newSubmitSettingStart[0].type == 0 ? 1 : 0;
            }
          }          
          const newSubmitSettingEnd = submitRemindSetting.filter(x => x.task_id == item.task_id && +x.notification_type == 7)
          if(newSubmitSettingEnd && newSubmitSettingEnd.length > 0){
            if(newSubmitSettingEnd[0].dateStart == null){
              item.is_remind_end_admin = 0;
              item.is_remind_end_user = 0;
            }
            else{
              item.is_remind_end_admin = +newSubmitSettingEnd[0].type == 1 ? 1 : 0;
              item.is_remind_end_user = +newSubmitSettingEnd[0].type == 0 ? 1 : 0;
            }
          }
        }
      }
      setData(dueDateData);
    }
  }, [openRemindSetting]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let errors = [];
    for (let i = 0; i < data.length; i++) {
      let task = data[i];
      let check_start = task.task_no == 1 || task.task_start_limit_mode != 1 ? !task.task_end_limit && task.task_end_limit_mode == 1 : !task.task_start_limit && task.task_start_limit_mode == 1;
      let check_end = task.task_no > 1 && task.task_end_limit_mode != 1 ? !task.task_start_limit && task.task_start_limit_mode == 1 : !task.task_end_limit && task.task_end_limit_mode == 1;
      if ((check_start && !check_end) || (!check_start && check_end)) {
        errors.push(task.task_no);
      }
      // else{
      //   if(task.task_start_limit && task.task_end_limit && task.task_start_limit > task.task_end_limit){
      //     errors.push(task.task_no);
      //   }
      // }
    }
    setErrorTasks(errors);
  }, [data]);

  useEffect(() => {
    setFontSize(windowWidth / 40);
  }, [windowWidth, windowHeight]);
  return (
    <div className='App'>
      <ToastContainer />
      <Modal
        className='task_duedate'
        open={openNew}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              <span className='common_text-clamp common_text-clamp--2' style={{ lineHeight: `${fontSize / 1.2}px` }}>
                {' '}
                プロセス名：{process ? process.process_name : ''}
              </span>
              {/* <Tooltip color={'#00a199'} placement='topLeft' title={process?.process_name} arrowPointAtCenter>
                <div className='flex__box'> <span style={{width:"max-content"}}>プロセス名：</span><span className='common_text-clamp common_text-clamp--1' style={{marginBottom:0,flex:1,overflow:'hidden'}}>{process ? process.process_name : ''}</span></div>
            </Tooltip> */}
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        //height={"80%"}
        width={'100%'}
        header={''}
        footer={null}
      >
        <Spin tip='ローディング．．．' spinning={spin}>
          <div className='task_content'>
            <Row style={{ padding: '20px 0px' }}>
              <Col span={2}></Col>
              <Col span={20}>
                <div className='text-clamp text-clamp--2'>
                  <p style={{ color: '#fff', textAlign: 'center', fontSize: `${fontSize / 1.5}px` }}>
                    開始時設定を行ってください。
                    <br />
                    変更はタスクが開始されるまで行うことができます。
                  </p>
                  <Row>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <div style={{ color: '#fff', textAlign: 'left', fontSize: `${fontSize / 2.5}px` }}>
                        ※遅延通知が既に送信されている場合は、変更後の日時で再送信されます。
                        <br />
                        ※変更されたことをお知らせする通知は送信されませんので、ご注意ください。
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row style={{ paddingBottom: 10, border: '3px solid', borderColor: 'white', borderStyle: '' }}>
              <Col span={2}></Col>
              <Col span={20}>
                <div className='flex__box flex__jus__sb flex__fd__cl flex__gap__r--20'>
                  <Row>
                    <Col span={4}></Col>
                    <Col span={10}>
                      <div style={{ textAlign: 'center', color: 'white', fontSize: `${fontSize / 2}px` }}>開始予定日時</div>
                    </Col>
                    <Col span={10}>
                      <div style={{ textAlign: 'center', color: 'white', fontSize: `${fontSize / 2}px` }}>終了予定日時</div>
                    </Col>
                  </Row>
                  {data.length > 0 &&
                    data.map((item, index) => {
                      return (
                        <div key={index}>
                          <Row gutter={[10, 10, 10, 10]}>
                            <Col span={4}>
                              <div className='flex__box flex__jus__sb flex--1 process__task__box'>
                                <div className='process__task__box--number' style={{ width: '100%', textAlign: 'left', fontSize: `${fontSize / 3}px`, flex: 1 }}>
                                  タスク{convertToFull(item.task_no)}
                                  <Tooltip color={'#00a199'} placement='topLeft' title={item.task_name} arrowPointAtCenter>
                                    <div className='flex__box'>
                                      {' '}
                                      <span style={{ width: 'max-content' }}>タスク名：</span>
                                      <span className='common_text-clamp common_text-clamp--1' style={{ marginBottom: 0, flex: 1, overflow: 'hidden' }}>
                                        {item ? item.task_name : ''}
                                      </span>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </Col>
                            <Col span={10}>
                              <div className='flex__box flex__jus__sb flex--1 process__task__box'>
                                <div className='process__task__box--nametask flex--1'>
                                  {item.task_no == 1 && (
                                    <p style={{ textAlign: 'left', fontSize: `${fontSize / 3}px`, margin: 0 }}>
                                      プロセス開始日時
                                      <br />
                                      {process ? process.process_start_achievement + ' にタスクが開始' : ''}
                                    </p>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 0 && (
                                    <p style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      設定なし
                                      <br />　
                                    </p>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4) && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      <p style={{ margin: 0 }}> 開始時設定 </p>
                                      <Row>
                                        <Col span={22}>
                                          <DatePicker
                                            value={item.task_start_limit !== null ? moment(item.task_start_limit) : ''}
                                            allowClear={true}
                                            suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                                            format={'yyyy 年 MM 月 DD'}
                                            style={{ fontSize: `${fontSize / 2.5}px` }}
                                            onChange={(e) => {
                                              handleChange(item.task_no, e, true, true);
                                            }}
                                          />
                                          <TimePicker
                                            showNow={false}
                                            value={item.task_start_limit !== null ? moment(item.task_start_limit) : ''}
                                            allowClear={false}
                                            suffixIcon={<FaCaretDown size={20} color='#000' />}
                                            format={'HH：mm'}
                                            style={{ fontWeight: 600, fontSize: `${fontSize / 2.5}px` }}
                                            onSelect={(e) => {
                                              handleChange(item.task_no, e, true, false);
                                            }}
                                          />
                                          　にタスクが開始
                                        </Col>
                                        <Col span={2}>
                                          <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {item.task_start_limit && (
                                              <svg
                                                style={{ cursor: 'pointer' }}
                                                width='25'
                                                height='25'
                                                viewBox='0 0 448 512'
                                                xmlns='http://www.w3.org/2000/svg'
                                                onClick={() => {
                                                  setOpenRemindSetting(true);
                                                  setOpenSetType(true);
                                                  let remind = [];
                                                  let date = null;
                                                  if(submitRemindSetting && submitRemindSetting.length > 0){
                                                    remind = submitRemindSetting.filter((x) => x.task_id == item.task_id && +x.notification_type == 4);
                                                    if(remind.length <= 0){
                                                      remind = remindData.filter((x) => x.task_id == item.task_id && +x.notification_type == 4 && x.actor_type == (item.is_remind_start_admin > 0 ? 0 : 1));
                                                      const filterDate = remindDate.filter((x) => x.task_id == item.task_id && +x.notification_type == 4 && x.actor_type == (item.is_remind_start_admin > 0 ? 0 : 1));
                                                      date = (filterDate &&  filterDate.length > 0) ? filterDate[0].notification_next_time : null;
                                                    }
                                                    else{
                                                      // dateStart = remind[0].dateStart;
                                                      date = remind[0].dateStart;
                                                    }
                                                  }else{
                                                    remind = remindData.filter((x) => x.task_id == item.task_id && +x.notification_type == 4 && x.actor_type == (item.is_remind_start_admin > 0 ? 0 : 1));
                                                    const filterDate = remindDate.filter((x) => x.task_id == item.task_id && +x.notification_type == 4 && x.actor_type == (item.is_remind_start_admin > 0 ? 0 : 1));
                                                    date = (filterDate &&  filterDate.length > 0) ? filterDate[0].notification_next_time : null;
                                                  }
                                                  const newDateRemind = moment(date);
                                                  const duration = moment.duration({
                                                    years: 0, // Nếu có giá trị từ remind thì thay thế
                                                    months: 0, // Nếu có giá trị từ remind thì thay thế
                                                    days: +remind[0]?.notification_start_day_value || 0,
                                                    hours: +remind[0]?.notification_start_hour_value || 0,
                                                    minutes: +remind[0]?.notification_start_minutes_value || 0,
                                                  });
                                                  newDateRemind.add(duration);
                                                  newDateRemind.startOf('minute');
                                                  const dateStart = newDateRemind;
                                                  if (remind && remind.length > 0) {
                                                    setRemindSetting({
                                                      type: remind[0].type != null ? remind[0].type : (item.is_remind_start_admin > 0 ? '1' : '0'),
                                                      trn_manage_id: remind[0].trn_manage_id,
                                                      team_id: remind[0].team_id,
                                                      process_id: remind[0].process_id,
                                                      process_name: process.process_name,
                                                      task_id: remind[0].task_id,
                                                      task_name: item.task_name,
                                                      task_content: item.task_content,
                                                      notification_type: 4,
                                                      notification_start_type: 5,
                                                      setting_time: item.task_start_limit,
                                                      remind_time: date,
                                                      dateStart: dateStart,
                                                      notification_start_day_value: +remind[0].notification_start_day_value ,
                                                      notification_start_hour_value: +remind[0].notification_start_hour_value ,
                                                      notification_start_minutes_value: +remind[0].notification_start_minutes_value ,
                                                      notification_loop_value: +remind[0].notification_loop_value,
                                                      notification_loop_interval: remind[0].notification_loop_interval ? remind[0].notification_loop_interval : '5',
                                                      notification_loop_num: +remind[0].notification_loop_num,
                                                    });
                                                  } else {
                                                    setRemindSetting({
                                                      type: '0',
                                                      trn_manage_id: item.trn_manage_id,
                                                      team_id: item.team_id,
                                                      process_id: item.process_id,
                                                      process_name: process.process_name,
                                                      task_id: item.task_id,
                                                      task_name: item.task_name,
                                                      task_content: item.task_content,
                                                      notification_type: 4,
                                                      notification_start_type: 5,
                                                      setting_time: item.task_start_limit,
                                                      remind_time: item.task_start_limit,
                                                      dateStart: item.task_start_limit,
                                                      notification_start_day_value: 0,
                                                      notification_start_hour_value: 0,
                                                      notification_start_minutes_value: 0,
                                                      notification_loop_value: 1,
                                                      notification_loop_interval: '5',
                                                      notification_loop_num: 1,
                                                    });
                                                  }
                                                }}
                                              >
                                                <path d='M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z' 
                                                  fill={item.is_remind_start_admin > 0 || item.is_remind_start_user > 0 ? 'rgb(3, 255, 180)' : 'yellow'} 
                                                  stroke='black' stroke-width='15' stroke-linejoin='round' />
                                              </svg>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 1 && item.status != 1 && item.status != 2 && item.status != 3 && item.status != 4 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      開始時設定 <br />
                                      {item.task_start_limit ? formatDateJP(new Date(item.task_start_limit)) + '　にタスクが開始' : <br />}
                                    </div>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 2 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      事前設定 <br />
                                      {item.task_start_limit ? formatDateJP(new Date(item.task_start_limit)) + '　にタスクが開始' : <br />}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col span={10}>
                              <div className='flex__box flex__jus__sb flex--1 process__task__box'>
                                <div className='process__task__box--nametask flex--1'>
                                  {item.task_end_limit_mode_display == 0 && (
                                    <p style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      設定なし
                                      <br />　
                                    </p>
                                  )}
                                  {item.task_end_limit_mode_display == 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4) && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      <p style={{ margin: 0 }}>開始時設定</p>
                                      <Row>
                                        <Col span={22}>
                                          <DatePicker
                                            value={item.task_end_limit !== null ? moment(item.task_end_limit) : ''}
                                            allowClear={true}
                                            suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                                            format={'yyyy 年 MM 月 DD'}
                                            style={{ fontSize: `${fontSize / 2.5}px` }}
                                            onChange={(e) => {
                                              handleChange(item.task_no, e, false, true);
                                            }}
                                          />
                                          <TimePicker
                                            showNow={false}
                                            value={item.task_end_limit !== null ? moment(item.task_end_limit) : ''}
                                            allowClear={false}
                                            suffixIcon={<FaCaretDown size={20} color='#000' />}
                                            format={'HH：mm'}
                                            style={{ color: 'black', fontWeight: 600, fontSize: `${fontSize / 2.5}px` }}
                                            onSelect={(e) => {
                                              handleChange(item.task_no, e, false, false);
                                            }}
                                          />
                                          　にタスクが終了
                                        </Col>
                                        <Col span={2}>
                                          <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {item.task_end_limit && (
                                              <svg
                                                style={{ cursor: 'pointer' }}
                                                width='25'
                                                height='25'
                                                viewBox='0 0 448 512'
                                                xmlns='http://www.w3.org/2000/svg'
                                                onClick={() => {
                                                  setOpenRemindSetting(true);
                                                  setOpenSetType(false);
                                                  let remind = [];
                                                  let date = null;
                                                  if(submitRemindSetting && submitRemindSetting.length > 0){
                                                    remind = submitRemindSetting.filter((x) => x.task_id == item.task_id && +x.notification_type == 7);
                                                    if(remind.length <= 0){
                                                      remind = remindData.filter((x) => x.task_id == item.task_id && +x.notification_type == 7 && x.actor_type == (item.is_remind_end_admin > 0 ? 0 : 1));
                                                      const filterDate = remindDate.filter((x) => x.task_id == item.task_id && +x.notification_type == 7 && x.actor_type == (item.is_remind_end_admin > 0 ? 0 : 1));
                                                      date = (filterDate &&  filterDate.length > 0) ? filterDate[0].notification_next_time : null;
                                                    }
                                                    else{
                                                      // dateStart = remind[0].dateStart;
                                                      date = remind[0].dateStart;
                                                    }
                                                  }else{
                                                    remind = remindData.filter((x) => x.task_id == item.task_id && +x.notification_type == 7 && x.actor_type == (item.is_remind_end_admin > 0 ? 0 : 1));
                                                    const filterDate = remindDate.filter((x) => x.task_id == item.task_id && +x.notification_type == 7 && x.actor_type == (item.is_remind_end_admin > 0 ? 0 : 1));
                                                    date = (filterDate &&  filterDate.length > 0) ? filterDate[0].notification_next_time : null;
                                                  }
                                                  const newDateRemind = moment(date);
                                                  const duration = moment.duration({
                                                    years: 0, // Nếu có giá trị từ remind thì thay thế
                                                    months: 0, // Nếu có giá trị từ remind thì thay thế
                                                    days: +remind[0]?.notification_start_day_value || 0,
                                                    hours: +remind[0]?.notification_start_hour_value || 0,
                                                    minutes: +remind[0]?.notification_start_minutes_value || 0,
                                                  });
                                                  newDateRemind.add(duration);
                                                  newDateRemind.startOf('minute');
                                                  const dateStart = newDateRemind;
                                                  if (remind && remind.length > 0) {
                                                    setRemindSetting({
                                                      type: remind[0].type != null ? remind[0].type : (item.is_remind_end_admin > 0 ? '1' : '0'),
                                                      trn_manage_id: remind[0].trn_manage_id,
                                                      team_id: remind[0].team_id,
                                                      process_id: remind[0].process_id,
                                                      process_name: process.process_name,
                                                      task_id: remind[0].task_id,
                                                      task_name: item.task_name,
                                                      task_content: item.task_content,
                                                      notification_type: 7,
                                                      notification_start_type: 6,
                                                      setting_time: item.task_end_limit,
                                                      remind_time: date,
                                                      dateStart: dateStart,
                                                      notification_start_day_value: +remind[0].notification_start_day_value ,
                                                      notification_start_hour_value: +remind[0].notification_start_hour_value ,
                                                      notification_start_minutes_value: +remind[0].notification_start_minutes_value ,
                                                      notification_loop_value: +remind[0].notification_loop_value,
                                                      notification_loop_interval: remind[0].notification_loop_interval ? remind[0].notification_loop_interval : '5',
                                                      notification_loop_num: +remind[0].notification_loop_num,
                                                    });
                                                  } else {
                                                    setRemindSetting({
                                                      type: '0',
                                                      trn_manage_id: item.trn_manage_id,
                                                      team_id: item.team_id,
                                                      process_id: item.process_id,
                                                      process_name: process.process_name,
                                                      task_id: item.task_id,
                                                      task_name: item.task_name,
                                                      task_content: item.task_content,
                                                      notification_type: 7,
                                                      notification_start_type: 6,
                                                      setting_time: item.task_end_limit,
                                                      remind_time: item.task_end_limit,
                                                      dateStart: item.task_end_limit,
                                                      notification_start_day_value: 0,
                                                      notification_start_hour_value: 0,
                                                      notification_start_minutes_value: 0,
                                                      notification_loop_value: 1,
                                                      notification_loop_interval: '5',
                                                      notification_loop_num: 1,
                                                    });
                                                  }
                                                }}
                                              >
                                                <path d='M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z' 
                                                  fill={item.is_remind_end_admin > 0 || item.is_remind_end_user > 0 ? 'rgb(3, 255, 180)' : 'yellow'} 
                                                  stroke='black' 
                                                  stroke-width='15' 
                                                  stroke-linejoin='round' />
                                              </svg>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                  {item.task_end_limit_mode_display == 1 && item.status != 1 && item.status != 2 && item.status != 3 && item.status != 4 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      開始時設定 <br />
                                      {item.task_end_limit ? formatDateJP(new Date(item.task_end_limit)) + '　にタスクが終了' : <br />}
                                    </div>
                                  )}
                                  {item.task_end_limit_mode_display == 2 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      事前設定 <br />
                                      {item.task_end_limit ? formatDateJP(new Date(item.task_end_limit)) + '　にタスクが終了' : <br />}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row style={{ minHeight: 50, padding: 20 }}>
              <div className='flex__box flex__jus__c ' style={{ width: '100%' }}>
                <div className='flex__box flex__jus__sb common__process__bottom--btn'>
                  <Button style={{ backgroundColor: 'transparent' }}></Button>
                  <div className='flex__box flex__jus__sb flex__gap__cl--20'>
                    <Button
                      // disabled={errorTasks?.length > 0}
                      size='large'
                      className='bg__green common__process__control--btn'
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      内容を確定
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Spin>
      </Modal>

      <Modal
        open={openDone}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              <span className='common_text-clamp common_text-clamp--2' style={{ lineHeight: `${fontSize / 1.2}px` }}>
                プロセス名：{process ? process.process_name : ''}
              </span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{ paddingTop: 50, fontSize: `${fontSize / 2}px`, textAlign: 'center' }} className='text-clamp text-clamp--2'>
                  開始時設定の内容を確定しました。
                  <br />
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={openError}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              <span className='common_text-clamp common_text-clamp--2' style={{ lineHeight: `${fontSize / 1.2}px` }}>
                プロセス名：{process ? process.process_name : ''}
              </span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <p style={{ paddingTop: 50, fontSize: `${fontSize / 2}px` }} className='text-clamp text-clamp--2'>
                  設定中に開始されたタスクが存在するため、内容を確定できませんでした。
                </p>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={openNotUser}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              <span className='common_text-clamp common_text-clamp--2' style={{ lineHeight: `${fontSize / 1.2}px` }}>
                プロセス名：{process ? process.process_name : ''}
              </span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{ paddingTop: 50, fontSize: `${fontSize / 2}px`, textAlign: 'center' }} className='text-clamp text-clamp--2'>
                  開始時設定を行う権限がありません。
                  <br />
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={openNotFound}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              <span className='common_text-clamp common_text-clamp--2' style={{ lineHeight: `${fontSize / 1.2}px` }}>
                プロセス名：{process ? process.process_name : ''}
              </span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <p style={{ paddingTop: 50, fontSize: `${fontSize / 2}px` }} className='text-clamp text-clamp--2'>
                  設定中に開始されたタスクが存在するため、内容を確定できませんでした。
                </p>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <RemindTaskDuedate
        value={openSetType ? getRemindData() : getRemindData()}
        timeStartTask={remindSetting?.setting_time ? new Date(remindSetting?.setting_time) : ''}
        notification_start_type={remindSetting?.notification_start_type}
        open={openRemindSetting}
        handleCancelRemind={() => {
          setOpenRemindSetting(!openRemindSetting);
        }}
        handleSetRemindTask={handleRemindSetting}
      />
    </div>
  );
}

export default TaskDueDate;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Content, Header } from 'antd/lib/layout/layout';
import { Button, Spin, Tooltip } from 'antd';
import ModalConfirm from '../../commons/commonComponents/modalConfirm/ModalConfirm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaCog, FaShareAlt, FaTh } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { COMMON_MESSAGES } from '../../commons/commonMessages/commonMessages';
import queryString from 'query-string';

TemplateProcess.propTypes = {};

function TemplateProcess(props) {
  const { listProcess, spinning } = props;
  const navigation = useNavigate();
  const [processInfo, setProcessInfo] = useState({});
  const handleCopy = async () => {
    if (!processInfo.process_id && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(COMMON_MESSAGES.HRM.USER.select_one_row, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const filter = {
      template_id: processInfo.process_id,
    };
    navigation({
      pathname: `/process/new`,
      search: queryString.stringify(filter),
    });
  };
  return (
    <>
      <Header>
        <div className='header'>
          <div className='header__content'>
            <h1 className='header__content__title'>
              <FaTh color='#000' /> テンプレート
            </h1>
          </div>
        </div>
      </Header>
      <Content>
        <Spin spinning={spinning} size='large' className='process_content_spining'>
          <div className='common__flex process__content '>
            <div className='process__content__bottom'>
              <div className='process__content__bottom--box'>
                <div style={{ flex: 1 }}>
                  <div className={'process__new--content process__new--content--custom process__admin--custom'}>
                    <div className='process__new--intro common__process__listtaskbox'>
                      <h2 className='color__title__black mb--30 mt--30'>事前に作成されたテンプレートを使用してプロセスを作成</h2>
                      <div className='flex__box' style={{ flexWrap: 'wrap', padding: '0px 10px', columnGap: 10, rowGap: 10 }}>
                        {listProcess.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={+processInfo.process_id === +item.process_id ? 'template_item active' : 'template_item'}
                              style={{ width: 250, height: 200, border: '1px solid #ccc',padding:10,boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 10px`,cursor:'pointer',
                                border:'4px solid #00a199' }}
                              onClick={() => {
                                setProcessInfo({ process_id:item.process_id });
                              }}
                            >
                              <h2 className='common_text-clamp common_text-clamp--2' style={{color:'black',minHeight:50,textAlign:'center'}}>{item.process_name}</h2>
                              <Tooltip title={item.process_outline}  overlayInnerStyle={{width: '250px'}}>
                              <p className='common_text-clamp common_text-clamp--4' style={{color:'black'}}>{item.process_outline}</p>
                              </Tooltip>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='common__process__control'>
                  <div className='common__flex common__process__control--listbtn'>
                    <div className='common__flex common__process__control--btnL'>
                    </div>
                    <div className='common__flex common__process__control--btnR'>
                      <Button disabled={false} className={false ? 'common__process__control--btn common__process__bottom--btn--disable' : 'bg__green common__process__control--btn'} onClick={handleCopy}>
                        OK
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Content>
    </>
  );
}

export default TemplateProcess;

import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { hrmsApi } from '../../../../api/hrmApi';
import { FormatDate } from '../../../../commons/commonFunctions/FormatDate';
import { teamState } from '../../../../redux/selector';
import User from './User';

UserContainer.propTypes = {};

const initialGetAllUserValues = {};

function UserContainer(props) {
  const [listUser, setListUser] = useState([]);
  const [seachModle, setSearchModle] = useState(initialGetAllUserValues);
  const [spinning, setSpinning] = useState(false);
  const teamInfo = useSelector(teamState);
  const location = useLocation();
  const navigation = useNavigate();
  const [pagination, setPagination] = useState({
    totals: 1,
    limit: 10,
    page: 1,
  });

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      team_id: teamInfo?.listTeam[0]?.team_id,
      page: Number.parseInt(pagination.page) || 1,
      limit: Number.parseInt(pagination.limit) || 10,
      id: Number.parseInt(params.user) || undefined,
    };
  }, [location.search, teamInfo]);

  useEffect(async() => {
      try {   
       if(location.pathname.includes('hrm')){
        setSpinning(true);
        const result = await hrmsApi.usersApi.getAllUser(queryParams);
        if (result) {
          const { data, pagination = {} } = result;
          const dataListUser = [...data].map((item, index) => {
            item.key = index;
            item.no = index + 1;
            item.last_login_date = item.last_login_date === null ? '' : FormatDate(new Date(item.last_login_date));
            return item;
          });
          setListUser(dataListUser);
        }
        setSpinning(false);
       }
      } catch (error) {
        setSpinning(false);
      }

  }, [queryParams, location.pathname]);

  const handlePagination = (values) => {
    const { page, pageSize } = values;
    const filter = {
      ...queryParams,
      team_id: undefined,
      page: page,
      limit: pageSize,
    };
    setPagination({ ...pagination, page, limit: pageSize });
    navigation({
      pathname: `${location.pathname}`,
      search: queryString.stringify(filter),
    });
  };

  return <User spinningUserLoad={spinning} queryParams={queryParams} listUser={listUser} handlePagination={handlePagination} />;
}

export default UserContainer;

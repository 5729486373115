import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Image, Input, Modal, Row, Spin } from 'antd';
import queryString from 'query-string';
import logo from '../../../../assets/logo.jpg';
import './Css/style.css';
import { referencesApi } from '../../../../api/referencesApi';
import { teamState } from '../../../../redux/selector';
import { formatDateJP, formatDateJP2 } from '../../../../commons/commonFunctions/FormatDate';

const cryptoJS = require('crypto-js');

TaskReference.propTypes = {};

function TaskReference(props) {
  const teamInfo = useSelector(teamState);
  const [item, setItem] = useState({});
  const [spin, setSpin] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  //const [openNotUser, setOpenNotUser] = useState(false);
  const [openNotFound, setOpenNotFound] = useState(false);

  const location = useLocation();
  const navigation = useNavigate();

  const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;

  useEffect(() => {
    async function fetchData() {
      const accessToken = localStorage.getItem('accessToken');
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (!accessToken || !user) {
        navigation('/login');
        return;
      }
      setSpin(true);

      var filter = queryString.parse(location.search);
      const option = JSON.parse(cryptoJS.AES.decrypt(filter.searchId.replaceAll(' ', '+'), encryptKey).toString(cryptoJS.enc.Utf8));

      if (option.task_id) {
        try {
          if (!teamInfo || !teamInfo.listTeam || teamInfo.listTeam.length <= 0) {
            return;
          }
          const param = { type: option.type, team_id: teamInfo.listTeam[0]?.team_id, trn_manage_id: option.trn_manage_id, task_id: option.task_id, user_id: option.user_id };
          const res = await referencesApi.getTaskDtl(param);

          if (res.data && res.data.length > 0) {
            const data = res.data[0];
            setItem({
              team_id: teamInfo.listTeam[0]?.team_id,
              trn_manage_id: data.trn_manage_id,
              task_id: data.task_id,
              user_id: data.user_id,
              process_name: data.process_name,
              process_start_achievement: data.process_start_achievement,
              task_name: data.task_name,
              user_name: data.user_name,
              user_status_name: data.user_status_name,
              user_task_start_achievement: data.user_task_start_achievement,
              user_task_end_achievement: data.user_task_end_achievement,
              task_content: data.task_content,
              user_respond_info: data.user_respond_info,
            });
            setOpenNew(true);
          } else {
            setItem({});
            setOpenNotFound(true);
          }
          setSpin(false);
        } catch (error) {
          setSpin(false);
          console.log(error);
        }
      } else {
        setSpin(false);
        setOpenNotFound(true);
        //let closeWindows = window.open("/taskConfirm","_self");
        //closeWindows.close();
        window.close();
      }
    }
    fetchData();
  }, [location.pathname, teamInfo]);

  const handleSubmit = async () => {
    window.close();
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [fontSize, setFontSize] = useState(0);
  //const [lineSpace, setLineSpace] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setFontSize(windowWidth / 40);
    //setLineSpace(windowHeight/20);
  }, [windowWidth, windowHeight]);

  return (
    <div className='App'>
      <Modal
        open={openNew}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
             <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}> {item ? `プロセス名：${item.process_name}` : 'プロセス名：'}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{item ? formatDateJP(new Date(item.process_start_achievement)) : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <Spin tip='ローディング．．．' spinning={spin}>
          <div className='task_content' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',paddingTop:'20px' }}>
            <Row style={{}}>
              <Col span={2} />
              <Col span={20}>
                <div className='task_content--content'>
                  <p className='text-clamp text-clamp--2' style={{ fontSize: `${fontSize}px`,margin:0,textAlign:'center',lineHeight:'initial' }}>
                    {item.task_name ? '「' + item.task_name + '」' : ''}
                  </p>
                  <p style={{ fontSize: `${fontSize / 2}px`,margin:0,lineHeight:'initial' }} className='text-clamp text-clamp--2'>
                    タスク担当者：{item.user_name}
                  </p>
                  <p style={{ fontSize: `${fontSize / 2}px`,margin:0,lineHeight:'initial' }} className='text-clamp text-clamp--2'>
                    タスクのステータス：{item.user_status_name}
                  </p>
                  <p style={{ fontSize: `${fontSize / 2}px`,margin:0,lineHeight:'initial' }} className='text-clamp text-clamp--2'>
                    タスク開始実績日時：{item.user_task_start_achievement ? formatDateJP2(new Date(item.user_task_start_achievement)) : ''}
                  </p>
                  <p style={{ fontSize: `${fontSize / 2}px`,margin:0,lineHeight:'initial' }} className='text-clamp text-clamp--2'>
                    タスク終了実績日時：{item.user_task_end_achievement ? formatDateJP2(new Date(item.user_task_end_achievement)) : ''}
                  </p>
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row style={{}}>
              <Col span={2} />
              <Col span={20}>
                <div className='task_content--content'>
                  <p style={{ fontSize: `${fontSize / 2}px`,margin:0,lineHeight:'initial' ,marginTop:20}} className='text-clamp text-clamp--2'>
                    以下の指示内容に従ってタスクを処理してください。
                  </p>
                </div>
              </Col>
              <Col span={2} />
            </Row>
            <Row style={{}}>
              <Col span={2} />
              <Col span={20} className='text-clamp text-clamp--2'>
                <div className='task_content--content' style={{ whiteSpace: 'pre-line', fontSize: `${fontSize / 2}px`,marginBottom:20,lineHeight:'initial' }}>
                  {item ? item.task_content : ''}
                </div>
              </Col>
              <Col span={2} />
            </Row>
            <Row style={{}}>
              <Col span={24}>
                <Row>
                  <Col span={2}></Col>
                  <Col span={20}>
                    <div className='task_content--label' style={{ fontSize: `${fontSize / 2}px` }}>
                      備考欄
                    </div>
                    <div >
                      <Input.TextArea disabled={true} maxLength={4000} style={{ fontSize: `${fontSize / 2}px`,backgroundColor:'#ccc',color:'#000' }} defaultValue={item ? item.user_respond_info : ''} readOnly={true} autoSize={true} />
                    </div>
                  </Col>
                  <Col span={2} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={2} style={{ paddingTop: 0, paddingBottom: 0 }} />
              <Col span={20}>
                <div className='common__process__bottom--btn' style={{margin:"10px 0px"}}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <>
                      <Button
                        size='large'
                        className='common__process__control--btn bg__blue'
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        閉じる
                      </Button>
                    </>
                  </div>
                </div>
              </Col>
              <Col span={2} />
            </Row>
          </div>
        </Spin>
      </Modal>

      <Modal
        open={openNotFound}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              プロセス名：{item ? item.process_name : ''}
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{item ? item.trigger_next_execution_start_time : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <p style={{ paddingTop: 50, fontSize: `${fontSize}px` }} className='text-clamp text-clamp--2'>
                  {/* 設定中に開始されたタスクが存在するため、内容を確定できませんでした。 */}
                  権限がありません。
                </p>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default TaskReference;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TemplateProcess from './TemplateProcess';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { teamState } from '../../redux/selector';
import { processApi } from '../../api/processApi';
import { useLocation } from 'react-router';
import { FormatDate } from '../../commons/commonFunctions/FormatDate';
import processSlice from '../Process/reduxProcess/processSlice';

TemplateProcessContainer.propTypes = {};

function TemplateProcessContainer(props) {
  const dispatch = useDispatch();
  const teamInfo = useSelector(teamState);
  const [listProcess, setListProcess] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [pagination, setPagination] = useState({
    totals: 1,
    limit: 10,
    page: 1,
  });
  const queryParams = useMemo(() => {
    const params = {};
    return {
      ...params,
      team_id: 1,
      page: Number.parseInt(pagination.page) || 1,
      limit: Number.parseInt(pagination.limit) || 10,
      id: Number.parseInt(params.user) || undefined,
      template_flg:1
    };
  }, [ teamInfo]);

  useEffect(() => {
    (async () => {
      try {
        if (queryParams.team_id === undefined) {
          setListProcess([]);
          return;
        }
        setSpinning(true);
        const { data } = await processApi.getAll(queryParams);
        if (data) {
          const dataListProcess = [...data].map((item, index) => {
            let get_trigger_last_execution_start_time = '';
            if (new Date(item.trigger_last_execution_start_time) - new Date(item.entry_date) > 0 || item.pre_column1 !== null) {
              get_trigger_last_execution_start_time = new Date(item.trigger_last_execution_start_time) - new Date(item.pre_column1 !== 'null' ? item.pre_column1 : null) > 0 ? (new Date(item.trigger_last_execution_start_time) - new Date(item.entry_date) > 0 ? FormatDate(item.trigger_last_execution_start_time) : '') : new Date(item.pre_column1) - new Date(item.entry_date) > 0 ? FormatDate(item.pre_column1) : '';
            }
            return {
              ...item,
              no: index + 1,
              user_name: item.user_name,
              trigger_next_execution_start_time: item.trigger_next_execution_start_time ? FormatDate(new Date(item.trigger_next_execution_start_time)) : '',
              trigger_last_execution_start_time: item.trigger_last_execution_start_time && new Date(`${item.trigger_last_execution_start_time}`) - new Date(item.entry_date) < 0 ? '' : FormatDate(new Date(item.trigger_last_execution_start_time)),
              pre_column1: get_trigger_last_execution_start_time,
              edit_date: item.edit_date ? FormatDate(new Date(item.edit_date)) : '',
            };
          });
          setListProcess(dataListProcess.sort((a,b)=> (a.pre_column2 - b.pre_column2)));
          const action = processSlice.actions.newProcess({});
          dispatch(action);
        }
        setSpinning(false);
      } catch (error) {
        setSpinning(false);
      }
    })();
  }, [queryParams]);

  return <TemplateProcess listProcess={listProcess} spinning={spinning} />;
}

export default TemplateProcessContainer;

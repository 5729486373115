import { Button, Input } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import InputI3links from '../../../../../commons/commonComponents/inputI3links/InputI3links';
import { FaPlus, FaRegTimesCircle } from 'react-icons/fa';
import useWindowWidth from '../../../../../hooks/useWindowWidth/useWindowWidth';

SortListTask.propTypes = {};

function SortListTask(props) {
  const { listTask, handleActiveTask, handleAddTask, handleEditTask, handleRemoveTask, onChangeOrder } = props;
  const [taskName, setTaskName] = useState('');

  const handleActive = (id) => {
    if (id && handleActiveTask) {
      handleActiveTask(id);
    }
  };
  const handleAdd = () => {
    if (handleAddTask) {
      handleAddTask();
    }
  };
  const handleEdit = (value) => {
    if (value && handleEditTask) {
      handleEditTask(value);
    }
  };
  const handleRemove = (id) => {
    if (id && handleRemoveTask) {
      handleRemoveTask(id);
    }
  };
  const windowWidth = useWindowWidth();

  const SortableItem = SortableElement(({ item}) => {
    return (
      <div className='common__flex process__task--item'>
        <div className='common__flex process__task--itemL'>
          <div className='process__task--item--number'>
            <p>タスク</p>
            <p>{item.index + 1}</p>
          </div>
          <div className='common__flex process__task--item--name'>
            {item.active ? (
              <div className='common__flex process__task--item--input'>
                <InputI3links item={item} maxLength={41} handleEdit={handleEdit} handleActive={handleActive} />
              </div>
            ) : (
              <div className='common__flex common__flex process__task--item--input '
                onClick={() => {
                  const id = item.id;
                  handleActive(id);
                }}
              >
                {item.task_name ? <p className='common_text-clamp common_text-clamp--2'>{item.task_name} </p> : <p className=''> ここにタスク名を入力</p>}
              </div>
            )}

            <Button
              className='process__task--item--btn'
              onClick={() => {
                const id = item.id;
                handleRemove(id);
              }}
            >
             <FaRegTimesCircle size={windowWidth < 1025 ? 25 : 30 }   />
            </Button>
          </div>
        </div>
        <div className='process__task--itemR'>
          <Button
            className='process__task--item--btn'
            onClick={() => {
              handleAdd();
            }}
          >
            <FaPlus size={windowWidth < 1025 ? 25 : 30 }/>
          </Button>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <div className='common__flex process__listtask '>
      {items?.map((item, index) => {
        return <SortableItem key={`${item.id}`} index={index} item={{...item,index}} />;
      })}
    </div>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newData = arrayMoveImmutable(listTask, oldIndex, newIndex);
    if (onChangeOrder) {
      onChangeOrder(newData);
    }
  };
  return (
    <>
      <SortableList items={listTask} onSortEnd={onSortEnd} distance={1} axis='xy' helperClass='SortableHelper' />
    </>
  );
}

export default SortListTask;

import CommonFunction, { convertToFull } from './CommonFunction'


function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export const FormatDateString = (DATEVALUE)=>{
  
  if(!DATEVALUE){
    return 
  }
  const DATEVALUE1 = new Date(DATEVALUE)
  if(DATEVALUE1.getFullYear() === 1970){
    return 
  }
  return (
    [
      DATEVALUE1.getFullYear(),
      padTo2Digits(DATEVALUE1.getMonth() + 1),
      padTo2Digits(DATEVALUE1.getDate()),
    ].join('') +
    '_' +
    [
      padTo2Digits(DATEVALUE1.getHours()),
      padTo2Digits(DATEVALUE1.getMinutes()),
      padTo2Digits(DATEVALUE.getSeconds()),
    ].join('')
  );
}

export const FormatDate = (DATEVALUE)=>{
  
  if(!DATEVALUE){
    return 
  }
  const DATEVALUE1 = new Date(DATEVALUE)
  if(DATEVALUE1.getFullYear() === 1970){
    return 
  }

 
    return (
        [
          DATEVALUE1.getFullYear(),
          padTo2Digits(DATEVALUE1.getMonth() + 1),
          padTo2Digits(DATEVALUE1.getDate()),
        ].join('-') +
        ' ' +
        [
          padTo2Digits(DATEVALUE1.getHours()),
          padTo2Digits(DATEVALUE1.getMinutes()),
        //   padTo2Digits(DATEVALUE.getSeconds()),
        ].join(':')
      );
    
}
export const FormatDateCustom = (DATEVALUE)=>{
  
  if(!DATEVALUE){
    return 
  }
  const DATEVALUE1 = new Date(DATEVALUE)
  if(DATEVALUE1.getFullYear() === 1970){
    return 
  }

 
    return (
        new Date([
          DATEVALUE1.getFullYear(),
          padTo2Digits(DATEVALUE1.getMonth() + 1),
          padTo2Digits(DATEVALUE1.getDate()),
        ].join('-') +
        ' ' +
        [
          padTo2Digits(DATEVALUE1.getHours()),
          padTo2Digits(DATEVALUE1.getMinutes()),
        ].join(':'))
      );
    
}

export const formatDateJP1 = (DATEVALUE)=>{
  if(!DATEVALUE){
    return 
  }
  return (
    convertToFull(DATEVALUE.getFullYear().toString()) + '年' 
    + convertToFull(padTo2Digits(DATEVALUE.getMonth() + 1)) + '月' 
    + convertToFull(padTo2Digits(DATEVALUE.getDate())) + '日' 
    + '　' + [convertToFull(padTo2Digits(DATEVALUE.getHours())), convertToFull(padTo2Digits(DATEVALUE.getMinutes()))].join('：')
  );
}

export const formatDateJP = (DATEVALUE)=>{
  if(!DATEVALUE){
    return 
  }
  return (
    DATEVALUE.getFullYear().toString() + '年 ' 
    + ((DATEVALUE.getMonth() + 1) < 10 ? `0${(DATEVALUE.getMonth() + 1)}` : (DATEVALUE.getMonth() + 1))  + '月 ' 
    + (DATEVALUE.getDate() < 10 ? `0${DATEVALUE.getDate()}` : DATEVALUE.getDate()) + '日 ' 
    + '　' + [DATEVALUE.getHours() < 10 ? `0${DATEVALUE.getHours()}`: DATEVALUE.getHours() , DATEVALUE.getMinutes() < 10 ? `0${DATEVALUE.getMinutes()}`: DATEVALUE.getMinutes()].join(':')
  );
}

export const formatDateJP2 = (DATEVALUE)=>{
  if(!DATEVALUE){
    return 
  }
  return (
    convertToFull(DATEVALUE.getFullYear().toString()) + '年' 
    + convertToFull(padTo2Digits(DATEVALUE.getMonth() + 1)) + '月' 
    + convertToFull(padTo2Digits(DATEVALUE.getDate())) + '日' 
    + '　' 
    + convertToFull(padTo2Digits(DATEVALUE.getHours())) + '時' 
    + convertToFull(padTo2Digits(DATEVALUE.getMinutes())) + '分' 
  );
}
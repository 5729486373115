import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import SignInSA from '../../components/Auth/SignInSA';
import teamSlice from '../../components/I3linkSider/reduxTeam/teamSlice';
import { COMMON_MESSAGES } from '../../commons/commonMessages/commonMessages';
import authenticationsApi from '../../api/authenticationsApi';
import teamsApi from '../../api/teamsApi';

PageLoginSA.propTypes = {};

function PageLoginSA(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [spinning, setSpinning] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [ teams, setTeams] = useState([]);

  useEffect(() => {
    (async () => {
      const teams = await teamsApi.getAllPublic()
      if(teams.data){
        setTeams(teams.data);
      }
      else{
        setTeams([]);
      }
    })();
    localStorage.removeItem('userInfo');
    localStorage.removeItem('accessToken');
    const action = teamSlice.actions.addTeam({listTeam:[]})
    dispatch(action)
  }, [])

  const handleSignIn = async (values) => {
    if (values) {
      try {
        setSpinning(true);
        values = {...values, isSuperAdmin: true};
        const result = await authenticationsApi.Login(values);
        const { accessToken, userInfo, temp_login_password_flg, teamInfo } = result;
        if (result.error) {
          if(result.onDisplay && !toast.isActive({toastId:'toastId_err'})){
            setErrormessage(result.error);
          }else
          {
            toast.error(result.error, {
              toastId:'toastId_err',
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
          }
          navigate('/loginsa');
          setSpinning(false);
          return;
        }
        if (+temp_login_password_flg === 1 && !toast.isActive({toastId:'toast_warning'})) {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('teamInfo',JSON.stringify(teamInfo))
          toast.warning(COMMON_MESSAGES.AUTH.CHANGEPASSWORD, {
            toastId:'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          navigate('/resetpassword');
          return;
        }
        if (accessToken && userInfo && +temp_login_password_flg === 0) {
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('isSuperAdmin', 1);
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('teamInfo',JSON.stringify(teamInfo))
          navigate('/process');
          return;
        }
        setSpinning(false);
      } catch (error) {
        alert(error)
        if (error.message && !toast.isActive({toastId:'toast_warning'})) {
          toast.warning(error.message, {
            toastId:'toast_warning',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
        setSpinning(false);
        navigate('/loginsa');
      }
    }
  };
  return (
    <div className='common__signin '>
      <Spin spinning={spinning}  size='large' className='common__signin__loading'>
        <SignInSA teams={teams} onSignIn={handleSignIn} errormessage = {errormessage}/>
      </Spin>
    </div>
  );
}

export default PageLoginSA;

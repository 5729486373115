import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserContainer from './components/user';
import './css/style.css';
HRM.propTypes = {};

function HRM(props) {

  return (
    <Routes>
    <Route path='user/*' element={<UserContainer />} />
    <Route path='/*' element={<Navigate to="/hrm/user" />} />
  </Routes>
   
  );
}

export default HRM;

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
listTeam:[]
};

export default createSlice({
  name: 'teams',
  initialState: initialState,
  reducers: {
    addTeam: (state, action) => {
      const {listTeam }= action.payload
      state.listTeam = listTeam
    },
    
  },
});

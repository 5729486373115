import { Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { processApi } from '../../../../../api/processApi';
import IUI_intro0 from '../../../../../assets/IUI_intro0.png';
import IUI_intro1 from '../../../../../assets/IUI_intro1.png';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { escapeRegExp } from '../../../../../commons/commonFunctions/CommonFunction';
import { COMMON_MESSAGES } from '../../../../../commons/commonMessages/commonMessages';
import { MAX_NUMBER_TASK } from '../../../../../config/config';
import { processState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';
import SortListTask from './SortListTask';
import queryString from 'query-string';
StepCreateProcess.propTypes = {};
function StepCreateProcess(props) {
  const { nextStep, prevStep, flag, handleListStep } = props;
  const processInfo = useSelector(processState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [processName, setProcessName] = useState(() => {
    return processInfo.process_name;
  });
  const [step, setStep] = useState(() => {
    return flag ? 2 : 0;
  });
  const [listTask, setListTask] = useState(() => {
    return processInfo.tasks;
  });
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(() => {
    let check = false;
    // const searchParam = queryString.parse(location.search);
    // if (searchParam.template_id && processInfo.pre_column3) {
    //     check = true;
    // }
    return check;
  });

  const handleOnChangeNameProcess = (value) => {
    setProcessName(value);
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <div className='process__new--intro '>
            <h2 className='mb--10 color__title__black'>プロセス(仕事)をi3Linksに覚えさせましょう。</h2>
            <h2 className='color__title__black'>プロセス(仕事)を細かいタスク(作業)に分解し、</h2>
            <h2 className=' color__title__black'>「誰が」「何を」「いつまでに」を次の画面から設定していきます。</h2>
          </div>
        );
      case 1:
        return (
          <>
            <div className='process__new--intro mb--10'>
              <h2 className='color__title__black'>プロセス名を設定してください。 (40文字以内)</h2>
            </div>
            <div className='process__new--input mb--10'>
              <Input
                autoFocus={true}
                placeholder='ここにプロセス名称を入力'
                value={processName}
                maxLength={41}
                onChange={(e) => {
                  const nameProcess = e.target.value;
                  const nameEscapeRegExp = escapeRegExp(nameProcess);
                  if (processName.length > nameProcess.length) {
                    handleOnChangeNameProcess(nameEscapeRegExp);
                    return;
                  }
                  if (nameEscapeRegExp.length <= 40 && !toast.isActive({ toastId: 'length_name_task' })) {
                    handleOnChangeNameProcess(nameEscapeRegExp);
                  } else {
                    toast.warning('40字以内で入力してください。', {
                      toastId: 'length_name_task',
                      position: 'top-right',
                      autoClose: 3000,
                      closeOnClick: true,
                      theme: 'light',
                      pauseOnHover: false,
                      hideProgressBar: true,
                    });
                  }
                }}
                onBlur={() => {
                  const process_name = processName.trim();
                  setProcessName(process_name);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && handleNextStep) {
                    handleNextStep();
                  }
                }}
              />
            </div>
          </>
        );
      case 2:
        return (
          <div className='process__new--intro common__process__listtaskbox mb--10'>
            <div className='common__flex mb--30 mt--20' style={{ marginTop: 20 }}>
              <h2 className='color__title__black'>タスク名を設定してください (40文字以内)</h2>
            </div>
            <div className='common__process__listtask  mb--30'>
              <SortListTask listTask={listTask} handleActiveTask={handleActiveTask} handleAddTask={handleAddTask} handleEditTask={handleEditTask} handleRemoveTask={handleRemoveTask} onChangeOrder={onChangeOrder} />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  //////////////add task/////
  const handleAddTask = () => {
    if (listTask.length >= MAX_NUMBER_TASK && !toast.isActive({ toastId: 'toast_warning' })) {
      toast.warning(COMMON_MESSAGES.PROCESS.MAXTASK, {
        toastId: 'toast_warning',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: false,
        theme: 'light',
        pauseOnHover: true,
        hideProgressBar: true,
      });
      return;
    }
    const newTask = {
      id: uuidv4(),
      task_name: ``,
      task_content: '',
      listUser: [],
      type: null,
      active: false,
      worker_decision_type: 0,
      start_limit_reference_type: 2,
      start_limit_reference_task: 0,
      start_limit_decision_type: 0,
      start_limit_year_diff_value: 0,
      start_limit_month_diff_value: 0,
      start_limit_day_diff_value: 0,
      start_limit_hour_diff_value: 0,
      start_limit_minutes_diff_value: 0,
      start_limit_year_fixed_value: null,
      start_limit_month_fixed_value: null,
      start_limit_day_fixed_value: null,
      start_limit_hour_fixed_value: null,
      start_limit_minutes_fixed_value: null,
      end_limit_reference_type: 0,
      end_limit_reference_task: null,
      end_limit_decision_type: 0,
      end_limit_year_diff_value: 0,
      end_limit_month_diff_value: 0,
      end_limit_day_diff_value: 0,
      end_limit_hour_diff_value: 0,
      end_limit_minutes_diff_value: 0,
      end_limit_year_fixed_value: null,
      end_limit_month_fixed_value: null,
      end_limit_day_fixed_value: null,
      end_limit_hour_fixed_value: null,
      end_limit_minutes_fixed_value: null,
      pre_column1: null,
    };
    setListTask([...listTask, { ...newTask, index: listTask.length }]);
  };
  //////////////add task/////

  //////////////active edit task/////
  const handleActiveTask = (id) => {
    const newListTask = listTask.map((item) => {
      const task = { ...item };
      if (task.id === id) {
        task.active = !task.active;
      } else {
        task.active = false;
      }
      return task;
    });

    setListTask(newListTask);
  };
  //////////////active edit task/////

  //////////////active edit task/////
  const handleEditTask = (value) => {
    if (value.task_name === '') {
      const newListTask = listTask.map((item) => {
        if (item.id === value.id) {
          item.active = false;
          return item;
        }
        return item;
      });
      setListTask(newListTask);
      return;
    }
    const newListTask = listTask.map((item) => {
      if (item.id === value.id) {
        item.task_name = value.task_name.trim();
        item.active = false;
      }
      return item;
    });
    const indexTask = listTask.findIndex((item) => item.id === value.id) + 1 === listTask.length;
    if (indexTask && listTask.length < MAX_NUMBER_TASK) {
      const newTask = {
        id: uuidv4(),
        task_name: '',
        task_content: '',
        listUser: [],
        active: true,
        index: listTask.length,
        worker_decision_type: 0,
        start_limit_reference_type: 2,
        start_limit_reference_task: 0,
        start_limit_decision_type: 0,
        start_limit_year_diff_value: 0,
        start_limit_month_diff_value: 0,
        start_limit_day_diff_value: 0,
        start_limit_hour_diff_value: 0,
        start_limit_minutes_diff_value: 0,
        start_limit_year_fixed_value: null,
        start_limit_month_fixed_value: null,
        start_limit_day_fixed_value: null,
        start_limit_hour_fixed_value: null,
        start_limit_minutes_fixed_value: null,
        end_limit_reference_type: 0,
        end_limit_reference_task: null,
        end_limit_decision_type: 0,
        end_limit_year_diff_value: 0,
        end_limit_month_diff_value: 0,
        end_limit_day_diff_value: 0,
        end_limit_hour_diff_value: 0,
        end_limit_minutes_diff_value: 0,
        end_limit_year_fixed_value: null,
        end_limit_month_fixed_value: null,
        end_limit_day_fixed_value: null,
        end_limit_hour_fixed_value: null,
        end_limit_minutes_fixed_value: null,
        pre_column1: null,
      };
      setListTask([...newListTask, { ...newTask }]);
      return;
    }
    setListTask(newListTask);
    return;
  };
  //////////////active edit task/////

  //////////////remove task/////
  const handleRemoveTask = (id) => {
    const newListTask = listTask.filter((item) => item.id !== id);
    const newlist = newListTask.map((item, index) => {
      const newItem = { ...item };
      newItem.index = index;
      return newItem;
    });
    if (newlist.length > 0) {
      setListTask([...newlist]);
    } else {
      const newTask = {
        id: uuidv4(),
        task_name: ``,
        task_content: '',
        listUser: [],
        active: false,
        index: 0,
        worker_decision_type: 0,
        start_limit_reference_type: 2,
        start_limit_reference_task: 0,
        start_limit_decision_type: 0,
        start_limit_year_diff_value: 0,
        start_limit_month_diff_value: 0,
        start_limit_day_diff_value: 0,
        start_limit_hour_diff_value: 0,
        start_limit_minutes_diff_value: 0,
        start_limit_year_fixed_value: null,
        start_limit_month_fixed_value: null,
        start_limit_day_fixed_value: null,
        start_limit_hour_fixed_value: null,
        start_limit_minutes_fixed_value: null,
        end_limit_reference_type: 0,
        end_limit_reference_task: null,
        end_limit_decision_type: 0,
        end_limit_year_diff_value: 0,
        end_limit_month_diff_value: 0,
        end_limit_day_diff_value: 0,
        end_limit_hour_diff_value: 0,
        end_limit_minutes_diff_value: 0,
        end_limit_year_fixed_value: null,
        end_limit_month_fixed_value: null,
        end_limit_day_fixed_value: null,
        end_limit_hour_fixed_value: null,
        end_limit_minutes_fixed_value: null,
      };
      setListTask([{ ...newTask }]);
    }
  };
  //////////////remove task/////

  //// chỉnh vị trí task
  const onChangeOrder = (newListTask) => {
    if (processInfo?.newProcess) {
      setListTask(newListTask);
    } else {
      const arrList = newListTask.map((item) => {
        return {
          task_name: item.task_name,
          start_limit_day_diff_value: +item?.start_limit_day_diff_value || 0,
          start_limit_hour_diff_value: +item?.start_limit_hour_diff_value || 0,
          start_limit_minutes_diff_value: +item?.start_limit_minutes_diff_value || 0,
          start_limit_reference_type: +item?.start_limit_reference_type,

          end_limit_day_diff_value: +item?.end_limit_day_diff_value || 0,
          end_limit_hour_diff_value: +item?.end_limit_hour_diff_value || 0,
          end_limit_minutes_diff_value: +item?.end_limit_minutes_diff_value || 0,
          end_limit_reference_type: +item?.end_limit_reference_type === 1 ? 1 : 0,
        };
      });
      let flag = 0;
      let check = true;
      for (let index = 0; index < arrList.length; index++) {
        const element = arrList[index];
        let totalTime = 0;
        if (((index === 0 && +element.start_limit_reference_type === 1) || (index === 0 && +element.start_limit_reference_type === 0)) && !toast.isActive({ toastId: 'task_number' })) {
          toast.warning('開始予定日時が設定されているため、タスク1に移動できません。予定日時設定をご確認ください。', {
            toastId: 'task_number',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: false,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });
          return;
        }
        if (element.start_limit_reference_type === 1 || element.end_limit_reference_type === 1) {
          if (element.start_limit_day_diff_value || element.start_limit_hour_diff_value || element.start_limit_minutes_diff_value) {
            totalTime = element.start_limit_day_diff_value * 24 * 60 + element.start_limit_hour_diff_value * 60 + element.start_limit_minutes_diff_value;
          }
          if (element.end_limit_day_diff_value || element.end_limit_hour_diff_value || element.end_limit_minutes_diff_value) {
            totalTime = element.end_limit_day_diff_value * 24 * 60 + element.end_limit_hour_diff_value * 60 + element.end_limit_minutes_diff_value;
          }
          if (flag <= totalTime) {
            flag = totalTime;
          } else {
            check = false;
            break;
          }
        }
      }
      if (newListTask && check) {
        const newlist = newListTask.map((item, index) => {
          const newItem = { ...item };
          newItem.index = index;
          return newItem;
        });
        setListTask(newlist);
      } else {
        toast.warning('時系列に矛盾があります。予定日時設定をご確認ください。', {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: false,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        return;
      }
    }
  };
  ////////////////////////////

  //////////////////////////////////////////////

  //////////////////////////////////////////////
  const handlePrevStep = () => {
    if (step > 0) {
      setStep(step - 1);
      handleListStep(step - 1);
      return;
    }
    if (prevStep && step === 0) {
      prevStep();
      return;
    }
  };

  const handleNextStep = async () => {
    if (step === 0) {
      setStep(step + 1);
      handleListStep(step + 1);
      return;
    }
    if (step === 1) {
      const queryParams = { team_id: processInfo.team_id, process_name: processName.trim(), process_id: processInfo.process_id || null };
      const resultName = await processApi.getByName(queryParams);
      if (resultName.error) {
        setStep(step + 1);
        handleListStep(step + 1);
        const process_name = processName.trim();
        const action = processSlice.actions.addNameProcess(process_name);
        dispatch(action);
        return;
      }
      if (resultName.success && !toast.isActive('name_process')) {
        toast.warning('このプロセス名は既に存在しています。', {
          toastId: 'name_process',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',

          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }
    }
    if (nextStep && step === 2) {
      const filterListTask = [...listTask]
        .filter((item) => item.task_name.trim() !== '')
        .map((item, index) => {
          return { ...item, index };
        });
      const action = processSlice.actions.addTasks(filterListTask);
      dispatch(action);
      nextStep();
      return;
    }
  };

  ////////////huy create process
  const handleCancelCreateProcess = () => {
    const searchParam = queryString.parse(location.search);
    const action = processSlice.actions.newProcess({});
    dispatch(action);
    if (searchParam.process_id) {
      navigate({
        pathname: `/process`,
      });
    } else {
      navigate({
        pathname: `/template`,
      });
    }
    return;
  };
  ////////////tắt modla huy tạo process
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };

  const checkDisableBtn = () => {
    if (step === 1) {
      const check = processName && processName.length <= 40;
      return check ? false : true;
    }
    if (step === 2) {
      const check = listTask.filter((item) => item.task_name !== '');
      return check.length > 0 ? false : true;
    }
    return false;
  };

  useEffect(() => {
    setProcessName(processInfo.process_name);
    handleListStep(step);
    setListTask(() => {
      return processInfo.tasks.length > 0
        ? processInfo.tasks
        : [{ id: uuidv4(), task_name: ``, task_content: '', type: null, listUser: [], active: false, index: 0, worker_decision_type: 0, start_limit_reference_type: 2, start_limit_reference_task: 0, start_limit_decision_type: 0, start_limit_year_diff_value: 0, start_limit_month_diff_value: 0, start_limit_day_diff_value: 0, start_limit_hour_diff_value: 0, start_limit_minutes_diff_value: 0, start_limit_year_fixed_value: null, start_limit_month_fixed_value: null, start_limit_day_fixed_value: null, start_limit_hour_fixed_value: null, start_limit_minutes_fixed_value: null, end_limit_reference_type: 0, end_limit_reference_task: null, end_limit_decision_type: 0, end_limit_year_diff_value: 0, end_limit_month_diff_value: 0, end_limit_day_diff_value: 0, end_limit_hour_diff_value: 0, end_limit_minutes_diff_value: 0, end_limit_year_fixed_value: null, end_limit_month_fixed_value: null, end_limit_day_fixed_value: null, end_limit_hour_fixed_value: null, end_limit_minutes_fixed_value: null }];
    });
  }, [processInfo, handleListStep, step]);
  useEffect(()=>{
    if(step === 2){
      setShowModal(() => {
        let check = false;
        const searchParam = queryString.parse(location.search);
        if (searchParam.template_id && processInfo.pre_column3) {
          check = true;
        }
        return check;
      });
    }
  },[processInfo,step])

  return (
    <>
      <div className='process__content__bottom--box'>
        <div style={{ flex: 1 }}>
          <div className={step < 2 ? 'process__new--content' : 'process__new--content process__new--content--custom'} style={{ padding: 0 }}>
            {renderStep(step)}
            {step < 2 && (
              <div className='common__flex process__new--workflow' style={{ width: '70%' }}>
                {step === 0 ? (
                  <div style={{ height: 185 }}>
                    <img src={IUI_intro0} alt='' style={{ objectFit: 'contain' }} />
                  </div>
                ) : (
                  <div style={{ height: 190 }}>
                    <img src={IUI_intro1} alt='' style={{ objectFit: 'contain' }} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='common__process__control'>
          <div className='common__flex common__process__control--listbtn'>
            <div className='common__flex common__process__control--btnL'>
              <Button
                className='bg__red common__process__control--btn'
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                キャンセル
              </Button>
              <ModalConfirm open={openModal} handleCancelConfirm={handleCancelConfirm} handleSubmit={handleCancelCreateProcess} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />
            </div>

            <div className='common__flex common__process__control--btnR'>
              {step > 0 && (
                <Button disabled={false} className={true ? 'bg__blue common__process__control--btn' : 'common__process__control--btn common__process__bottom--btn--disable'} onClick={handlePrevStep}>
                  前に戻る
                </Button>
              )}
              <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__control--btn common__process__bottom--btn--disable' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                次へ進む
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className=''
        maskClosable={false}
        closable={false}
        title=''
        open={showModal}
        // onCancel={() => {
        //   setShowModal(false);
        // }}
        forceRender
        footer={[
          <div key={0} className='modle__user__btn flex__box flex__jus__c'>
            <Button
              className='i3link__btn bg__blue'
              onClick={() => {
                setShowModal(false);
                const action = processSlice.actions.disableNotification({nameStep:'pre_column3'});
                dispatch(action);
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        <p style={{ marginBottom: 0 }}>{processInfo.pre_column3}</p>
      </Modal>
    </>
  );
}

export default StepCreateProcess;

import { FormatDateString } from "./FormatDate";

export const FORMATTRIM = (ob) => {
  const newOb = { ...ob };
  for (const key in newOb) {
    const item = newOb[key];
    if (typeof item === 'string') {
      newOb[key] = item.trim();
    }
  }
  return newOb;
};

export const convertToHalf = (e) => {
  return e.replace(/[！-～]/g, (halfwidthChar) => String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0));
};

export const convertToFull = (e) => {
  return e.replace(/[!-~]/g, (fullwidthChar) => String.fromCharCode(fullwidthChar.charCodeAt(0) + 0xfee0));
};

//////////// check ngày có vượt quá 10 năm so với ngày hiện tại
export const checkDate = (date1, date2) => {
  const dateNow1 = new Date(date1);
  const currentDate1 = new Date(date2);
  return (currentDate1 - dateNow1) / (365 * 24 * 60 * 60 * 1000);
};

//////////check ngày quá khứ///////////////////////
export const checkPrevDate = (dateStart, dateEnd = new Date()) => {
  const check = Math.trunc((new Date(dateStart) - new Date(dateEnd)) / 1000);
  if (check < -1) {
    return true;
  } else {
    return false;
  }
};
export const isPastDate = (inputDate, currentDate = new Date()) => {
  const input = new Date(inputDate);
  const current = new Date(currentDate);
  // Tính hiệu giữa hai ngày ra phút
  const differenceInMinutes = (current - input) / (1000 * 60);
  if (differenceInMinutes > 1) {
    return true;
  } else {
    return false;
  }
};
//////////////check ngày cuối tháng/////////////////
export const checkLastDayOfMonth = (dateValue) => {
  const date = new Date(dateValue).getDate();
  const month = new Date(dateValue).getMonth();
  const year = new Date(dateValue).getFullYear();
  const lastDay = new Date(year, month + 1, 0).getDate();
  if (date === lastDay) {
    return true;
  }
  return false;
};

/*Hàm tính khoảng cách giữa 2 ngày trong javascript*/
export const numberDayToDate = (dateStart, dateEnd) => {
  if (dateStart && dateEnd) {
    let timeDateStart = new Date(dateStart).getTime();
    let timeDateEnd = new Date(dateEnd).getTime();
    return Math.trunc((timeDateEnd - timeDateStart) / (24 * 60 * 60 * 1000));
  }
};

export const escapeRegExp = (string) => {
  // return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  // const tagsRegex = new RegExp(/<[^>]*>/gm);
  // const text = string.replace(tagsRegex, '').replace(/[`~!@#$%^&*()|+\-='"<>\{\}\[\]\\\/]/gi, '');
  return string;
};
export const resetMilliseconds = (date) => {
  return date.setMilliseconds(0);
};

export const getLastDayOfMonth = (date) => {
  // Tạo một đối tượng Date với ngày 0 của tháng tiếp theo
  return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDay();
}
export const getFirstDayOfMonth = (date) => {
  const d = new Date(date);
  // Get the first day of the month
  const firstDay = new Date(d.getFullYear(), d.getMonth(), 1).getDay();
  return firstDay;
}

export const getWeekOfMonth = (date) => {
  const newDate = new Date(date)

  // Lấy ngày, tháng, năm từ đối tượng date
  const dayOfMonth = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();

  // Lấy thứ trong tuần
  const dayOfWeek = newDate.getDay(); // 0 = Chủ Nhật, 1 = Thứ Hai, ..., 6 = Thứ Bảy

  // Tính số lần xuất hiện của thứ trong tháng
  let occurrence = 0;
  for (let day = 1; day <= dayOfMonth; day++) {
      const currentDate = new Date(year, month, day);
      if (currentDate.getDay() === dayOfWeek) {
          occurrence++;
      }
  }

  return occurrence;
};

export const DownloadCSV = (title, dataHeader, dataValue, data) => {
  var csv = dataHeader.join(',');
  if(!data || data == null || data == undefined || data.length <= 0){
    alert("案件別のデータが見つかりません。");
    return;
  }
      
  for (let rowIdx = 0; rowIdx < data.length; rowIdx++) {
    let row = data[rowIdx];
    csv += "\r\n";
    for(let colIdx = 0; colIdx < dataValue.length; colIdx++){
      csv += (colIdx > 0 ? ",": "") + (row[dataValue[colIdx]] ? row[dataValue[colIdx]] : '');
    }
  }

  var blob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
  var url = URL.createObjectURL(blob);


  var hiddenElement = document.createElement('a');
  hiddenElement.href = url
  hiddenElement.target = '_blank';
  
  //provide the name for the CSV file to be downloaded
  hiddenElement.download = title + '_' + FormatDateString(new Date()) + '.csv';
  hiddenElement.click();
}
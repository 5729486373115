import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PageLogin from './pages/Authentication';
import PageLoginSA from './pages/AuthenticationSA';
import PageResetPassword from './pages/Authentication/PageResetPassword';
import MainPage from './pages/MainPage';
import PageForgotPassword from './pages/Authentication/PageForgotPassword';
import TaskAssign from './components/TaskAssign';
import TaskConfirm from './components/TaskConfirm';
import TaskDueDate from './components/TaskDueDate';
import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import { useEffect } from 'react';

const App = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/login' && 
      (location.pathname.includes('taskAssign') 
        || location.pathname.includes('taskConfirm') 
        || location.pathname.includes('taskDueDate')
        || location.pathname.includes('processing')
        || location.pathname.includes('run')
        || location.pathname.includes('done')
        || location.pathname.includes('task')
      )) {
      sessionStorage.setItem('history_location',location.pathname + location.search)
    }

  }, [location]);
  return (
    <div className='app'>
      <ConfigProvider locale={jaJP}>
        <Routes>
          <Route exact path='/login' element={<PageLogin />} />
          <Route exact path='/loginsa' element={<PageLoginSA />} />
          <Route exact path='/resetpassword' element={<PageResetPassword />} />
          <Route exact path='/forgotpassword' element={<PageForgotPassword />} />
          <Route exact path='/taskAssign' element={<TaskAssign />} />
          <Route exact path='/taskConfirm' element={<TaskConfirm />} />
          <Route exact path='/taskDueDate' element={<TaskDueDate />} />
          <Route exact path='/*' element={<MainPage />} />
        </Routes>
      </ConfigProvider>
      <ToastContainer limit={5} newestOnTop={true} />
    </div>
  );
};
export default App;

export const COMMON_MESSAGES = {
  AUTH: {
    PASSWORDERROR: '英大文字、英小文字、数字、特殊記号 を混在させた10文字以上の文字列',
    // PASSWORDERROR: 'パスワードは不正です。(※10文字以上、英大文字、英小文字、英数字、特殊記号含め）',
    PASSWORDEMTY: 'パスワードを入力してください。',
    USERNAMEEMTY: 'ID(メールアドレス)を入力してください。',
    USERNAMEERROR: 'ID(メールアドレス)が正しくありません。',
    CHANGEPASSWORD: 'パスワードを再度設定してください。',
  },
  PROCESS: {
    // NAMETASK: 'このタスク名は既に使用されています。',
    NAMETASK: '同じタスク名を複数のタスクに設定できない。',
    NOTEMTY: '入力必須です。',
    MAXTASK: '追加できるタスクの上限です。',
    EXECNODATA: '既に削除されています。',
  },
  HRM: {
    USER: {
      select_one_row: 'データを選択してください。',
    },
    GROUP: {
      select_one_row: 'データを選択してください。',
      name_in_use: '属性名は既に使用されています。',
    },
  },
};

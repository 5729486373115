import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

ModalConfirm.propTypes = {};

function ModalConfirm(props) {
  const { textConfirm,textConfirmBottom,textConfirmMid,textConfirmMid1, handleSubmit, open, handleCancelConfirm } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleOk = () => {
    if (handleSubmit) {
      handleSubmit();
      setShowModal(false);
    }
  };
  const handleCancel = () => {
    if (handleCancelConfirm) {
      handleCancelConfirm();
      setShowModal(false);
      return;
    }
    setShowModal(false);
    navigate('.');
  };

  useEffect(() => {
    try {
      if (location.pathname.includes('delete') || open) {
        setShowModal(true);
      }
    } catch (error) {}
  }, [location.pathname, open]);

  return (
    <Modal
      className=''
      maskClosable={false}
      closable={true}
      title=''
      open={showModal}
      onCancel={() => {
        handleCancel();
      }}
      forceRender
      footer={[
        <div key={0} className='modle__user__btn flex__box flex__jus__c'>
          <Button
            // disabled={spinning}
            className='i3link__btn bg__red'
            onClick={() => {
              handleCancel();
            }}
          >
            キャンセル
          </Button>
          <Button
            // disabled={spinning}
            className='i3link__btn bg__blue'
            onClick={() => {
              handleOk();
            }}
          >
            OK
          </Button>
        </div>,
      ]}
    >
      <p style={{marginBottom:0}}>{textConfirm ? textConfirm : 'confirm'}</p>
     
      <p style={{marginBottom:0}}>
      {textConfirmMid}
      </p>
      <p style={{marginBottom:0}}>
      {textConfirmMid1}
      </p>
    
      <p style={{marginBottom:0}}>{textConfirmBottom}</p>
     
    </Modal>
  );
}

export default ModalConfirm;

import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import logo from '../../assets/logo.jpg';
import { LIST_NAV } from '../../commons/commonNav/CommonsNav';
import processSlice from '../Process/reduxProcess/processSlice';
import './css/style.css';
import teamSlice from './reduxTeam/teamSlice';
import useWindowWidth from '../../hooks/useWindowWidth/useWindowWidth';
const { Sider } = Layout;
I3linkSider.propTypes = {};

function I3linkSider(props) {
  const [listTeam, setListTeam] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [navActive, setNavActive] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  ////////////////////////////////////////////////

  const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });
  const windowWidth = useWindowWidth();
  

  ///////////////////////////////////////////////
  const handleConfirm = (item) => {
    if (location.pathname.includes('process/new') || location.pathname.includes('process/update') || location.pathname.includes('process/admin') || location.pathname.includes('/scheduler')) {
      const pathname = location.pathname;
      const searchQuery = location.search;
      navigate({
        pathname: pathname,
        search: searchQuery,
      });
      setNavActive({ ...item });
      setShowModal(true);
      return;
    }
    if (item.to === '/login') {
      localStorage.setItem('flag_history_location','0')
      localStorage.removeItem('history_location')
      const pathname = location.pathname;
      navigate(pathname);
      if (isSuperAdmin == 1) {
        setNavActive({ to: '/loginsa' });
      } else {
        setNavActive({ to: '/login' });
      }
      setShowModal(true);
      return;
    }
    navigate(`${item.to}`);
    return;
  };

  const handleOk = () => {
    if (location.pathname.includes('process/new') || location.pathname.includes('process/update')) {
      localStorage.removeItem('process');
      const action = processSlice.actions.newProcess({});
      dispatch(action);
    }
    setShowModal(false);
    navigate(`${navActive.to}`);
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleRenderMess = () => {
    if (location.pathname.includes('process/update') || location.pathname.includes('/new') || location.pathname.includes('/scheduler')) {
      return (
        <>
          <h2 style={{ marginBottom: 0 }}>現在設定中の内容は破棄されます。</h2>
          <h2> 設定を終了してよろしいですか？</h2>
        </>
      );
    }
    if (location.pathname.includes('process/admin')) {
      return (
        <>
          <h2 style={{ marginBottom: 0 }}> 現在設定中の内容は破棄されます。</h2>
          <h2> 設定を終了してよろしいですか？</h2>
        </>
      );
    }
    return (
      <>
        <h2> ログアウトしてよろしいですか？</h2>
      </>
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const userCreate = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
        if (userCreate === null || !userCreate.user_id) {
          navigate('/login');
        }
        setIsSuperAdmin(localStorage.getItem('isSuperAdmin'));
        const teamInfo = localStorage.getItem('teamInfo') ? JSON.parse(localStorage.getItem('teamInfo')) : null;

        if (teamInfo) {
          const action = teamSlice.actions.addTeam({ listTeam: teamInfo });
          dispatch(action);
          localStorage.setItem('listTeam', JSON.stringify(teamInfo[0]));
          setListTeam(teamInfo);
        }
      } catch (error) {
        navigate('/login');
      }
    })();
  }, []);
  return (
    <Sider
      theme='light'
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 35px)',
        position: 'sticky',
        left: 0,
        top: '35px',
        bottom: 0,
      }}
      breakpoint='xxl'
      width={150}
      collapsedWidth={windowWidth < 768 ? '0' : '150'}
    >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', background: '#fff' }}>
        <div className='logo' style={{ width: '100%',height:'64px' }}>
          <div
            onClick={() => {
              handleConfirm({ to: '/' });
              return;
            }}
          >
            <Link to={`/process`}>
              <img alt='logoI3links' src={logo} style={{ width: '100%' }} />
            </Link>
          </div>
        </div>


        <div className='sider_navigation' style={{ display: 'flex', flexDirection: 'column' }}>
          <ul className='sider_menu'>
            {LIST_NAV.list_menu.map((item, index) => {
              if (item.name) {
                return (
                  (
                    <li key={uuidv4()} className='sider_menu_item sider_submenu_menu overlay' style={{ position: 'relative' }}  >
                      <a className={location.pathname.split('/').includes(item.to.split('/')[1]) ? 'active' : ''} onClick={() => {
                        if(item.to){
                          handleConfirm(item)
                        }
                      return;
                    }} >
                        <span className='sider_menu_icon'>{item.icon}</span>
                        <span className='sider_menu_name'>{item.name}</span>
                      </a>
                      <div className='sider_submenu_box'>
                        <ul className='sider_submenu'>
                          {item?.submenu?.map((subItem) => {
                            return (
                              <li
                                key={uuidv4()}
                                className='sider_submenu_item'
                                onClick={(event) => {
                                  event.stopPropagation(); 
                                  handleConfirm(subItem)
                                   return;
                                 }}
                              >
                                <a className={location.pathname.includes(subItem.to) ? 'active' : ''} >
                                  <span className='sider_menu_icon'>{subItem.icon}</span>
                                  <span className='sider_menu_name'>{subItem.name}</span>
                                </a>                       
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  )
                )
              } else {
                return <li key={uuidv4()} className='sider_menu_item flex_1'></li>;
              }
            })}
          </ul>
            <Modal
              className=''
              maskClosable={false}
              closable={true}
              title=''
              open={showModal}
              onCancel={() => {
                handleCancel();
              }}
              forceRender
              footer={[
                <div key={uuidv4() + 1} className='modle__user__btn flex__box flex__jus__c'>
                  <Button
                    className='i3link__btn bg__red'
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button
                    className='i3link__btn bg__blue'
                    onClick={() => {
                      handleOk();
                    }}
                  >
                    OK
                  </Button>
                </div>,
              ]}
            >
              {handleRenderMess()}
            </Modal>
        </div>
      </div>
    </Sider>
  );
}

export default I3linkSider;

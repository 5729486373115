import React, { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import { Button, Col, Input, InputNumber, Modal, Popover, Radio, Row, Select, Spin } from 'antd';
import { FaList, FaPlus, FaRegTimesCircle } from 'react-icons/fa';
import TimeSelection from './TimeSelection';
import { formatDateJP } from '../../../../../commons/commonFunctions/FormatDate';
import moment from 'moment';
import { numberDayToDate } from '../../../../../commons/commonFunctions/CommonFunction';

RemindTask.propTypes = {};
const defaultListRemind = [
  {
    dateStart: null,
    notification_loop_value: null,
    notification_loop_num: null,
    notification_loop_interval: '5',
    notification_start_type:null,
  },
];
const calculateTimeDifference = (startTime, endTime) => {
  if (startTime && endTime) {
    const newEndTime = `${endTime._d}`
    const dateDiff = moment.duration(moment(newEndTime).diff(moment(startTime)));
    const newTimeStart = {
      notification_start_hour_value: dateDiff.hours(),
      notification_start_minutes_value: dateDiff.minutes(),
      notification_start_day_value: numberDayToDate(startTime, newEndTime),
    };
    return newTimeStart;
  }
  return {
    notification_start_hour_value: 0,
    notification_start_minutes_value: 0,
    notification_start_day_value: 0,
  };
};

const renderMaxNumberInput = (calculateTimeDifference, optionRemind) => {
  const { notification_start_hour_value, notification_start_minutes_value, notification_start_day_value } = calculateTimeDifference;
  if (notification_start_hour_value || notification_start_minutes_value || notification_start_day_value) {
    if (+optionRemind === 3) {
      const maxNumber = Math.abs(notification_start_day_value);
      return maxNumber > 99 ? 99 : maxNumber;
    }
    if (+optionRemind === 4) {
      const maxNumber = Math.abs(notification_start_day_value * 24) + Math.abs(notification_start_hour_value);
      return maxNumber > 99 ? 99 : maxNumber;
    }
    if (+optionRemind === 5) {
      const maxNumber = Math.abs(notification_start_day_value * 24 * 60) + Math.abs(notification_start_hour_value) * 60 + Math.abs(notification_start_minutes_value);
      return maxNumber > 99 ? 99 : maxNumber;
    }
  }
  return 0;
};

const renderMaxNumberInput2 = (calculateTimeDifference, optionRemind, valueNum) => {
  const { notification_start_hour_value, notification_start_minutes_value, notification_start_day_value } = calculateTimeDifference;
  // const check = valueNum === null ? false : valueNum === '0' ? false : !valueNum ? false : true
  const check = Boolean(valueNum) && valueNum !== '0';

  if ((notification_start_hour_value || notification_start_minutes_value || notification_start_day_value) && check) {
    if (+optionRemind === 3) {
      const maxNumber = Math.trunc(Math.abs(notification_start_day_value) / valueNum);
      return maxNumber > 10 ? 10 : maxNumber + 1;
    }
    if (+optionRemind === 4) {
      const maxNumber = Math.trunc((Math.abs(notification_start_day_value * 24) + Math.abs(notification_start_hour_value)) / valueNum);
      return maxNumber > 10 ? 10 : maxNumber + 1;
    }
    if (+optionRemind === 5) {
      const maxNumber = Math.trunc((Math.abs(notification_start_day_value * 24 * 60) + Math.abs(notification_start_hour_value) * 60 + Math.abs(notification_start_minutes_value)) / valueNum);
      return maxNumber > 10 ? 10 : maxNumber + 1;
    }
  }
  return 0;
};
const generateNextDatesTimes = (value) => {
  const listNextRemind = [];
  if (value.notification_loop_num && value.dateStart) {
    listNextRemind.push(value.dateStart);
    for (let index = 1; index < value.notification_loop_num; index++) {
      const element = value;
      if (+element.notification_loop_interval === 3) {
        const newDate = moment(listNextRemind[listNextRemind.length - 1]).add(value.notification_loop_value, 'days');
        listNextRemind.push(newDate);
        continue;
      }
      if (+element.notification_loop_interval === 4) {
        const newDate = moment(listNextRemind[listNextRemind.length - 1]).add(value.notification_loop_value, 'hours');
        listNextRemind.push(newDate);
        continue;
      }
      if (+element.notification_loop_interval === 5) {
        const newDate = moment(listNextRemind[listNextRemind.length - 1]).add(value.notification_loop_value, 'minutes');
        listNextRemind.push(newDate);
        continue;
      }
    }
  }
  return listNextRemind;
};
function RemindTask(props) {
  const { open, handleCancelRemind, timeStartTask, handleSetRemindTask, notification_start_type, value } = props;
  const [listRemind, setListRemind] = useState(() => {
    return defaultListRemind;
  });
  const [optionRemind, setOptionRemind] = useState(() => {
    return 0;
  });
  const [clearTime, setClearTime] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const hide = () => {
    setOpenPopover(false);
  };

  const calculateTimeDifferenceValue = calculateTimeDifference(timeStartTask, listRemind[0].dateStart);

  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };
  const handleSelectTime = (value) => {
    const { indexValue, time } = value;
    if (indexValue === undefined) return;
    // Chỉ cập nhật phần tử có index cần thiết
    setListRemind((prevList) =>
      prevList.map((item, index) => {
        if (index === indexValue) {
          //////////////////////////////
          const maxNumberLoopValue = renderMaxNumberInput(calculateTimeDifference(timeStartTask, time), item.notification_loop_interval);
          let newItem = { ...item, dateStart: time, notification_loop_value: time ? item.notification_loop_value : '0', notification_loop_num: time ? item.notification_loop_num : null, notification_loop_interval: '5' }; // Cập nhật phần tử tại indexValue
          if (+item.notification_loop_value > maxNumberLoopValue) {
            newItem.notification_loop_value = `${maxNumberLoopValue}`;
          }
          if (+item.notification_loop_value > maxNumberLoopValue && maxNumberLoopValue !== 0) {
            newItem.notification_loop_value = `${maxNumberLoopValue}`;
          }
          if (+item.notification_loop_value === 0 && maxNumberLoopValue > 0) {
            newItem.notification_loop_value = `${maxNumberLoopValue}`;
          }
          const maxNumberLoopNum = renderMaxNumberInput2(calculateTimeDifference(timeStartTask, time), item.notification_loop_interval, newItem.notification_loop_value);
          newItem.notification_loop_num = `${maxNumberLoopNum}`;
          return newItem; // Cập nhật phần tử tại indexValue
        }
        return item; // Không cần phải tạo lại đối tượng nếu không thay đổi
      })
    );
    setClearTime(false);
  };
  const handleChangeNum = (value) => {
    const { indexValue, num } = value;
    if (!num || indexValue === undefined) return;
    // Chỉ cập nhật phần tử có index cần thiết
    setListRemind((prevList) =>
      prevList.map((item, index) => {
        if (index === indexValue) {
          return { ...item, notification_loop_num: num }; // Cập nhật phần tử tại indexValue
        }
        return item; // Không cần phải tạo lại đối tượng nếu không thay đổi
      })
    );
    setClearTime(false);
  };
  const handleChangeValue = (value) => {
    const { indexValue, valueNum } = value;
    if (!valueNum || indexValue === undefined) return;
    // Chỉ cập nhật phần tử có index cần thiết
    setListRemind((prevList) =>
      prevList.map((item, index) => {
        if (index === indexValue) {
          const maxNumberLoopNum = renderMaxNumberInput2(calculateTimeDifference(timeStartTask, item.dateStart), item.notification_loop_interval, valueNum);
          // console.log(maxNumberLoopNum);

          let newItem = { ...item, notification_loop_value: valueNum };
          // if (+item.notification_loop_num > maxNumberLoopNum) {
          //   newItem.notification_loop_num = `${maxNumberLoopNum}`;
          // }
          // if (+item.notification_loop_num > maxNumberLoopNum && maxNumberLoopNum !== 0) {
          //   newItem.notification_loop_num = `${maxNumberLoopNum}`;
          // }
          // if (+item.notification_loop_num === 0 && maxNumberLoopNum > 0) {
          //   newItem.notification_loop_num = `${maxNumberLoopNum}`;
          // }
          newItem.notification_loop_num = `${maxNumberLoopNum}`;
          return newItem; // Cập nhật phần tử tại indexValue
        }
        return item; // Không cần phải tạo lại đối tượng nếu không thay đổi
      })
    );
    setClearTime(false);
  };
  const handleChangeinterval = (value) => {
    const { indexValue, interval } = value;
    if (!interval || indexValue === undefined) return;
    // Chỉ cập nhật phần tử có index cần thiết
    setListRemind((prevList) =>
      prevList.map((item, index) => {
        if (index === indexValue) {
          const maxNumberLoopValue = renderMaxNumberInput(calculateTimeDifference(timeStartTask, item.dateStart), interval);
          // const maxNumberLoopNum = renderMaxNumberInput2(calculateTimeDifference(timeStartTask, item.dateStart), interval, maxNumberLoopValue);
          let newItem = { ...item, notification_loop_interval: interval };
          if (+item.notification_loop_value > maxNumberLoopValue) {
            newItem.notification_loop_value = `${maxNumberLoopValue}`;
          }
          if (+item.notification_loop_value > maxNumberLoopValue && maxNumberLoopValue !== 0) {
            newItem.notification_loop_value = `${maxNumberLoopValue}`;
          }
          if (+item.notification_loop_value === 0 && maxNumberLoopValue > 0) {
            newItem.notification_loop_value = `${maxNumberLoopValue}`;
          }
          const maxNumberLoopNum = renderMaxNumberInput2(calculateTimeDifference(timeStartTask, item.dateStart), interval, newItem.notification_loop_value);
          /////////////////////////////
          // if (+item.notification_loop_num > maxNumberLoopNum) {
          //   newItem.notification_loop_num = `${maxNumberLoopNum}`;
          // }
          // if (+item.notification_loop_num > maxNumberLoopNum && maxNumberLoopNum !== 0) {
          //   newItem.notification_loop_num = `${maxNumberLoopNum}`;
          // }
          // if (+item.notification_loop_num === 0 && maxNumberLoopNum > 0) {
          //   newItem.notification_loop_num = `${maxNumberLoopNum}`;
          // }
          newItem.notification_loop_num = `${maxNumberLoopNum}`;
          return newItem; // Cập nhật phần tử tại indexValue
        }
        return item; // Không cần phải tạo lại đối tượng nếu không thay đổi
      })
    );
    setClearTime(false);
  };

  const handleOk = () => {
    if (handleSetRemindTask) {
      const intervalMultiplier = (() => {
        switch (+listRemind[0].notification_loop_interval) {
          case 3:
            return +listRemind[0].notification_loop_value*1440; // 3 -> phút trong một ngày
          case 4:
            return +listRemind[0].notification_loop_value*60;  // 4 -> phút trong một giờ
          default:
            return +listRemind[0].notification_loop_value;   // Nếu không phải 3 hoặc 4 thì dùng mặc định
        }
      })();
      const calculateTimeDifferenceValue = calculateTimeDifference(timeStartTask, listRemind[0].dateStart);
      const value = {...listRemind[0], optionRemind, ...calculateTimeDifferenceValue, notification_loop_value:intervalMultiplier };
      handleSetRemindTask(value);
    }
  };
  const handleRemoveRemind = (indexValue) => {
    if (indexValue === undefined || indexValue < 0 || indexValue >= listRemind.length) return;

    const newListRemind = listRemind.filter((_, index) => index !== indexValue); // Lọc bỏ phần tử ở indexValue
    const newDefaultListRemind = [{...value,...defaultListRemind[0],notification_start_type:listRemind[0].notification_start_type}]
    
    // Cập nhật danh sách sau khi xóa phần tử
    setListRemind(newListRemind.length === 0 ? newDefaultListRemind : newListRemind);
    setClearTime(true);
  };
  // const handleAddRemind = () => {
  //   if (listRemind.length === 3) return;
  //   setListRemind((prevList) => [...prevList, defaultListRemind]); // Cập nhật mảng dựa trên trạng thái cũ
  // };
  

  useEffect(() => {
    if (value) {
      setListRemind([
        {
          ...value,
          dateStart: value.dateStart ? moment(`${value.dateStart._d}`) : null,
          notification_loop_value: value.notification_loop_value,
          notification_loop_num: value.notification_loop_num,
          notification_loop_interval: value.notification_loop_interval,
          notification_start_type:value.notification_start_type
        },
      ]);
      setOptionRemind(value.optionRemind);
    }
  }, [open]);  
  return (
    <>
      <Modal
        open={open}
        width={1150}
        footer={
          <Row justify={'center'}>
            <Col>
              <Button
                className='i3link__btn common__process__control--btn bg__blue'
                onClick={() => {
                  handleOk();
                }}
              >
                設定
              </Button>
            </Col>
          </Row>
        }
        onCancel={handleCancelRemind}
      >
        <Spin spinning={false} size='large' className='process_content_spining'>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <div>
                <h2 className=''>設定された予定日時の事前に送信するリマインド通知の登録が行えます。</h2>
                <h2 className=''>{value.type_setting ?  `開始予定日時`  :`終了予定日時`}  　{formatDateJP(timeStartTask)}</h2>
              </div>
            </Col>
            <Col span={24}>
              <div className=''>
                <Row gutter={[10, 10]}>
                  <Col>
                    <span>通知の送信先</span>
                  </Col>
                  <Col>
                    <Radio.Group
                      onChange={(value) => {
                        setOptionRemind(value.target.value);
                      }}
                      value={optionRemind}
                    >
                      <Radio value={0}>タスクの担当者のみ</Radio>
                      <Radio value={1}>タスクの担当者とプロセス管理者</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <Row gutter={[10, 10]}>
                  {listRemind.map((item, index) => {
                    return (
                      <Col span={24} key={index}>
                        <div>
                          <Row gutter={[10, 10]} justify='space-between' align='middle'>
                            <Col>
                              <p>通知条件 {index + 1}</p>
                            </Col>
                            <Col>
                              <div style={{ border: '3px solid #00a199', padding: 10 }}>
                                <Row gutter={[10, 10]} align='middle'>
                                  <Col>
                                    <div>
                                      <p>※予定日時からの差分を自動計算して設定します</p>
                                      <TimeSelection clearTime={clearTime} timeStartTask={timeStartTask} value={listRemind[0].dateStart} indexValue={index} handleSelectTime={handleSelectTime} />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                                      <span style={{ minWidth: 50 }}>
                                        {item.dateStart ? Math.abs(calculateTimeDifferenceValue.notification_start_day_value) : '00'}日 <br />
                                        {item.dateStart ? Math.abs(calculateTimeDifferenceValue.notification_start_hour_value) : '00'}時間 <br />
                                        {item.dateStart ? Math.abs(calculateTimeDifferenceValue.notification_start_minutes_value) : '00'}分
                                      </span>
                                      <span>前を基準に</span>
                                      <Select
                                        value={item.notification_loop_value}
                                        style={{
                                          width: 80,
                                        }}
                                        onChange={(value) => {
                                          handleChangeValue({ indexValue: index, valueNum: value });
                                        }}
                                        options={
                                          renderMaxNumberInput(calculateTimeDifferenceValue, item.notification_loop_interval) === 0
                                            ? [
                                                {
                                                  value: `0`,
                                                  label: `0`,
                                                },
                                              ]
                                            : new Array(renderMaxNumberInput(calculateTimeDifferenceValue, item.notification_loop_interval)).fill(null).map((item, index) => {
                                                return {
                                                  value: `${index + 1}`,
                                                  label: `${index + 1}`,
                                                };
                                              })
                                        }
                                      />
                                      <Select
                                        value={item.notification_loop_interval}
                                        style={{
                                          width: 100,
                                        }}
                                        onChange={(value) => {
                                          handleChangeinterval({ indexValue: index, interval: value });
                                        }}
                                        options={[
                                          {
                                            value: '5',
                                            label: '分',
                                          },
                                          {
                                            value: '4',
                                            label: '時',
                                          },
                                          {
                                            value: '3',
                                            label: '日',
                                          },
                                        ]}
                                      />
                                      <span>間隔で</span>
                                      <Select
                                        value={+item.notification_loop_num || '1' }
                                        style={{
                                          width: 80,
                                        }}
                                        onChange={(value) => {
                                          handleChangeNum({ indexValue: index, num: value });
                                        }}
                                        options={
                                          renderMaxNumberInput2(calculateTimeDifferenceValue, item.notification_loop_interval, item.notification_loop_value) === 0
                                            ? [
                                                {
                                                  value: `1`,
                                                  label: `1`,
                                                },
                                              ]
                                            : new Array(renderMaxNumberInput2(calculateTimeDifferenceValue, item.notification_loop_interval, item.notification_loop_value)).fill(null).map((item, index) => {
                                                return {
                                                  key: `${index + 1}`,
                                                  value: `${index + 1}`,
                                                  label: `${index + 1}`,
                                                };
                                              })
                                        }
                                      />
                                      <span>回 繰り返し送信</span>
                                      <span style={{ cursor: 'pointer' }}>
                                        <div className='scale_middle'>
                                          <Popover
                                            placement='bottomLeft'
                                            content={
                                              <div onClick={hide}>
                                                {generateNextDatesTimes(item).map((item, index) => {
                                                  return (
                                                    <>
                                                      <span>{formatDateJP(new Date(item))} </span> <br />
                                                    </>
                                                  );
                                                })}
                                              </div>
                                            }
                                            title='リマインド通知シミュレーション'
                                            trigger='click'
                                            open={openPopover}
                                            onOpenChange={handleOpenChange}
                                          >
                                            <FaList size={20} />
                                          </Popover>
                                        </div>
                                      </span>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      className='scale_middle'
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleRemoveRemind(index);
                                      }}
                                    >
                                      <FaRegTimesCircle size={30} />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
}

export default RemindTask;

import axiosClient from './axiosClient';
import queryString from 'query-string';

export const scheduleApi = {
  
  getById(params) {
    const searchParam = queryString.stringify(params)
    const url = `/scheduler/${searchParam}`;
    return axiosClient.get(url, { params });
  },
  
};

import { Button, Col, Image, Input, Modal, Row, Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trn_tasksApi } from '../../api/trn_tasksApi';
import logo from '../../assets/logo.jpg';
import { formatDateJP } from '../../commons/commonFunctions/FormatDate';
import './Css/style.css';

TaskConfirm.propTypes = {};

function TaskConfirm(props) {
  const [task, setTask] = useState({});
  const [respond, setRespond] = useState("");
  const [spin,setSpin] = useState(false)
  const [searchStatus,setSearchStatus] = useState(null)
  const [openNew, setOpenNew] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [openDone, setOpenDone] = useState(false);
  const [openNotUser, setOpenNotUser] = useState(false);
  
  const teamInfo = localStorage.getItem('teamInfo') ? JSON.parse(localStorage.getItem('teamInfo')) : [];

  const location = useLocation() 
  const navigation = useNavigate();
  
  useEffect(()=>{
    const accessToken = localStorage.getItem('accessToken');
    const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    if (!accessToken || !user) {
      navigation('/login');
      return;
    }
    async function fetchData(){
      var filter = queryString.parse(location.search)
      if(filter.trn_manage_id || filter.task_id){
        try {
          const param = {trn_manage_id: filter.trn_manage_id, task_id: filter.task_id, user_id: user.user_id}
          const res = await trn_tasksApi.getTaskConfirm(param);
          if(res.data){
            //if(user.user_id !== newdata.data.user_id){
            const search_status = res.data.search_status;
            const process_name = (teamInfo.length <= 0 || teamInfo[0].team_id != res.data.team_id ? '' : res.data.process_name);
            const process_start_achievement = (teamInfo.length <= 0 || teamInfo[0].team_id != res.data.team_id ? '' : formatDateJP(new Date(res.data.process_start_achievement)));
            const task_name = (teamInfo.length <= 0 || teamInfo[0].team_id != res.data.team_id ? '' : res.data.task_name);
            setSearchStatus(search_status);
            if(search_status == 0){
              if(res.data.status == '5'){
                setTask({
                  process_name: process_name,
                  process_start_achievement: process_start_achievement,
                  team_id: res.data.team_id,
                  trn_manage_id: res.data.trn_manage_id,
                  task_id: res.data.task_id,
                  user_id: user.user_id,
                  task_name: task_name,
                  task_content: res.data.task_content,
                  parts_input_value: res.data.parts_input_value,
                })
                setOpenNew(true)
              }
              else if(res.data.status == '10'){
                setTask({
                  process_name: process_name,
                  process_start_achievement: process_start_achievement,
                  task_id: res.data.task_id,
                  task_name: task_name,
                })
                setOpenDone(true)
              }
              else{
                setTask({})
                setOpenNotUser(true)
              }
            }
            else if(search_status == -1 || search_status == 99){
              setTask({
                process_name: process_name,
                process_start_achievement: process_start_achievement,
                task_id: res.data.task_id,
                task_name: task_name,
              })
              setOpenNotUser(true)
            }
            else if(search_status == -2){
              setTask({})
              setOpenNotUser(true)
            }
          }
          else{
            setTask({})
            setOpenNotUser(true)
          }
        } catch (error) {
            console.log(error);
        }
      }
      else{
        setOpenNotUser(true)      
        //let closeWindows = window.open("/taskConfirm","_self");
        //closeWindows.close();
        window.close()
      }
    }
    fetchData();
  },[location.pathname])
  
  const handleSubmit = async()=>{
    try {
      setSpin(true)
      const body = {...task, parts_input_value: respond} 
      const result = await trn_tasksApi.updateTaskConfirmRespond(+task.task_id, body);
      if (result.success && !toast.isActive({toastId:'toastId_err'})) {
        toast.success('正常に登録されました。', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
        setSpin(false)
        setOpenNew(false);
        setOpenSubmit(true);
      } else if (result.validate) {
        if(result.validate == 0){
          setSpin(false)
          setOpenNew(false);
          setOpenDone(true);
        }
      } else {
        setSpin(false)
        toast.error(result.error, {
          toastId:'toastId_err',
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: true,
          hideProgressBar: true,
        });
      }

    } catch (error) {
      setSpin(false)
      console.log(error);
    }
  };
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [fontSize, setFontSize] = useState(0);
  const [lineSpace, setLineSpace] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, []);
  
  useEffect(() => {
    setFontSize(windowWidth/40);
    setLineSpace(windowHeight/20);
  }, [windowWidth, windowHeight]);

  return (
    <div className="App">
      
      <Modal
        open={openNew}
        title={
          <Row>
            <Col span={2}><Image height={50} src={logo} preview={false} /></Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}> プロセス名：{task ? task.process_name : ""}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right',fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
              【プロセス開始日時{task ? task.process_start_achievement : ""}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={"100%"}
        header={""}
        footer={null}
        >
        <Spin tip="ローディング．．．" spinning={spin}>   
        <div className="task_content" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}} >
          <Row style={{}}>
            <Col span={2} ></Col>
            <Col span={20}>
              <div className="task_content--title" style={{height:'max-content'}}>
                <h1 className="text-clamp text-clamp--2" style={{fontSize:`${fontSize}px`,lineHeight:'initial',padding:'10px 0px'}}>「{task ? task.task_name : ""}」</h1>
              </div>
              <div className='task_content--content'>
                <div style={{fontSize:`${fontSize / 2}px`}} className="text-clamp text-clamp--2">
                  以下の指示内容に従ってタスクを処理してください。<br/>
                </div>
                <div style={{whiteSpace: 'pre-line', fontSize:`${fontSize / 2}px`}}>
                  {task ? task.task_content : ""}
                </div>
              </div>
            </Col>
            <Col span={2} ></Col>
          </Row>
          <Row style={{}} >
            <Col span={24}>
              <Row>
                <Col span={2}></Col>
                <Col span={20} >
                  <div className='task_content--label' style={{fontSize:`${fontSize / 2}px`}}>備考欄</div>
                  <div>
                    <Input.TextArea 
                      maxLength={3000} 
                      style={{fontSize:`${fontSize / 2}px`}} 
                      onChange={(e) => {setRespond(e.target.value);}} defaultValue={task ? task.parts_input_value : ""}/>
                  </div>
                </Col>
                <Col span={2}></Col>
              </Row>
            </Col> 
          </Row>
          <Row>
            <Col span={2} style={{}} ></Col>
            <Col span={20}> 
              <div className='common__process__bottom--btn' style={{margin:"10px 0px"}}>
                <div style={{display:'flex',justifyContent:'center'}}>
                  {
                    searchStatus !== 99
                    && <Button className='bg__green common__process__control--btn' size='large' onClick={ () => {handleSubmit()}}>完了報告</Button>
                  }
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
        </Spin>
      </Modal>     
      
      <Modal
        open={openSubmit}
        title={
          <Row>
            <Col span={2}><Image height={50} src={logo} preview={false} /></Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{task ? task.process_name : ""}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right',fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
              【プロセス開始日時{task ? task.process_start_achievement : ""}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={"100%"}
        header={""}
        footer={null}
        >
        <div className="task_content" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
          <Row style={{}}>
            <Col span={2}></Col>
            <Col span={20}>
              <div className="task_content--message">
                <div style={{fontSize:`${fontSize}px`,textAlign:'center' }} className="text-clamp text-clamp--2">
                  {task && task.task_name ? '「' + task.task_name + '」' : "タスク"}
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{fontSize:`${fontSize / 2}px`, textAlign: "center"}} className="text-clamp text-clamp--2">
                  このタスクの完了報告を受け付けました。<br/>
                  お疲れ様でした。<br/>
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>       
      </Modal>     
      
      <Modal
        open={openDone}
        title={
          <Row>
            <Col span={2}><Image height={50} src={logo} preview={false} /></Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{task ? task.process_name : ""}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right',fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
              【プロセス開始日時{task ? task.process_start_achievement : ""}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={"100%"}
        header={""}
        footer={null}
        >
        <div className="task_content" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
          <Row style={{}}>
            <Col span={2}></Col>
            <Col span={20}>
              <div className="task_content--message">
                <div style={{fontSize:`${fontSize}px`,textAlign:'center' }} className="text-clamp text-clamp--2">
                  {task && task.task_name ? '「' + task.task_name + '」' : "タスク"}
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{fontSize:`${fontSize / 2}px`, textAlign: "center"}} className="text-clamp text-clamp--2">
                  このタスクは既に完了されています。<br/>
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>   
      
      <Modal
        open={openNotUser}
        title={
          <Row>
            <Col span={2}><Image height={50} src={logo} preview={false} /></Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{task ? task.process_name : ""}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right',fontWeight: 'bold', fontSize: `${fontSize/1.5}px` }}>
              【プロセス開始日時{task ? task.process_start_achievement : ""}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={"100%"}
        header={""}
        footer={null}
        >
        <div className="task_content" style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
          <Row style={{}}>
            <Col span={2}></Col>
            <Col span={20}>
              <div className="task_content--message">
                <div style={{fontSize:`${fontSize}px`,textAlign:'center' }} className="text-clamp text-clamp--2">
                  {task && task.task_name ? '「' + task.task_name + '」' : "タスク"}
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{fontSize:`${fontSize / 2}px`, textAlign: "center"}} className="text-clamp text-clamp--2">
                  このタスクの担当者ではないため完了することはできません。<br/>
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>  
    </div>
  );
}

export default TaskConfirm;

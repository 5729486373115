import { Button, DatePicker, Input, Layout, Space, Spin, Tooltip } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { React, useEffect, useState } from 'react';
import { FaClipboardCheck, FaEraser, FaFilter, FaRegComment } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { referencesApi } from '../../../../api/referencesApi';
import TableI3links from '../../../../commons/commonComponents/tableI3links/TableI3links';
import { FormatDate } from '../../../../commons/commonFunctions/FormatDate';
import useWindowWidth from '../../../../hooks/useWindowWidth/useWindowWidth';
import { teamState } from '../../../../redux/selector';
import TaskDetails from '../TaskDetails';
const { Header, Content } = Layout;
const { RangePicker } = DatePicker;
TaskAdminDone.propTypes = {};

function TaskAdminDone(props) {
  const defaultTitle = '完了済プロセス';
  const teamInfo = useSelector(teamState);
  const [listTask, setListTask] = useState([]);
  const [listTaskFilter, setlistTaskFilter] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [filter, setFilter] = useState(null);
  const [title, setTitle] = useState(defaultTitle);
  const [pagination, setPagination] = useState({
    totals: 1,
    limit: 10,
    page: 1,
  });
  const location = useLocation();
  const navigation = useNavigate();
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

  /////////////////////////////////////////////////////
  const windowWidth = useWindowWidth();
  const [sortFilter, setSortFilter] = useState({
    filterProcessName: '',
    filterAdminName: '',
    filterStatusName: '',
    filterStartTime: [null, null],
    filterEndTime: [null, null],
  });
  const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys[0];
      return { ...newListSort };
    });
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys;
      return { ...newListSort };
    });
  };
  const handleFilterExpire = (value) => {
    setSortFilter(() => {
      return { ...sortFilter, filterIsExpire: value };
    });
  };
  const handleReset = (clearFilters,filterName,confirm) => {
    if(filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality'|| filterName === 'filterEndTimeReality'){
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = [];
        return { ...newListSort };
      });
    }else{
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = '';
        return { ...newListSort };
      });
    }
    confirm()
    clearFilters();
  };
  ///// lọc theo text
  const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={placeholder}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
            icon={<FaFilter />}
            size='small'
            style={{
              width: 90,
            }}
          ></Button>
          <Button
            onClick={() => {
              setSelectedKeys([])
              return clearFilters && handleReset(clearFilters,filterName,confirm)
            }}
            size='small'
            type='primary'
            style={{
              width: 90,
            }}
            icon={<FaEraser />}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });
  ///// lọc theo ngày
  const getColumnSearchDateProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, padding: 10 }}>
          <div>
            <RangePicker
              type=''
              defaultValue={sortFilter.filterNextStartTime}
              value={selectedKeys.length ? selectedKeys.map((date) => (date ? moment(date) : null)) : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? [moment(dates[0]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(), moment(dates[1]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()] : []);
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              onClick={() => {
                handleSearchDate(selectedKeys, confirm, dataIndex, filterName);
              }}
              size='small'
              type='primary'
              style={{ width: 90, marginRight: 8 }}
              icon={<FaFilter />}
            />
            <Button
              onClick={() => {
                setSelectedKeys([])
              return clearFilters && handleReset(clearFilters,filterName,confirm)
              }}
              size='small'
              type='primary'
              style={{ width: 90 }}
              icon={<FaEraser />}
            />
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });
  const Widthtable = windowWidth - 565 - 160;
  const [Columns, setColumns] = useState(() => {
    return [
      {
        title: '',
        align: 'center',
        dataIndex: '',
        key: '1',
        ellipsis: true,
        width: 50,
        render: (item) => {
          return (
            <>
              <span style={{ margin: 0 }}>{`${item.no ? item.no : ''}`} </span>
              <Tooltip title={`${item.notes ? item.notes : ''}`} color='#fff' overlayInnerStyle={{ border: '0.5px solid #ccc', color: '#000', backgroundColor: '#fff', fontSize: 10 }}>
                {item.notes ? <FaRegComment color='#FFC800' size={10} /> : ''}
              </Tooltip>
            </>
          );
        },
      },
      {
        title: 'プロセス名',
        align: 'left',
        dataIndex: '',
        key: '2',
        ellipsis: true,
        width: Widthtable * 0.7,
        sorter: {
          compare: (a, b) => a.process_name?.localeCompare(b.process_name),
          multiple: 1,
        },
        ...getColumnSearchProps('process_name', 'プロセス名', 'filterProcessName'),
        render: (item) => {
          return (
            <a
              onClick={() => {
                setTitle('プロセス詳細画面');
                const search = {
                  // team_id: item.team_id,
                  trn_manage_id: item.trn_manage_id,
                  // type: item.type,
                };
                navigation({
                  pathname: `${location.pathname}`,
                  search: queryString.stringify(search),
                });
                setFilter({
                  team_id: item.team_id,
                  trn_manage_id: item.trn_manage_id,
                  type: item.type,
                });
              }}
            >
              {item.process_name}
            </a>
          );
        },
      },
      { title: 'プロセス管理者', align: 'left', dataIndex: 'process_administrator_head_display', key: '4', ellipsis: true, 
        width: Widthtable * 0.3,
        sorter: {
          compare: (a, b) => a.process_administrator_head_display?.localeCompare(b.process_administrator_head_display),
          multiple: 1,
        },
        ...getColumnSearchProps('process_administrator_head_display', 'プロセス管理者', 'filterAdminName'),
       },
      { title: 'プロセス実行者', align: 'left', dataIndex: 'process_practitioner', key: '5', ellipsis: true, 
        width: 140,
        sorter: {
          compare: (a, b) => a.process_practitioner?.localeCompare(b.process_practitioner),
          multiple: 1,
        },
        ...getColumnSearchProps('process_practitioner', 'プロセス実行者', 'filterStatusName'),
       },
      { title: 'プロセス開始実績日時', align: 'left', dataIndex: 'process_start_achievement_display', key: '3', ellipsis: true, 
        width: 180,
        sorter: {
          compare: (a, b) => new Date(a.process_start_achievement_display) - new Date(b.process_start_achievement_display),
          multiple: 1,
        },
        ...getColumnSearchDateProps('process_start_achievement_display', 'プロセス開始実績日時', 'filterStartTime'),
       },
      { title: 'プロセス終了実績日時', align: 'left', dataIndex: 'process_end_achievement_display', key: '6', ellipsis: true, 
        width: 180,
        sorter: {
          compare: (a, b) => new Date(a.process_end_achievement_display) - new Date(b.process_end_achievement_display),
          multiple: 1,
        },
        ...getColumnSearchDateProps('process_end_achievement_display', 'プロセス終了実績日時', 'filterEndTime'),
       },
      { title: '', align: 'left', dataIndex: '', key: '', ellipsis: true },
    ];
  });

  ///sort khi có thông tin sort thay đổi
  useEffect(() => {
    let dataFilterTasks = [...listTask];
    if (sortFilter.filterProcessName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_name.includes(sortFilter.filterProcessName?.trim()));
    }
    if (sortFilter.filterAdminName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_administrator_head_display.includes(sortFilter.filterAdminName?.trim()));
    }
    if (sortFilter.filterStatusName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_practitioner.includes(sortFilter.filterStatusName?.trim()));
    }
    if (sortFilter.filterStartTime[0] && sortFilter.filterStartTime[1]) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_start_achievement_display && new Date(`${item.process_start_achievement_display}`) >= new Date(sortFilter.filterStartTime[0]) && new Date(`${item.process_start_achievement_display}`) <= new Date(sortFilter.filterStartTime[1]));
    }
    if (sortFilter.filterEndTime[0] && sortFilter.filterEndTime[1]) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_start_achievement_display && new Date(`${item.process_start_achievement_display}`) >= new Date(sortFilter.filterEndTime[0]) && new Date(`${item.process_start_achievement_display}`) <= new Date(sortFilter.filterEndTime[1]));
    }
    if (sortFilter.filterIsExpire) {
      dataFilterTasks = dataFilterTasks.filter((item) => 
        (!item.task_start_achievement && item.task_start_limit_display && new Date(item.task_start_limit) < new Date())
        || (!item.task_end_achievement && item.task_end_limit_display && new Date(item.task_end_limit) < new Date())
      );
    }
    
    setlistTaskFilter(() => {
      return dataFilterTasks.map((item, index) => {
        return { ...item, no: index + 1 };
      });
    });
    setPagination((prevPagination) => {
      return { ...prevPagination };
    });
  }, [sortFilter]);

  /////////////////////////////////////////////////////

  const handlePaginationNexted = (value) => {
    if (value) {
      const newPagination = { ...pagination, ...value };
      setPagination(newPagination);
    }
  };

  useEffect(() => {
    (async () => {
      const filter = queryString.parse(location.search);
      if (filter.trn_manage_id) {
        setTitle('プロセス詳細画面');
        setFilter({
          team_id: teamInfo.listTeam[0]?.team_id,
          user_id: user.user_id,
          trn_manage_id: filter.trn_manage_id,
          type: 2,
        });
      } else {
        setTitle(defaultTitle);
        await refresh();
      }
    })();
  }, [location.pathname, location.search, teamInfo]);

  const refresh = async () => {
    try {
      if (user === null || user.user_id === null) {
        navigation('/login');
        return;
      }
      if (!teamInfo || !teamInfo.listTeam || teamInfo.listTeam.length <= 0) {
        return;
      }
      setSpinning(true);
      const teamQuery = {
        team_id: teamInfo.listTeam[0]?.team_id,
        user_id: user.user_id,
      };
      const { data } = await referencesApi.getAdminDone(teamQuery);
      const dataListTask = [...data]
        .sort((a, b) => {
          return new Date(b.process_end_achievement) - new Date(a.process_end_achievement);
        })
        .map((item, index) => {
          return {
            ...item,
            no: index + 1,
            type: 2,
            process_administrator_head_display: item.process_administrator_head,
            process_start_achievement_display: item.process_start_achievement ? FormatDate(new Date(item.process_start_achievement)) : '',
            process_end_achievement_display: item.process_end_achievement ? FormatDate(new Date(item.process_end_achievement)) : '',
          };
        });
      setListTask(dataListTask);
      setlistTaskFilter(dataListTask)
      setSpinning(false);
    } catch (error) {
      setSpinning(false);
      console.log(error);
    }
  };

  return (
    <>
      <Header className='header__common'>
        <div className='flex__box flex__jus__sb'>
          <div className='header__title'>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
              <FaClipboardCheck size={20} color='#000' />
              <h1 className='header__content__title'>{title + ' '}</h1>
            </div>
          </div>
        </div>
      </Header>
      <Content>
        <div className='common__content flex__box flex__jus__sb flex__gap__cl--20'>
          <div className='' style={{ overflowX: 'hidden', position: 'relative' }}>
            <Spin spinning={spinning} tip='ローディング．．．'>
              {location.search && (
                <>
                  <TaskDetails spinningProp={spinning} filter={filter} />
                  <div>
                    <Button
                      className='bg__blue common__process__control--btn'
                      onClick={() => {
                        setTitle(defaultTitle);
                        setFilter(null);
                        refresh();
                        navigation('/process/done');
                      }}
                    >
                      前に戻る
                    </Button>
                  </div>
                </>
              )}
              {!location.search && 
                <TableI3links 
                  spinning={spinning} 
                  className='common__table title_table_small' 
                  scroll={{ x: '100%' }} 
                  refresh={refresh} 
                  type='radio' 
                  pageSizeOptions={['10', '25', '50', '100']} 
                  dataSource={listTaskFilter} 
                  total={listTaskFilter.length} 
                  columns={Columns} 
                  Pagination={pagination} 
                  handlePaginationNexted={handlePaginationNexted} 
                  // handleFilterAdmin={handleFilterExpire}
                />
              }
            </Spin>
          </div>
        </div>
      </Content>
    </>
  );
}

export default TaskAdminDone;

import axiosClient from './axiosClient';

export const referencesApi = {
  getProcessing(params) {
    const url = '/refProcessing';
    return axiosClient.get(url, { params });
  },
  getPlanning(params) {
    const url = '/refPlanning';
    return axiosClient.get(url, { params });
  },
  getDone(params) {
    const url = '/refDone';
    return axiosClient.get(url, { params });
  },
  getAdminRun(params) {
    const url = '/refAdminRun';
    return axiosClient.get(url, { params });
  },
  getAdminDone(params) {
    const url = '/refAdminDone';
    return axiosClient.get(url, { params });
  },  

  getProcessDtl(params) {
    const url = '/refProcessDtl';
    return axiosClient.get(url, { params });
  },
  getUsers(params) {
    const url = '/refUsers';
    return axiosClient.get(url, { params });
  },
  getTask(params) {
    const url = '/refTask';
    return axiosClient.get(url, { params });
  },

  getTaskDtl(params) {
    const url = '/refTaskDtl';
    return axiosClient.get(url, { params });
  },
  addNote(data) {
    const url = '/refProcessNote';
    return axiosClient.post(url, { data });
  },

};

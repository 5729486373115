import { Button, Layout, Spin, DatePicker, Input, Space } from 'antd';
import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { referencesApi } from '../../../../api/referencesApi';
import { FormatDate } from '../../../../commons/commonFunctions/FormatDate';
import TableI3links from '../../../../commons/commonComponents/tableI3links/TableI3links';
import { teamState } from '../../../../redux/selector';
import TaskDetails from '../TaskDetails';
import { FaRegCalendarAlt, FaEraser, FaFilter } from 'react-icons/fa';
import useWindowWidth from '../../../../hooks/useWindowWidth/useWindowWidth';
import queryString from 'query-string';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Header, Content } = Layout;

TaskPlanning.propTypes = {};

function TaskPlanning(props) {
  const defaultTitle = '予定タスク';
  const teamInfo = useSelector(teamState);
  const [listTask, setListTask] = useState([]);
  const [listTaskFilter, setlistTaskFilter] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [filter, setFilter] = useState(null);
  const [title, setTitle] = useState(defaultTitle);
  const [pagination, setPagination] = useState(() => {
    const PaginationLocal = localStorage.getItem('paginationTaskPlanning')
      ? JSON.parse(localStorage.getItem('paginationTaskPlanning'))
      : {
          totals: 1,
          limit: 10,
          page: 1,
        };
    return PaginationLocal;
  });
  const location = useLocation();
  const navigation = useNavigate();
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  /////////////////////////////////////////////////////
  const windowWidth = useWindowWidth();
  const Widthtable = windowWidth - 725 - 160;

  const [sortFilter, setSortFilter] = useState({
    filterProcessName: '',
    filterTaskName: '',
    filterAdminName: '',
    filterStatusName: '',
    filterStartTime: [null, null],
    filterEndTime: [null, null],
  });
  const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys[0];
      return { ...newListSort };
    });
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex, filterName) => {
    confirm();
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = selectedKeys;
      return { ...newListSort };
    });
  };
  const handleFilterExpire = (value) => {
    setSortFilter(() => {
      return { ...sortFilter, filterIsExpire: value };
    });
  };
  const handleReset = (clearFilters,filterName,confirm) => {
    if(filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality'|| filterName === 'filterEndTimeReality'){
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = [];
        return { ...newListSort };
      });
    }else{
      setSortFilter((prevValue) => {
        const newListSort = { ...prevValue };
        newListSort[filterName] = '';
        return { ...newListSort };
      });
    }
    confirm()
    clearFilters();
  };
  ///// lọc theo text
  const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={placeholder}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
            icon={<FaFilter />}
            size='small'
            style={{
              width: 90,
            }}
          ></Button>
          <Button
            onClick={() => {
              setSelectedKeys([])
              return clearFilters && handleReset(clearFilters,filterName,confirm)
            }}
            size='small'
            type='primary'
            style={{
              width: 90,
            }}
            icon={<FaEraser />}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });
  ///// lọc theo ngày
  const getColumnSearchDateProps = (dataIndex, placeholder, filterName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, padding: 10 }}>
          <div>
            <RangePicker
              type=''
              defaultValue={sortFilter.filterNextStartTime}
              value={selectedKeys.length ? selectedKeys.map((date) => (date ? moment(date) : null)) : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? [moment(dates[0]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(), moment(dates[1]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()] : []);
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              onClick={() => {
                handleSearchDate(selectedKeys, confirm, dataIndex, filterName);
              }}
              size='small'
              type='primary'
              style={{ width: 90, marginRight: 8 }}
              icon={<FaFilter />}
            />
            <Button
              onClick={() => {
                setSelectedKeys([])
              return clearFilters && handleReset(clearFilters,filterName,confirm)
              }}
              size='small'
              type='primary'
              style={{ width: 90 }}
              icon={<FaEraser />}
            />
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FaFilter
        style={{
          color: filtered ? '#000' : undefined,
        }}
      />
    ),
  });

  const [columns, setColumns] = useState(() => {
    return [
      { title: '', align: 'center', dataIndex: 'no', key: '1', ellipsis: true, width: 50 },
      {
        title: 'プロセス名',
        align: 'left',
        dataIndex: '',
        key: '2',
        ellipsis: true,
        width: (Widthtable * 0.3) > 120 ? (Widthtable * 0.3) : 120,
        sorter: {
          compare: (a, b) => a.process_name?.localeCompare(b.process_name),
          multiple: 1,
        },
        ...getColumnSearchProps('process_name', 'プロセス名', 'filterProcessName'),
        render: (item) => {
          return (
            <a
              onClick={() => {
                setTitle('プロセス詳細画面');
                const search = {
                  // team_id: item.team_id,
                  trn_manage_id: item.trn_manage_id,
                  // type: item.type,
                };
                navigation({
                  pathname: `${location.pathname}`,
                  search: queryString.stringify(search),
                });
                setFilter({
                  team_id: item.team_id,
                  trn_manage_id: item.trn_manage_id,
                  type: item.type,
                });
              }}
            >
              {item.process_name}
            </a>
          );
        },
      },
      { title: 'タスク名', align: 'left', dataIndex: 'task_name', key: '3', ellipsis: true, 
        width: (Widthtable * 0.7) > 110 ? (Widthtable * 0.7) : 110,
        sorter: {
          compare: (a, b) => a.task_name?.localeCompare(b.task_name),
          multiple: 1,
        },
        ...getColumnSearchProps('task_name', 'タスク名', 'filterTaskName'),
       },
      {
        title: 'タスク開始予定日時',
        align: 'left',
        dataIndex: '',
        key: '4',
        ellipsis: true,
        width: 180,
        sorter: {
          compare: (a, b) => new Date(a.task_start_limit_display) - new Date(b.task_start_limit_display),
          multiple: 1,
        },
        ...getColumnSearchDateProps('task_start_limit_display', 'タスク開始予定日時', 'filterStartTime'),
        render: (item) => {
          return !item.task_start_achievement_display && new Date(item.task_start_limit_display) < new Date() ? <div style={{ color: 'red' }}>{item.task_start_limit_display}</div> : <div>{item.task_start_limit_display}</div>;
        },
      },
      {
        title: 'タスク終了予定日時',
        align: 'left',
        dataIndex: '',
        key: '5',
        ellipsis: true,
        width: 180,
        sorter: {
          compare: (a, b) => new Date(a.task_end_limit_display) - new Date(b.task_end_limit_display),
          multiple: 1,
        },
        ...getColumnSearchDateProps('task_end_limit_display', 'タスク終了予定日時', 'filterEndTime'),
        render: (item) => {
          return !item.task_end_achievement_display && new Date(item.task_end_limit_display) < new Date() ? <div style={{ color: 'red' }}>{item.task_end_limit_display}</div> : <div>{item.task_end_limit_display}</div>;
        },
      },
      { title: 'プロセス管理者', align: 'left', dataIndex: 'process_administrator_head_display', key: '6', ellipsis: true, 
        width: 150,
        sorter: {
          compare: (a, b) => a.process_administrator_head_display?.localeCompare(b.process_administrator_head_display),
          multiple: 1,
        },
        ...getColumnSearchProps('process_administrator_head_display', 'プロセス管理者', 'filterAdminName'),
       },
      { title: 'プロセス実行者', align: 'left', dataIndex: 'process_practitioner', key: '7', ellipsis: true, 
        width: 150,
        sorter: {
          compare: (a, b) => a.process_practitioner?.localeCompare(b.process_practitioner),
          multiple: 1,
        },
        ...getColumnSearchProps('process_practitioner', 'プロセス実行者', 'filterStatusName'),
       },

      { title: '', align: 'left', dataIndex: '', key: '8', ellipsis: true },
    ];
  });

  ///sort khi có thông tin sort thay đổi
  useEffect(() => {
    let dataFilterTasks = [...listTask];
    if (sortFilter.filterProcessName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_name.includes(sortFilter.filterProcessName?.trim()));
    }
    if (sortFilter.filterTaskName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.task_name.includes(sortFilter.filterTaskName?.trim()));
    }
    if (sortFilter.filterStartTime[0] && sortFilter.filterStartTime[1]) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.task_start_limit_display && new Date(`${item.task_start_limit_display}`) >= new Date(sortFilter.filterStartTime[0]) && new Date(`${item.task_start_limit_display}`) <= new Date(sortFilter.filterStartTime[1]));
    }
    if (sortFilter.filterEndTime[0] && sortFilter.filterEndTime[1]) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.task_end_limit_display && new Date(`${item.task_end_limit_display}`) >= new Date(sortFilter.filterEndTime[0]) && new Date(`${item.task_end_limit_display}`) <= new Date(sortFilter.filterEndTime[1]));
    }
    if (sortFilter.filterAdminName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_administrator_head_display.includes(sortFilter.filterAdminName?.trim()));
    }
    if (sortFilter.filterStatusName?.trim()) {
      dataFilterTasks = dataFilterTasks.filter((item) => item.process_practitioner.includes(sortFilter.filterStatusName?.trim()));
    }
    if (sortFilter.filterIsExpire) {
      dataFilterTasks = dataFilterTasks.filter((item) => 
        (!item.task_start_achievement && item.task_start_limit_display && new Date(item.task_start_limit) < new Date())
        || (!item.task_end_achievement && item.task_end_limit_display && new Date(item.task_end_limit) < new Date())
      );
    }
    setlistTaskFilter(() => {
      return dataFilterTasks.map((item, index) => {
        return { ...item, no: index + 1 };
      });
    });
    setPagination((prevPagination) => {
      return { ...prevPagination };
    });
  }, [sortFilter]);

  ///////////////////////////////////////////////////////////////////////////
  const handlePaginationNexted = (values) => {
    if (values) {
      let newPagination = { ...pagination, ...values };
      localStorage.setItem('paginationTaskPlanning', JSON.stringify(newPagination));
      setPagination(newPagination);
    }
  };
  const refresh = async () => {
    try {
      if (user === null || user.user_id === null) {
        navigation('/login');
        return;
      }
      if (!teamInfo || !teamInfo.listTeam || teamInfo.listTeam.length <= 0) {
        return;
      }
      setSpinning(true);
      const teamQuery = {
        team_id: teamInfo.listTeam[0]?.team_id,
        user_id: user.user_id,
      };
      const { data } = await referencesApi.getPlanning(teamQuery);
      const dataListTask = [...data].map((item, index) => {
        return {
          ...item,
          no: index + 1,
          type: 1,
          process_administrator_head_display: item.process_administrator_head,
          process_start_achievement_display: item.process_start_achievement ? FormatDate(new Date(item.process_start_achievement)) : '',
          process_end_achievement_display: item.process_end_achievement ? FormatDate(new Date(item.process_end_achievement)) : '',
          task_start_limit_display: item.task_start_limit ? FormatDate(new Date(item.task_start_limit)) : '',
          task_start_achievement_display: item.task_start_achievement ? FormatDate(new Date(item.task_start_achievement)) : '',
          task_end_limit_display: item.task_end_limit ? FormatDate(new Date(item.task_end_limit)) : '',
          task_end_achievement_display: item.task_end_achievement ? FormatDate(new Date(item.task_end_achievement)) : '',
        };
      });
      setListTask(dataListTask);
      setlistTaskFilter(dataListTask)
      setSpinning(false);
    } catch (error) {
      setSpinning(false);
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      const filter = queryString.parse(location.search);
      if (filter.trn_manage_id) {
        setTitle('プロセス詳細画面');
        setFilter({
          team_id: teamInfo.listTeam[0]?.team_id,
          user_id: user.user_id,
          trn_manage_id: filter.trn_manage_id,
          type: 1,
        });
      } else {
        setTitle(defaultTitle);
        await refresh();
      }
    })();
  }, [location.pathname, location.search, teamInfo]);

  return (
    <>
      <Header className='header__common'>
        <div className='flex__box flex__jus__sb'>
          <div className='header__title'>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
              <FaRegCalendarAlt size={20} color='#000' />
              <h1 className='header__content__title'>{title + ' '}</h1>
            </div>
          </div>
        </div>
      </Header>
      <Content>
        <div className='common__content flex__box flex__jus__sb flex__gap__cl--20'>
          <div className='common__content__left'>
            <Spin spinning={spinning} tip='Loading...'>
              {location.search && (
                <>
                  <TaskDetails spinningProp={spinning} filter={filter} />
                  <div className='common__process__bottom--btn'>
                    <Button
                      className='bg__blue common__process__control--btn'
                      onClick={() => {
                        setTitle(defaultTitle);
                        setFilter(null);
                        navigation('/reference/task/Planning');
                        refresh();
                      }}
                    >
                      前に戻る
                    </Button>
                  </div>
                </>
              )}
              {!location.search && (
                <>
                  <TableI3links 
                    spinning={spinning} 
                    className='common__table title_table_small' 
                    scroll={{ x: '100%' }} 
                    refresh={refresh} 
                    handlePaginationNexted={handlePaginationNexted} 
                    type='radio' 
                    pageSizeOptions={['10', '25', '50', '100']} 
                    dataSource={listTaskFilter} 
                    total={listTaskFilter.length} 
                    columns={columns} 
                    Pagination={pagination}
                    handleFilterAdmin={handleFilterExpire}
                  />
                </>
              )}
            </Spin>
          </div>
        </div>
      </Content>
    </>
  );
}

export default TaskPlanning;

import { Button, Collapse, Modal, Input, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { hrmsApi } from '../../../../../api/hrmApi';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import TableI3links from '../../../../../commons/commonComponents/tableI3links/TableI3links';
import { FormatDate } from '../../../../../commons/commonFunctions/FormatDate';
import { teamState } from '../../../../../redux/selector';
import { FaPlus, FaRegTimesCircle,FaFilter,FaEraser } from 'react-icons/fa';
import useWindowWidth from '../../../../../hooks/useWindowWidth/useWindowWidth';
import '../css/style.css';
const { Panel } = Collapse;

FilterUser.propTypes = {};

// const columnsUser = [
//   {
//     title: 'ユーザ名',
//     dataIndex: 'full_name',
//   },
// ];
const columnsGroup = [
  {
    title: '選択条件に所属する現在のユーザ一覧',
    dataIndex: 'full_name',
  },
];
const messageFillterUser = {
  top: '',
  mid: `
  担当者を起案者とする場合は、スケジュール設定が削除されます。
  また、予定日時設定の事前設定が設定なしに変更されます。`,
  bottom: '',
};

function FilterUser(props) {
  const { handleAdd, taskInfo, handleRemove, title, newProcess } = props;
  const [mode, setMode] = useState('user');
  const [spinning, setSpinning] = useState(false);
  const [userCurrent, setUserCurrent] = useState('');
  const [modeDefault, setModeDefault] = useState('');
  const [showModal, setShowModal] = useState(taskInfo.listUser.length > 0 ? false : true);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [listGroup, setListGroup] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const teamInfo = useSelector(teamState);
  const [listUserActive, setlistUserActive] = useState([]);
  const [listSearchGroup, setListSearchGroup] = useState([]);
  const [pagination, setPagination] = useState({
    totals: 1,
    limit: 10,
    page: 1,
  });

  const handleCancel = () => {
    setShowModal(false);
    setModeDefault('');
  };
  const handleShowModal = () => {
    setListSearchGroup([]);
    if (mode === 'user' && queryParams.listSearch?.length === 0 && modeDefault !== '起案者') {
      getAllUser();
    }
    // setDataSource([]);
    setShowModal(true);
  };

  const handleAddParamsInUser = (item) => {
    const newList = [...listGroup].map((group) => {
      if (+group.user_attributes_id === +item.user_attributes_id) {
        group.listAttributesDetail.map((itemDetail) => {
          if (itemDetail.user_attributes_detail_id === item.user_attributes_detail_id) {
            itemDetail.active = !itemDetail.active;
          } else {
            itemDetail.active = false;
          }
          return itemDetail;
        });
      }
      return group;
    });
    setListGroup([...newList]);
  };
  const handleAddParamsInGroup = () => {
    const listSearchInGroup = listGroup.reduce((arr, group) => {
      const item = group.listAttributesDetail.filter((itemDetail) => itemDetail.active === true);
      if (item.length > 0) {
        return [
          ...arr,
          {
            ...group,
            listAttributesDetail: [{ ...item[0], active: false }],
          },
        ];
      }
      return [...arr];
    }, []);
    setListSearchGroup([...listSearchInGroup]);
  };

  const handleActiveParamsInGroup = (item) => {
    const newList = [...listSearchGroup].map((group) => {
      if (+group.user_attributes_id === +item.user_attributes_id) {
        group.listAttributesDetail.map((itemDetail) => {
          if (itemDetail.user_attributes_detail_id === item.user_attributes_detail_id) {
            itemDetail.active = !itemDetail.active;
          } else {
            itemDetail.active = false;
          }
          return itemDetail;
        });
      }
      return group;
    });
    setListSearchGroup([...newList]);
  };

  const handleRemoveParamsInGroup = () => {
    const listSearchInGroup = listSearchGroup.reduce((arr, group) => {
      const item = group.listAttributesDetail.filter((itemDetail) => itemDetail.active === false);
      if (item.length > 0) {
        return [
          ...arr,
          {
            ...group,
            listAttributesDetail: item,
          },
        ];
      }
      return [...arr];
    }, []);
    setListSearchGroup([...listSearchInGroup]);
  };
  //// handleAdd
  const handleAddUser = () => {
    if (mode === 'user' && modeDefault === '') {
      ////////// kiểm tra nếu chưa có user nào được chọn mà nhấn ok sẽ thông báo
      if (listUserActive.length === 0 && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning('ユーザーを選択してください。', {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }
      /////// thông báo đã chọn người bắt đầu hoặc tất cả user
      const checkType = taskInfo.listUser.findIndex((item) => {
        return item.type === '全員' || item.type === '起案者';
      });
      if (checkType !== -1) {
        setShowModalConfirm(true);
        return;
      }
      //// kiểm tra user đã tồn tại trong danh chưa (lưu ở phiên bản này chưa kiểm tra bên trong group)
      const newUsers = listUserActive;
      // .filter((item) => {
      //   for (let index = 0; index < taskInfo.listUser.length; index++) {
      //     const element = taskInfo.listUser[index];
      //     const check = item.user_id === element.user_id;
      //     if (check) {
      //       return false;
      //     }
      //   }
      //   return true;
      // });
      //// thêm user vào task
      const index = [...taskInfo.listUser].findIndex((item) => {
        return item.user_id === userCurrent;
      });
      if (index !== -1) {
        const listLeft = [...taskInfo.listUser].slice(0, index);
        const listRight = [...taskInfo.listUser].slice(index + 1);
        const arrAdd = [];
        const arr = [...listLeft, ...newUsers, ...listRight];
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (arrAdd.findIndex((item) => item.user_id === element.user_id) === -1) {
            arrAdd.push(element);
          }
        }
        handleAdd({ listUser: [...arrAdd] });

        setShowModalConfirm(false);
        setlistUserActive([]);
        setShowModal(false);
        return;
      }
      handleAdd({ listUser: [...taskInfo.listUser, ...newUsers] });
      setShowModalConfirm(false);
      setlistUserActive([]);
      setShowModal(false);
      return;
    }
    if (mode === 'group') {
      if (listSearchGroup.length === 0) {
        setListSearchGroup([]);
        setDataSource([]);
        setShowModal(false);
        return;
      }
      ////////// kiểm tra nếu chưa có user nào được chọn mà nhấn ok sẽ thông báo
      if (dataSource.length === 0 && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning('選択されたグループにはユーザーがいません。', {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }
      const type = 'グループ';
      let test = [];
      let name = listSearchGroup.map((item) => {
        const namearr = item.listAttributesDetail.filter((item) => {
          test.push(item.user_attributes_detail_name);
          return item;
        });
        return `「${namearr.join('')}」`;
      });
      const listGroup = listSearchGroup.map((item) => {
        return item.listAttributesDetail[0];
      });
      const namegroup = test.length > 1 ? test.map((item) => `「${item}」`).join('かつ') : `${test[0]}`;
      const check = taskInfo.listUser.findIndex((item) => item.full_name === namegroup);
      if (check !== -1 && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning('グループはすでに選択されました。', {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }

      const index = [...taskInfo.listUser].findIndex((item) => {
        return item.user_id === userCurrent;
      });

      if (index !== -1) {
        const newUsers = { user_id: uuidv4(), listUserOfGroup: [...dataSource], full_name: namegroup, listGroup: [...listGroup], type: type };
        const listLeft = [...taskInfo.listUser].slice(0, index);
        const listRight = [...taskInfo.listUser].slice(index + 1);

        handleAdd({ listUser: [...listLeft, newUsers, ...listRight] });
        setShowModalConfirm(false);
        setlistUserActive([]);
        setShowModal(false);
        return;
      }

      handleAdd({ listUser: [...taskInfo.listUser, { user_id: uuidv4(), listUserOfGroup: [...dataSource], full_name: namegroup, listGroup: [...listGroup], type: type }] });
      setShowModal(false);
      setListSearchGroup([]);
      setDataSource([]);
      return;
    }
    if (mode === 'user' && modeDefault !== '') {
      const checkType = taskInfo.listUser.findIndex((item) => {
        return item.type === '全員' || item.type === '起案者';
      });

      if (checkType === -1 && taskInfo.listUser.length > 0) {
        setShowModalConfirm(true);
        return;
      }
      const type = modeDefault;
      handleAdd({ listUser: [{ user_id: uuidv4(), full_name: type, type }] });
      setShowModal(false);
      setModeDefault('');
      return;
    }
  };

  /// handleShowConfirm
  const handleConfirmOK = () => {
    if (modeDefault !== '') {
      const type = modeDefault;
      handleAdd({ listUser: [{ user_id: uuidv4(), full_name: type, type: type }] });

      setShowModalConfirm(false);
      setModeDefault('');
      setShowModal(false);
      return;
    }
    if (mode === 'group') {
      if (listSearchGroup.length === 0) {
        setListSearchGroup([]);
        setDataSource([]);
        setModeDefault('');
        setShowModal(false);
        return;
      }
      if (dataSource.length === 0 && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning('khong co user', {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }
      const type = 'グループ';
      let test = [];
      let name = listSearchGroup.map((item) => {
        const namearr = item.listAttributesDetail.filter((item) => {
          test.push(item.user_attributes_detail_name);
          return item;
        });
        return `「${namearr.join('')}」`;
      });
      const listGroup = listSearchGroup.map((item) => {
        return item.listAttributesDetail[0];
      });
      const namegroup = test.length > 1 ? test.map((item) => `「${item}」`).join('かつ') : `${test[0]}`;
      const check = taskInfo.listUser.findIndex((item) => item.full_name === namegroup);
      if (check !== -1 && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning('group da dc chon', {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
        return;
      }
      handleAdd({ listUser: [{ user_id: uuidv4(), listUserOfGroup: [...dataSource], full_name: namegroup, listGroup: [...listGroup], type: type }] });
      setShowModal(false);
      setListSearchGroup([]);
      setDataSource([]);
      setModeDefault('');
      return;
    }
    if (mode === 'user' && modeDefault === '') {
      handleAdd({ listUser: [...listUserActive] });
      setShowModalConfirm(false);
      setShowModal(false);
      setModeDefault('');
      return;
    }
  };
  /////handleCancel ModalShowConfirm

  const handleConfirmCancel = () => {
    if (mode === 'user') {
      setShowModalConfirm(false);
    }
  };
  //// xoa user
  const handleRemoveUser = (id) => {
    if (id && handleRemove) {
      handleRemove(id);
    }
  };
  //////// handle click User
  const handleClickUser = (listkey) => {
    const newListUser = dataSource.filter((user) => {
      const checkKey = listkey.includes(user.key);
      return checkKey;
    });
    setlistUserActive(newListUser);
  };

  const handlePaginationNexted = (values) => {
    if (values) {
      let newPagination = { ...pagination, ...values };
      setPagination(newPagination);
    }
  };
//////////////////////////////////////////////////////////////
const [listUserFilter, setlistUserFilter] = useState([]);
const [sortFilter, setSortFilter] = useState({
  filterUserName: '',
 
});
const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
  confirm();
  setSortFilter((prevValue) => {
    const newListSort = { ...prevValue };
    newListSort[filterName] = selectedKeys[0];
    return { ...newListSort };
  });
};

const handleReset = (clearFilters,filterName,confirm) => {
  if(filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality'|| filterName === 'filterEndTimeReality'){
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = [];
      return { ...newListSort };
    });
  }else{
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = '';
      return { ...newListSort };
    });
  }
  confirm()
  clearFilters();
};
///// lọc theo text
const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        placeholder={placeholder}
        value={`${selectedKeys[0] || ''}`}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          icon={<FaFilter />}
          size='small'
          style={{
            width: 90,
          }}
        ></Button>
        <Button
          onClick={() => {
            setSelectedKeys([])
            return clearFilters && handleReset(clearFilters,filterName,confirm)
          }}
          size='small'
          type='primary'
          style={{
            width: 90,
          }}
          icon={<FaEraser />}
        ></Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FaFilter
      style={{
        color: filtered ? '#000' : undefined,
      }}
    />
  ),
});


const [columnsUser, setColumnsUser] = useState(() => {
  return [
    { title: 'ユーザ名',
      dataIndex: 'full_name',
      sorter: {
        compare: (a, b) => a.full_name?.localeCompare(b.full_name),
        multiple: 1,
      },
      ...getColumnSearchProps('full_name', 'タスク名', 'filterUserName'),
     }
  ]
})


///sort khi có thông tin sort thay đổi
useEffect(() => {
  let dataFilterUsers = [...dataSource];
  if (sortFilter.filterUserName?.trim()) {
    dataFilterUsers = dataFilterUsers.filter((item) => item.full_name.includes(sortFilter.filterUserName?.trim()));
  }
  setlistUserFilter(() => {
    return dataFilterUsers.map((item, index) => {
      return { ...item, no: index + 1 };
    });
  });
  setPagination((prevPagination) => {
    return { ...prevPagination };
  });
}, [sortFilter]);

//////////////////////////////////////////////////////////////

  ///////////////call api//////////
  const queryParams = useMemo(() => {
    if (mode === 'user') {
      const listSearch = listGroup.reduce((arr, group) => {
        const item = group.listAttributesDetail.filter((itemDetail) => itemDetail.active === true);
        if (item.length > 0) {
          return [...arr, { user_attributes_id: item[0].user_attributes_id, user_attributes_detail_id: item[0].user_attributes_detail_id }];
        }
        return [...arr];
      }, []);
      return {
        listSearch,
        team_id: teamInfo.listTeam[0]?.team_id,
        page: Number.parseInt(pagination.page) || 1,
        limit: Number.parseInt(pagination.limit) || 10,
      };
    }
    if (mode === 'group') {
      // const listSearch =  [...listSearchGroup] ;
      const listSearch = [...listSearchGroup].reduce((arr, group) => {
        const item = group.listAttributesDetail;
        if (item.length > 0) {
          return [...arr, { user_attributes_id: item[0].user_attributes_id, user_attributes_detail_id: item[0].user_attributes_detail_id }];
        }
        return [...arr];
      }, []);
      return {
        listSearch,
        team_id: teamInfo.listTeam[0]?.team_id,
        page: Number.parseInt(pagination.page) || 1,
        limit: Number.parseInt(pagination.limit) || 10,
      };
    }
  }, [listGroup, listSearchGroup]);
  //////////////// call api lấy user từ những attribute đã chọn
  const filterUser = async () => {
    try {
      const result = await hrmsApi.usersApi.getUserSearch(queryParams);
      if (result) {
        const { data, pagination = {} } = result;
        if (data?.length > 0) {
          const dataListUser =
            data?.length > 0
              ? data.map((item, index) => {
                  item.key = index;
                  item.entry_date = FormatDate(new Date(item.entry_date));
                  item.edit_date = FormatDate(new Date(item.edit_date));
                  return item;
                })
              : [];
          return dataListUser;
        } else {
          return [];
        }
      }
    } catch (error) {
      if (error.message && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(error.message, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  };
  const getAllUser = async () => {
    try {
      setSpinning(true)
      const result = await hrmsApi.usersApi.getAllUser(queryParams);
      if (result) {
        const { data, pagination = {} } = result;
        const dataListUser =
          data?.length > 0
            ? data.map((item, index) => {
                item.key = index;
                item.entry_date = FormatDate(new Date(item.entry_date));
                item.edit_date = FormatDate(new Date(item.edit_date));
                return item;
              })
            : [];
        setDataSource(dataListUser);
        setlistUserFilter(dataListUser)
        setlistUserActive([]);
        setSpinning(false)
        return;
      }
    } catch (error) {
      if (error.message && !toast.isActive({ toastId: 'toast_warning' })) {
        toast.warning(error.message, {
          toastId: 'toast_warning',
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  };


  useEffect(() => {
    if (mode === 'user' && queryParams.listSearch?.length > 0 && modeDefault === '') {
      (async () => {
        try {
          const dataListUser = await filterUser();
          if (dataListUser) {
            const newData = dataListUser.map((item, index) => {
              item.key = index;
              return item;
            });  
            setDataSource([...newData]);
            setlistUserFilter([...newData])
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }

    ///////////////////////////////////////group//////////////////////////
    // if (mode === 'group' && queryParams.listSearch?.length > 0) {
    //   (async () => {
    //     try {
    //       const dataListUser = await filterUser();
    //       if (dataListUser) {
    //         const newData = dataListUser.map((item, index) => {
    //           item.key = index;
    //           return item;
    //         });
    //         setDataSource([...newData]);
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   })();
    // }
    // if (mode === 'group' && queryParams.listSearch?.length === 0) {
    //   setDataSource([]);
    // }
  }, [queryParams, modeDefault, listSearchGroup]);

  //////////////// call api lấy user từ những attribute đã chọn

  //////////////// call api lấy Attribute
  useEffect(() => {
    (async () => {
      try {
        const result = await hrmsApi.groupsApi.getAllGroup(queryParams);
        if (result) {
          const { data, pagination = {} } = result;
          const dataListGroup = data.map((item, index) => {
            item.key = index;
            item.entry_date = FormatDate(new Date(item.entry_date));
            item.edit_date = FormatDate(new Date(item.edit_date));
            return item;
          });
          setListGroup(dataListGroup);
          // setDataSource([]);
          setListSearchGroup([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [mode]);
  useEffect(() => {
    if (mode === 'user' && queryParams.listSearch?.length === 0 && modeDefault !== '起案者') {
      getAllUser();
    }
  }, []);

  ///////////////call api//////////
  const windowWidth = useWindowWidth();

  return (
    <>
      <div className='common__flex process__listtask '>
        {taskInfo.listUser.length > 0 && taskInfo.listUser[0] !== null ? (
          taskInfo.listUser.map((item) => {
            return (
              <div className='common__flex process__task--item'>
                <div className='common__flex process__task--itemL'>
                  <div className='process__task--item--number'>
                    <p>{item.full_name ? (item.type ? item.type : 'ユーザ') : '    '}</p>
                  </div>
                  <div className='common__flex process__task--item--name'>
                    <div
                      className='common__flex process__task--item--input '
                      onClick={() => {
                        handleShowModal();
                        setUserCurrent(item.user_id);
                      }}
                    >
                      {item?.full_name ? <p className='flex--1 common_text-clamp common_text-clamp--2'>{item.full_name} </p> : <p className='flex--1 process__task__defauft'> ここをクリックして設定</p>}
                    </div>

                    <Button
                      className='process__task--item--btn'
                      onClick={() => {
                        handleRemoveUser(item.user_id);
                      }}
                    >
                      <FaRegTimesCircle size={windowWidth < 1025 ? 25 : 30} />
                    </Button>
                  </div>
                </div>
                <div className='process__task--itemR'>
                  <Button
                    className='process__task--item--btn'
                    onClick={() => {
                      const newEmty = {
                        user_id: uuidv4(),
                        full_name: '',
                      };
                      handleAdd({ listUser: [...taskInfo.listUser, newEmty] });
                    }}
                  >
                    <FaPlus size={windowWidth < 1025 ? 25 : 30} />
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div className='common__flex process__task--item'>
            <div className='common__flex process__task--itemL'>
              <div className='process__task--item--number'>
                <p></p>
              </div>
              <div className='common__flex process__task--item--name'>
                <div
                  className='common__flex process__task--item--input'
                  onClick={() => {
                    handleShowModal();
                  }}
                >
                  <p className='flex--1 process__task__defauft'> ここをクリックして設定</p>
                </div>
              </div>
            </div>
            <div className='process__task--itemR'>
              <Button
                className='process__task--item--btn'
                onClick={() => {
                  handleShowModal();
                }}
              >
                <FaPlus size={windowWidth < 1025 ? 25 : 30} />
              </Button>
            </div>
          </div>
        )}
      </div>
      {showModalConfirm && (
        <ModalConfirm
          open={showModalConfirm}
          handleCancelConfirm={handleConfirmCancel}
          handleSubmit={handleConfirmOK}
          textConfirm={modeDefault === '起案者' ? `選択内容の反映には、本タスクの担当者設定を削除する必要があります。` : '選択内容の反映には、本タスクの担当者設定を削除する必要があります。'}
          // textConfirmMid={modeDefault === '起案者' && !newProcess ? `担当者を起案者とする場合は、スケジュール設定が削除されます。` : ''}
          textConfirmMid1={modeDefault === '起案者' && !newProcess ? `また、スケジュール設定と予定日時設定の事前設定が削除されます。` : ''}
          textConfirmBottom={modeDefault === '起案者' ? ' 削除して反映してもよろしいですか？' : '削除して反映してもよろしいですか？'}
        />
      )}
      <Modal className='modal__filterUser' width={900} maskClosable={false} closable={false} title={title ? <h2 className='color__title__black'>{title}</h2> : <h2 className='color__title__black'>担当者設定</h2>} open={showModal} forceRender footer={false}>
        <div className='common__flex modal__fillterUser--box'>
          <div className='common__flex flex__fd__cl modal__fillterUser--top'>
            <p className='color__text__black'> 担当者を設定してください。</p>
            <div style={{ width: 'max-content' }}>
              <p className='color__text__black' style={{ textAlign: 'left' }}>
                メンバー一覧：選択したユーザーが担当者
              </p>
              <p className='color__text__black' style={{ textAlign: 'left' }}>
                起案者：プロセスを手動実行したユーザーが担当者
              </p>
            </div>
            <p className='color__text__black'>※起案者を設定する場合、プロセスの実行方法が手動実行のみになります。</p>
          </div>
          <div className='common__flex  modal__fillterUser--top'>
            <Button
              className={mode === 'user' ? `common__process__control--btn  active` : ' common__process__control--btn'}
              onClick={() => {
                setMode('user');
              }}
            >
              ユーザー
            </Button>
            {/* <Button
              className={mode === 'group' ? `common__process__control--btn  active` : ' common__process__control--btn'}
              onClick={() => {
                setMode('group');
              }}
            >
              グループ
            </Button> */}
          </div>
          <div className='common__flex modal__fillterUser--center'>
            <div className='modal__fillterUser--option'>
              {mode === 'user' ? (
                <div className='common__flex modal__box__filterUser--user'>
                  {/* <Radio.Group onChange={() => {}}>
                    <Space direction='vertical'>
                      <Radio value={1}>メンバー一覧</Radio>
                      <Radio value={2}>起案者</Radio>
                    </Space>
                  </Radio.Group> */}
                  <div className='modal__box__filterUser--all-or-start modal__box__filterUser--listAttr'>
                    <Button
                      style={{ width: '100%', marginTop: 10, textAlign: 'left', paddingLeft: 20 }}
                      // disabled={modeDefault === '起案者' ? true : false}
                      className={modeDefault === '起案者' ? `common__process__control--btn ` :  'common__process__control--btn active' }
                      onClick={() => {
                        getAllUser();
                        setModeDefault('');
                      }}
                    >
                      メンバー一覧
                    </Button>
                  </div>
                  <div className='common__flex modal__box__filterUser--all-or-start'>
                    <Button
                      style={{ width: '100%', marginTop: 10, textAlign: 'left', paddingLeft: 20 }}
                      // disabled={modeDefault === '全員' ? true : false}
                      className={modeDefault === '起案者' ? ` common__process__control--btn active` :  'common__process__control--btn'}
                      onClick={() => {
                        setModeDefault('起案者');
                        setDataSource([]);
                        setlistUserFilter([])
                      }}
                    >
                      起案者
                    </Button>
                  </div>
                </div>
              ) : (
                <div className='common__flex modal__box__filterUser--group'>
                  <div className='modal__box__filterUser--listAttr'>
                    <div>
                      <Collapse className='Collapse__filter__list' onChange={() => {}}>
                        {listGroup?.map((menu, index) => {
                          return (
                            <Panel header={menu.user_attributes_name} key={menu.id}>
                              {menu.listAttributesDetail.map((item, index) => {
                                return (
                                  <div
                                    className={item.active ? 'Collapse__filter__item active' : 'Collapse__filter__item'}
                                    key={item.user_attributes_detail_id}
                                    onClick={() => {
                                      handleAddParamsInUser(item);
                                    }}
                                  >
                                    <span>{item.user_attributes_detail_name}</span>
                                  </div>
                                );
                              })}
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </div>
                  </div>
                  <div className='common__flex modal__box__filterUser--group--control'>
                    <Button
                      className=' bg__blue common__process__control--btn '
                      onClick={() => {
                        handleAddParamsInGroup();
                      }}
                    >
                      条件に追加 ↓
                    </Button>
                    <Button
                      className='bg__blue common__process__control--btn'
                      onClick={() => {
                        handleRemoveParamsInGroup();
                      }}
                    >
                      ↑ 条件から外す
                    </Button>
                  </div>
                  <div className='modal__box__filterUser--listAttr modal__box__filterUser--listAttr--add'>
                    <Collapse className='Collapse__filter__list' onChange={() => {}}>
                      {listSearchGroup?.map((menu, index) => {
                        return (
                          <Panel header={menu.user_attributes_name} key={menu.id}>
                            {menu.listAttributesDetail.map((item, index) => {
                              return (
                                <div
                                  className={item.active ? 'Collapse__filter__item active' : 'Collapse__filter__item'}
                                  key={item.user_attributes_detail_id}
                                  onClick={() => {
                                    handleActiveParamsInGroup(item);
                                  }}
                                >
                                  <span>{item.user_attributes_detail_name}</span>
                                </div>
                              );
                            })}
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </div>
                </div>
              )}
            </div>
            <div className='modal__fillterUser--table'>
              {mode === 'user' ? (
                <TableI3links
                  className=' table__user__actor'
                  Pagination={pagination}
                  handlePaginationNexted={handlePaginationNexted}
                  handleClick={(value) => {
                    handleClickUser(value);
                  }}
                  type='checkbox'
                  titleLocal={modeDefault}
                  columns={columnsUser}
                  dataSource={listUserFilter}
                  total = {listUserFilter.length}
                  spinning={spinning}
                  position='bottomRight'
                  showSizeChanger={true}
                  defaultPageSize={10}
                  pageSizeOptions={['10', '15', '20']}
                  scroll={{ y: 200 }}
                />
              ) : (
                <TableI3links className='table__user__actor' type='radio' columns={columnsGroup} dataSource={dataSource} position='bottomRight' showSizeChanger={true} defaultPageSize={10} pageSizeOptions={['10', '15', '20']} />
              )}
            </div>
          </div>
          <div className='common__flex common__process__control--listbtn mt--30'>
            <Button
              className=' bg__red common__process__control--btn'
              onClick={() => {
                handleCancel();
              }}
            >
              キャンセル
            </Button>
            <Button
              disabled={modeDefault !== '' ? false : dataSource.length === 0 ? true : false}
              className={dataSource.length === 0 && modeDefault === '' ? `common__process__control--btn common__process__bottom--btn--disable` : 'bg__blue common__process__control--btn'}
              onClick={() => {
                handleAddUser();
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FilterUser;

import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { API_URL } from '../config/config';
import { toast } from 'react-toastify';
const axiosClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
axiosClient.defaults.timeout = 60000;

const refreshToken = async () => {
  const res = await axiosClient.post('api/auth/refresh', {
    withCredentials: true,
  });
  return res;
};

// Add a request interceptor
axiosClient.interceptors.request.use(
  async function (config) {
    let accessToken1 = localStorage.getItem('accessToken');

    let decodeToken;
    if (accessToken1) {
      decodeToken = jwt_decode(accessToken1);
    }
    const date = new Date();
    if (config.url.indexOf('login') >= 0 || config.url.indexOf('api/auth/refresh') >= 0 || config.url.indexOf('resetpassword') >= 0 || config.url.indexOf('forgotpassword') >= 0 || config.url.indexOf('api/team') >= 0) {
      return config;
    }

    if (decodeToken.exp < date.getTime() / 1000) {
      const data = await refreshToken();
      if (data) {
        const { accessToken, ...other } = data;

        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          accessToken1 = accessToken;
        }
      }
    }
    const customHeaders = {};
    if (accessToken1) {
      customHeaders.Authorization = `Bearer ${accessToken1}`;
    }

    return {
      ...config,
      headers: {
        ...customHeaders, // auto attach token
        ...config.headers, // but you can override for some requests
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    return response.data;
  },

  function (error) {
    const { config, data, status } = error.response;
    if (status === 401) {
      window.location.reload(true);
      localStorage.removeItem('accessToken');
      throw new Error(data.error);
    }

    const URLS = ['/auth/local/register', '/auth/local'];
    if (URLS.includes(config.url) && status === 400) {
      const errorlist = data.message || [];
      const firstError = errorlist.length > 0 ? errorlist[0] : {};
      const messageList = firstError.messages.length > 0 ? firstError.messages[0] : {};

      throw new Error(messageList.message);
    }
  //   if (status === 500) {
  //     toast.warning('インターネット接続がありません。インターネット接続またはネットワークを確認してください。', {
  //       toastId:'name_process',
  //       position: 'top-right',
  //       autoClose: 3000,
  //       closeOnClick: true,
  //       theme: 'light',
  //       pauseOnHover: false,
  //       hideProgressBar: true,
  //   })
  //   return
  // }


    return Promise.reject(error);
  }
);

export default axiosClient;

import { BiSearchAlt } from 'react-icons/bi';
import { fileApi } from '../../api/fileApi';
import {
  FaAlignLeft, FaAlignRight, FaCog,
  FaCogs,
  FaRegCalendarAlt,
  FaTasks,
  FaUsers,
  FaLayerGroup,
  FaRecycle,
  FaClipboardList,
  FaClipboardCheck,
  FaUserCircle,
  FaLock,
  FaSignOutAlt,
  FaFileImport, 
  FaUserCog,
  FaUsersCog,
  FaProjectDiagram,
  FaFileDownload,
  FaTh, 
  FaShareAlt
} from 'react-icons/fa';


const download = async () => {
  try {
    return await fileApi.download({name: "1",});
  } catch (error) {
    console.log(error);
  }
};

export const LIST_NAV = {
  list_menu: [
    {
      id: 0,
      name: 'マイタスク',
      icon: <FaUserCog  />,
      to: '/reference',
      submenu: [
        { id: 1, name: '処理中タスク', icon: <FaCogs />, to: '/reference/processing' },
        { id: 3, name: '予定タスク', icon: < FaRegCalendarAlt/>, to: '/reference/task/Planning' },
        { id: 2, name: '完了済タスク', icon: <FaTasks />, to: '/reference/task/done' },
      ],
    },
    {
      id: 1,
      name: 'ユーザー管理',
      icon: <FaUsersCog  />,
      to: '/hrm',
      submenu: [
        { id: 1, name: 'ユーザー', icon: <FaUsers />, to: '/hrm/user' },
        // { id: 2, name: 'グループ', icon: <FaLayerGroup />, to: '/hrm/group' },
        // { id: 3, name: 'ユーザー・グループ一括登録', icon:  <FaFileImport  />, to: '/hrm/import' },
      ],
    },
    
    {
      id: 2,
      name: 'プロセス管理',
      icon: <FaCog />,
      to: '/process',
      submenu: [
        { id: 1, name: 'プロセス定義', icon: <FaProjectDiagram  />, to: '/process/list' },
        { id: 2, name: 'プロセス進捗', icon: <FaClipboardList />, to: '/process/run' },
        { id: 3, name: '完了済プロセス', icon: <FaClipboardCheck />, to: '/process/done' },
      ],
    },
    {
      id: 3,
      name: 'テンプレート',
      icon:  <FaTh />,
      to: '/template',
    },
    { id: 4, name: '', icon: '', to: '' },
    
    {
      id: 5,
      name: 'マニュアル',
      icon: <FaFileDownload onClick={() => {download()}} />,
      to: '',
    },
    // { id: 4, name: 'アカウント', icon: <FaUserCircle />, to: '/profile/resetpassword',submenu:[
    //   { id: 1, name: 'パスワード変更', icon: <FaLock />, to: '/profile/resetpassword' },
    //   { id: 2, name: 'ログアウト', icon: <FaSignOutAlt />, to: '/login' },
    // ] },

  ],
  Menu_collapsed: {
    collapsed_in: (
      <span>
        <FaAlignRight size={30} />
      </span>
    ),
    collapsed_out: (
      <span>
        <FaAlignLeft size={30} />
      </span>
    ),
  },
};
export const LIST_HEADER_MODDLE = {
  PROCESS: {},
};
